import { Cleaner } from "@/domain/user"
import {
    Cleaning,
    CleaningInfoDetail,
    CleaningType,
    CleaningWithCleaners
} from "./../domain/cleaning"
import { Listing } from "@/domain/listing"
import { PlacementType } from "@/domain/placement"
import { Reservation } from "@/domain/reservation"
import { bearerFetcher, useAuthBearerFetch, useAuthFetch } from "matsuri-hooks"
import { m2mCleaning_managerV3 } from "@/endpoints/m2m-cleaning.manager-v3"
import { m2mCleaning_managerV4 } from "../endpoints/m2m-cleaning.manager-v4"
import { useAuth } from "@/hooks/useAuth"
import { useMemo } from "react"
import dayjs from "dayjs"

interface CreateCleaningInput {
    cleaningDate: string
    cleaners: string[]
    listingId: string
    placement?: string
    note: string | undefined
}

interface CreateDelegateCleaningInput {
    cleaningDate: string
    listingId: string
    note: string | undefined
    placement: string | undefined
}

interface CreateCleaningWithPlacementInput {
    listingId?: string
    commonAreaId?: string
    cleaningDate: string
    note?: string
    placement: PlacementType
    cleaners: string[]
    photoTourId?: string
    submissionId?: string
}

export const requestCreateCleaning = (
    token: string,
    input: CreateCleaningInput
) => {
    return bearerFetcher<{ cleaningId: string }>(
        token,
        m2mCleaning_managerV3.createCleaning(),
        {
            method: "POST",
            body: JSON.stringify(input)
        }
    )
}

export const requestCreateDelegateCleaning = (
    token: string,
    input: CreateDelegateCleaningInput
) => {
    return bearerFetcher<{ cleaningId: string }>(
        token,
        m2mCleaning_managerV3.createCleaningOfListing({
            listingId: input.listingId
        }),
        {
            method: "POST",
            body: JSON.stringify(input)
        }
    )
}

interface CreateErrandInput {
    title: string
    description: string
}

export const requestCreateErrand = async (
    token: string,
    cleaningId: string,
    input: CreateErrandInput
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.createErrandsByListingManager({ cleaningId }),
        {
            method: "POST",
            body: JSON.stringify(input)
        }
    )
}

export const requestDeleteErrand = async (
    token: string,
    cleaningId: string,
    errandId: string
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.deleteErrandByListingManager({
            cleaningId,
            errandId
        }),
        {
            method: "DELETE"
        }
    )
}

export const requestUpdateCleaning = async (
    token: string,
    cleaningId: string,
    input: { note?: string; submissionId?: string }
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.updateCleaning({ cleaningId }),
        {
            method: "PUT",
            body: JSON.stringify(input)
        }
    )
}

export const requestUpdateCleaningAvailability = async (
    token: string,
    cleaningId: string,
    input: { isDisabled?: boolean }
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.updateCleaning({ cleaningId }),
        {
            method: "PUT",
            body: JSON.stringify(input)
        }
    )
}

export const useCleaning = (token: string, cleaningId: string | undefined) => {
    return useAuthFetch<CleaningInfoDetail>(
        token,
        cleaningId ? m2mCleaning_managerV3.getCleaning({ cleaningId }) : null,
        {
            authTokenHeader: "Authorization"
        }
    )
}

export const requestGetCleaning = (token: string, cleaningId: string) => {
    const endpoint = m2mCleaning_managerV3.getCleaning

    return bearerFetcher<CleaningInfoDetail>(token, endpoint({ cleaningId }), {
        method: endpoint.method
    })
}

export const requestAssignCleaner = (
    token: string,
    cleaningId: string,
    cleanerId: string,
    fixedPrice?: number
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.assignCleaners({ cleaningId }),
        {
            method: "POST",
            body: JSON.stringify({
                cleanerId,
                unitPrice: fixedPrice
            })
        }
    )
}

export const requestUnassignCleaner = (
    token: string,
    cleaningId: string,
    cleanerId: string
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.unassignCleaners({ cleaningId }),
        {
            method: "DELETE",
            body: JSON.stringify({
                cleanerId
            })
        }
    )
}

export const requestUnassignAllCleaner = (
    token: string,
    cleaningId: string
) => {
    const endpoint = m2mCleaning_managerV4.unassignAllCleanerByCleaningId
    return bearerFetcher(token, endpoint({ cleaningId }), {
        method: endpoint.method
    })
}

export const useCleaningCleaners = (
    token: string,
    cleaningId: string | undefined
) => {
    return useAuthBearerFetch<Cleaner[]>(
        token,
        cleaningId
            ? m2mCleaning_managerV3.listAssignedCleaners({ cleaningId })
            : null,
        {}
    )
}

export const requestGetAssignedCleanersByCleaningId = (
    token: string,
    cleaningId: string
) => {
    return bearerFetcher<Cleaner[]>(
        token,
        m2mCleaning_managerV3.listAssignedCleaners({ cleaningId }),
        {
            method: "GET"
        }
    )
}

export const useDelegateCleaning = (
    token: string,
    cleaningId: string | undefined
) => {
    return useAuthBearerFetch<CleaningInfoDetail>(
        token,
        cleaningId
            ? m2mCleaning_managerV3.getDelegateCleaning({ cleaningId })
            : null,
        {}
    )
}

export const requestTransferCleanings = async (
    token: string,
    input: {
        listingId: string
        sourceCompanyId: string
        targetCompanyId: string
        date: string
    }
) => {
    return bearerFetcher(token, m2mCleaning_managerV3.transferCleanings(), {
        method: "POST",
        body: JSON.stringify(input)
    })
}

export const requestTransferCleaning = async (
    token: string,
    input: {
        cleaningId: string
        targetCompanyId: string
    }
) => {
    return bearerFetcher(token, m2mCleaning_managerV3.transferCleaning(), {
        method: "POST",
        body: JSON.stringify(input)
    })
}

export interface CleaningSearchResponse extends CleaningWithCleaners {
    listingName: string
    cleanerNames: string[]
    commonAreaName?: string
    companyName?: string
    hasCheckinOnDate: boolean
    unresolvedTroubles: number
    submissionId?: string
    cleanerIds: string[]
    address: string
}

interface UseSearchCleaningsInput {
    startDate?: string
    endDate?: string
    listingName?: string
    cityGroupId?: string
}

export const useSearchCleanings = (
    input: UseSearchCleaningsInput | undefined
) => {
    const { token } = useAuth()
    const endpoint = m2mCleaning_managerV4.searchCleanings
    return useAuthBearerFetch<CleaningSearchResponse[]>(
        token,
        input ? endpoint() : null,
        {
            body: input ? JSON.stringify(input) : undefined,
            method: endpoint.method
        }
    )
}

export const useSearchDelegateCleanings = (
    startDate?: string,
    endDate?: string,
    listingName?: string
) => {
    const { token } = useAuth()
    const { data, refetch } = useAuthBearerFetch<CleaningSearchResponse[]>(
        token,
        m2mCleaning_managerV3.searchDelegateCleanings(),
        {
            body: JSON.stringify({
                ...(listingName && {
                    listingName: listingName
                }),
                ...(startDate && {
                    startDate: startDate
                }),
                ...(endDate && {
                    endDate: endDate
                })
            }),
            method: "POST"
        }
    )

    return { data: data ?? [], refetch }
}

export const requestUpdateCleaningByListingManager = (
    token: string,
    cleaningId: string,
    input: {
        cleaningDate?: string
        listingManagerMemo?: string
        cleaningType?: CleaningType
        isDisabled?: boolean
    }
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.updateCleaningByListingManager({ cleaningId }),
        {
            method: "PUT",
            body: JSON.stringify(input)
        }
    )
}

export const requestFindCleaningsByOtaId = (
    token: string,
    req: { otaId: string }
) => {
    return bearerFetcher<CleaningWithCleaners[]>(
        token,
        m2mCleaning_managerV3.findCleaningsByOtaId(),
        {
            method: "POST",
            body: JSON.stringify(req)
        }
    )
}

export const useCleaningsByListingId = (
    token: string,
    listingId: string | undefined,
    span: { startDate: string; endDate: string }
) => {
    return useAuthBearerFetch<Cleaning[]>(
        token,
        listingId
            ? m2mCleaning_managerV3.findCleaningsByListingId({ listingId })
            : null,
        {
            method: "POST",
            body: JSON.stringify({
                listingId,
                ...span
            })
        }
    )
}

// 3ヶ月以内の直前の清掃を見つける
export const usePreviousCleaningIn3Months = (
    token: string,
    listingId: string | undefined,
    cleaningDate: string | undefined
) => {
    const { data } = useAuthBearerFetch<Cleaning[]>(
        token,
        listingId && cleaningDate
            ? m2mCleaning_managerV3.findCleaningsByListingId({ listingId })
            : null,
        {
            method: "POST",
            body: JSON.stringify({
                listingId,
                startDate: dayjs(cleaningDate)
                    .add(-3, "month")
                    .format("YYYY-MM-DD"),
                endDate: dayjs(cleaningDate).add(-1, "day").format("YYYY-MM-DD")
            })
        }
    )
    const dataMemoized = useMemo(
        () => (data ? data[data.length - 1] : undefined),
        [data]
    )

    return {
        data: dataMemoized
    }
}

// 3ヶ月以内の直後の清掃を見つける
export const useNextCleaningIn3Months = (
    token: string,
    listingId: string | undefined,
    cleaningDate: string | undefined
) => {
    const { data } = useAuthBearerFetch<Cleaning[]>(
        token,
        listingId && cleaningDate
            ? m2mCleaning_managerV3.findCleaningsByListingId({ listingId })
            : null,
        {
            method: "POST",
            body: JSON.stringify({
                listingId,
                startDate: dayjs(cleaningDate)
                    .add(1, "day")
                    .format("YYYY-MM-DD"),
                endDate: dayjs(cleaningDate)
                    .add(3, "month")
                    .format("YYYY-MM-DD")
            })
        }
    )
    const dataMemoized = useMemo(() => (data ? data[0] : undefined), [data])

    return {
        data: dataMemoized
    }
}

export const requestUploadEvidence = (
    token: string,
    cleaningId: string,
    input: { data: string }
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.uploadEvidencePictureByListingManager({
            cleaningId
        }),
        {
            method: "POST",
            body: JSON.stringify(input)
        }
    )
}

export const requestDeleteEvidence = (
    token: string,
    cleaningId: string,
    input: {
        imageId: string
    }
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.deleteEvidencePictureByListingManager({
            cleaningId
        }),
        {
            method: "DELETE",
            body: JSON.stringify(input)
        }
    )
}

export const requestCreateCleaningWithPlacement = (
    token: string,
    input: CreateCleaningWithPlacementInput
) => {
    return bearerFetcher<string>(
        token,
        m2mCleaning_managerV3.createCleaningWithPlacement(),
        {
            token,
            method: "POST",
            body: JSON.stringify(input)
        }
    )
}

export const useSearchCleaningsWithPhotoTourIds = (
    token: string,
    input:
        | {
              startDate: string
              endDate: string
              photoTourIds: string[]
              cleaningStatus?: string | null
              cityGroupId?: string
          }
        | undefined
) => {
    return useAuthBearerFetch<CleaningSearchResponse[]>(
        token,
        input ? m2mCleaning_managerV4.findCleaningsWithPhotoTourIds() : null,
        {
            body: JSON.stringify(input),
            method: "POST"
        }
    )
}

export const useListingCleaningsByIds = (
    token: string,
    input: { ids: string[] } | undefined
) => {
    const endpoint = m2mCleaning_managerV4.findListingCleaningsByIds

    return useAuthBearerFetch<{
        data: {
            cleaning: CleaningWithCleaners
            hasCheckin: boolean
            listing: Listing
            reservation?: Reservation
        }[]
    }>(token, input ? endpoint() : null, {
        method: endpoint.method,
        body: JSON.stringify(input)
    })
}

interface UpdateCleaningScheduleInput {
    cleaningId: string
    scheduleStart: number
    scheduleEnd: number
}

export const requestUpdateCleaningSchedule = (
    token: string,
    { cleaningId, scheduleStart, scheduleEnd }: UpdateCleaningScheduleInput
) => {
    const endpoint = m2mCleaning_managerV4.updateCleaningSchedule

    return bearerFetcher(token, endpoint(), {
        method: endpoint.method,
        body: JSON.stringify({
            cleaningId,
            scheduleStart,
            scheduleEnd
        })
    })
}
