import * as React from "react"
import { useAuth } from "@/hooks/useAuth"
import { useContext } from "react"

type AllowedAction = "none" | "read" | "write" | "admin"
const allowedActions: AllowedAction[] = ["none", "read", "write", "admin"]

const isGreaterActionThan = (x: AllowedAction, y: AllowedAction) => {
    return allowedActions.indexOf(x) >= allowedActions.indexOf(y)
}

type Authority = "GeneralUser" | "Manager" | "Admin"
const authorities: Authority[] = ["GeneralUser", "Manager", "Admin"]

const isGreaterAuthorityThan = (x: Authority, y: Authority) => {
    return authorities.indexOf(x) >= authorities.indexOf(y)
}

// Manager以上かつscopeがlistings:write以上ならlisting manager
const isListingManager = (body: JWTDecodedBody) => {
    return (
        isGreaterAuthorityThan(body.authority, "Manager") &&
        body.scope.listings &&
        isGreaterActionThan(body.scope.listings, "write")
    )
}

const isSameCompanyFactory = (
    body: JWTDecodedBody
): ((companyId: string) => boolean) => {
    return function (companyId: string) {
        return body.companyId === companyId
    }
}

export const matsuriCompanyId =
    process.env.NODE_ENV === "development"
        ? "1"
        : "00112ed2-b85a-4a83-a7a3-d654767f2561"

interface JWTDecodedBody {
    authority: Authority
    companyId: string
    userId: string
    scope: Record<string, AllowedAction>
}

// 正しい実装: https://github.com/auth0/jwt-decode/blob/master/lib/base64_url_decode.js
const decodeJwt = (token: string): JWTDecodedBody | undefined => {
    const base64Url = token.split(".")[1]
    if (base64Url) {
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return JSON.parse(decodeURIComponent(escape(window.atob(base64))))
    }
    return
}

interface AccountInfoContextState {
    jwtPayload: JWTDecodedBody
    isListingManager: boolean
    isSameCompany: (companyId: string) => boolean
    isMatsuri: boolean
}

const AccountInfoContext = React.createContext<
    undefined | AccountInfoContextState
>(undefined)

const AccountInfoContextProvider = AccountInfoContext.Provider

interface AccountInfoProviderProps {
    children: React.ReactNode
}

export const AccountInfoProvider = (props: AccountInfoProviderProps) => {
    const authInfo = useAuth()
    const jwtDecodedBody = React.useMemo<undefined | JWTDecodedBody>(() => {
        return decodeJwt(authInfo.token)
    }, [authInfo])
    const value = React.useMemo(
        () =>
            jwtDecodedBody
                ? {
                      jwtPayload: jwtDecodedBody,
                      isListingManager: isListingManager(jwtDecodedBody),
                      isSameCompany: isSameCompanyFactory(jwtDecodedBody),
                      isMatsuri: jwtDecodedBody.companyId === matsuriCompanyId
                  }
                : undefined,
        [jwtDecodedBody]
    )

    return jwtDecodedBody ? (
        <AccountInfoContextProvider value={value}>
            {props.children}
        </AccountInfoContextProvider>
    ) : (
        <React.Fragment>{props.children}</React.Fragment>
    )
}

export const useAccountInfoCtx = (): AccountInfoContextState | never => {
    const ctx = useContext(AccountInfoContext)
    if (!ctx) {
        throw new Error("AccountInfoContext not found")
    }

    return ctx
}
