import "./style.css"
import "ress"
import { AlertProvider, ThemeProvider } from "matsuri-ui"
import { App } from "./App"
import { BrowserRouter } from "react-router-dom"
import { ErrorBoundary } from "@/ErrorBoundary"
import { cleaningTheme } from "./Theme"
import { datadogLogs } from "@datadog/browser-logs"
import { initM2mComponents } from "m2m-components/initM2mComponents"
import ReactDOM from "react-dom/client"

const container = document.getElementById("root")

initM2mComponents({
    env: process.env.NODE_ENV === "production" ? "production" : "development"
})

const NODE_ENV = process.env.NODE_ENV ?? "development"

const DATADOG_RELEASE_VERSION = process.env.DATADOG_RELEASE_VERSION

if (DATADOG_RELEASE_VERSION) {
    datadogLogs.init({
        service: `m2m-cleaning-manager-front-${NODE_ENV}`,
        env: NODE_ENV,
        clientToken: "pub8b96652e2b35dcea7c23f96817bca1fb",
        forwardErrorsToLogs: true,
        version: DATADOG_RELEASE_VERSION
    })
}

if (container) {
    const root = ReactDOM.createRoot(container)

    root.render(
        <ThemeProvider theme={cleaningTheme}>
            <ErrorBoundary>
                <BrowserRouter>
                    <AlertProvider position={"bottom-right"}>
                        <App />
                    </AlertProvider>
                </BrowserRouter>
            </ErrorBoundary>
        </ThemeProvider>
    )
}
