import { CleaningType } from "../domain/cleaning"
import { Listing, ListingInfo } from "@/domain/listing"
import { bearerFetcher, useAuthBearerFetch } from "matsuri-hooks"
import { m2mCleaning } from "../endpoints/m2m-cleaning"
import { m2mCleaning_managerV3 } from "@/endpoints/m2m-cleaning.manager-v3"
import { useAuth } from "./useAuth"

interface DelegateInput {
    listingId: string
    companyId: string
}

export const requestDelegation = async (
    token: string,
    input: DelegateInput
) => {
    return bearerFetcher(
        token,
        m2mCleaning.managerV3.delegateListing({
            listingId: input.listingId
        }),
        {
            method: "PUT",
            body: JSON.stringify({
                companyId: input.companyId
            })
        }
    )
}

export const requestUndelegation = async (
    token: string,
    input: { listingId: string }
) => {
    return bearerFetcher(
        token,
        m2mCleaning.managerV3.undelegateListing({
            listingId: input.listingId
        }),
        {
            method: "DELETE"
        }
    )
}

interface ListingInfoOutputWithTotal {
    data: ListingInfo[]
    total: number
}

export interface PageInput {
    page: number
    pageSize: number
    total: number
}
export const useListings = (
    pageInput: PageInput,
    listingName: string,
    isDelegated: boolean
) => {
    const { token } = useAuth()
    return useAuthBearerFetch<ListingInfoOutputWithTotal>(
        token,
        `${m2mCleaning_managerV3.listListings()}?${[
            `page=${pageInput.page}`,
            `pageSize=${pageInput.pageSize}`,
            `listingName=${listingName}`,
            `isNotDelegated=${isDelegated}`
        ].join("&")}`,
        {}
    )
}

export const useDelegatedListings = (
    pageInput: PageInput,
    listingName: string
) => {
    const { token } = useAuth()
    return useAuthBearerFetch<ListingInfoOutputWithTotal>(
        token,
        `${m2mCleaning_managerV3.listDelegatedListings()}?${[
            `page=${pageInput.page}`,
            `pageSize=${pageInput.pageSize}`,
            `listingName=${listingName}`
        ].join("&")}`,
        {}
    )
}

export interface CleaningTypeFeeListing {
    baseFee?: number
    beddingFee?: number
    ownerFee?: number
}

interface CleaningTypeFeeListByListing {
    listingId: string
    fees: Record<CleaningType, CleaningTypeFeeListing | undefined>
}

export const useCleaningTypeFeeListByListing = (
    token: string,
    listingId: string | undefined
) => {
    return useAuthBearerFetch<CleaningTypeFeeListByListing>(
        token,
        listingId
            ? m2mCleaning_managerV3.getCleaningTypeFeeListByListing({
                  listingId
              })
            : null,
        {}
    )
}

export const requestUpdateCleaningTypeFeeListByListing = (
    token: string,
    listingId: string,
    input: CleaningTypeFeeListByListing
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.putCleaningTypeFeeListByListing({ listingId }),
        {
            method: "PUT",
            body: JSON.stringify(input)
        }
    )
}

export const useListing = (token: string, listingId: string | undefined) => {
    return useAuthBearerFetch<Listing>(
        token,
        listingId ? m2mCleaning_managerV3.getListingById({ listingId }) : null,
        {}
    )
}

export const requestUpdateListing = (
    token: string,
    listingId: string,
    input: Pick<Listing, "cleanerNote">
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.updateListing({ listingId }),
        {
            method: "PUT",
            body: JSON.stringify(input)
        }
    )
}

export const requestSearchListingByName = (
    token: string,
    query: string,
    pagination: { page: number; pageSize: number }
) => {
    return bearerFetcher<ListingInfoOutputWithTotal>(
        token,
        `${m2mCleaning_managerV3.listListings()}?${[
            `page=${pagination.page}`,
            `pageSize=${pagination.pageSize}`,
            `listingName=${query}`
        ].join("&")}`
    )
}

export const requestGetListingById = async (
    token: string,
    listingId: string
) => {
    return bearerFetcher<Listing>(
        token,
        m2mCleaning_managerV3.getListingById({ listingId })
    )
}

export const requestGetCleaningTypeFeeListByListingId = async (
    token: string,
    listingId: string
) => {
    return bearerFetcher<CleaningTypeFeeListByListing>(
        token,
        m2mCleaning_managerV3.getCleaningTypeFeeListByListing({ listingId })
    )
}
