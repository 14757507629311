import { CommonArea } from "@/domain/commonArea"
import { bearerFetcher } from "matsuri-hooks"
import { m2mCleaning_managerV4 } from "@/endpoints/m2m-cleaning.manager-v4"

export const requestSearchCommonAreaByName = (
    token: string,
    input: { nameQuery: string; pageSize: number; page: number }
) => {
    return bearerFetcher<CommonArea[]>(
        token,
        m2mCleaning_managerV4.searchCommonAreaByName(),
        {
            method: "POST",
            body: JSON.stringify(input)
        }
    )
}
