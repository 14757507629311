import {
    CleaningNewStatus,
    colorCleaningNewStatus,
    displayCleaningNewStatus
} from "@/domain/cleaningNewStatus"
import React from "react"
import styled from "@emotion/styled"

const getLabelAndColor = (
    status: CleaningNewStatus | undefined
): { label?: string; color?: string } => {
    const label = displayCleaningNewStatus(status)
    const color = colorCleaningNewStatus(status)

    return { label, color }
}

const ChipElement = styled.div<{ backgroundColor: string }>`
    background-color: ${props => props.backgroundColor};
    border-radius: 14px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    width: 122px;
    height: 30px;
    line-height: 30px;
`

export const CleaningNewStatusChip = (props: {
    status: CleaningNewStatus | undefined
}) => {
    const { label, color } = getLabelAndColor(props.status)

    // 高さを揃えるために空のChipElementを返す
    if (!label || !color) return <ChipElement backgroundColor="inherit" />

    return <ChipElement backgroundColor={color}>{label}</ChipElement>
}
