/* eslint-disable */
/**
 * A function that returns the URL part common to the endpoints.
 */
export const root = () => {
  let __root = "";

  if (process.env.RUNTIME_ENV === "local") {
    __root = "http://localhost:7999";
  }

  if (process.env.RUNTIME_ENV === "development") {
    __root = "https://api-cleaning.dev.m2msystems.cloud";
  }

  if (process.env.RUNTIME_ENV === "production") {
    __root = "https://api-cleaning.m2msystems.cloud";
  }

  return __root;
};
/**
 * オペレーションを取得する
 *
 */
export const findOperationById = ({ operationId }: { operationId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/operations/${operationId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findOperationById.method = "GET" as const;
/**
 * オペレーションを検索する
 *
 */
export const searchOperations = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/operations/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchOperations.method = "POST" as const;
/**
 * オペレーションのトラブルを作成する
 *
 */
export const createOperationManagerTrouble = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/operations/createTrouble`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createOperationManagerTrouble.method = "POST" as const;
/**
 * Placementを取得する
 *
 */
export const findPlacementById = ({ placementId }: { placementId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/placements/${placementId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findPlacementById.method = "GET" as const;
/**
 * 会社IDからPlacementを取得する
 *
 */
export const searchPlacements = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/placements/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchPlacements.method = "POST" as const;
/**
 * 写真ツアーを無効化する
 *
 */
export const diablePhotoTour = ({ photoTourId }: { photoTourId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tours/${photoTourId}/disable`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

diablePhotoTour.method = "PUT" as const;
/**
 * 写真ツアーを有効化する
 *
 */
export const enablePhotoTour = ({ photoTourId }: { photoTourId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tours/${photoTourId}/enable`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

enablePhotoTour.method = "PUT" as const;
/**
 * 写真ツアーのアイテムを無効化する
 *
 */
export const diablePhotoTourItem = ({
  photoTourId,
  photoTourItemId,
}: {
  photoTourId: string;
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tours/${photoTourId}/items/${photoTourItemId}/disable`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

diablePhotoTourItem.method = "PUT" as const;
/**
 * 写真ツアーのアイテムを有効化する
 *
 */
export const enablePhotoTourItem = ({
  photoTourId,
  photoTourItemId,
}: {
  photoTourId: string;
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tours/${photoTourId}/items/${photoTourItemId}/enable`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

enablePhotoTourItem.method = "PUT" as const;
/**
 * 写真ツアーの動画をアップロードする
 *
 */
export const findPhotoTourMovie = ({ cleaningId }: { cleaningId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tours/cleaningId/${cleaningId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findPhotoTourMovie.method = "GET" as const;
/**
 * 写真ツアーの動画を削除する
 *
 */
export const deletePhotoTourMovie = ({
  photoTourMovieId,
}: {
  photoTourMovieId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tours/${photoTourMovieId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deletePhotoTourMovie.method = "DELETE" as const;
/**
 * PlacementIDから写真ツアーの動画を取得する
 *
 */
export const findPhotoTourMovieByPlacementId = ({
  placementId,
}: {
  placementId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tours/movies/placementId/${placementId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findPhotoTourMovieByPlacementId.method = "GET" as const;
/**
 * 物件に紐づく清掃の画像を取得する
 *
 */
export const findCleaningImageViewByListingId = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listing/${listingId}/imageView`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCleaningImageViewByListingId.method = "GET" as const;
/**
 * 共用部に紐づく清掃の画像を取得する
 *
 */
export const findCleaningImageViewByCommonAreaId = ({
  commonAreaId,
}: {
  commonAreaId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/common_area/${commonAreaId}/imageView`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCleaningImageViewByCommonAreaId.method = "GET" as const;
/**
 * 作業員PhotoTourの回数を取得する
 *
 */
export const getCleaningPhotoTourCounts = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/operationsCounts`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getCleaningPhotoTourCounts.method = "POST" as const;
/**
 * シフトのステータスを更新する
 *
 */
export const updateShiftsStatus = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/shifts/status`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateShiftsStatus.method = "PUT" as const;
/**
 * シフトの日付を更新する
 *
 */
export const updateShiftDate = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/shift/date`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateShiftDate.method = "PUT" as const;
/**
 * シフトを取得する
 *
 */
export const listShifts = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/shifts/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listShifts.method = "POST" as const;
/**
 * 物件清掃をIDを使って取得する
 *
 */
export const findListingCleaningsByIds = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/listingCleanings/find_by_ids`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findListingCleaningsByIds.method = "POST" as const;
/**
 * 写真ツアーのインデックスを再作成する
 *
 */
export const reindexPhotoTourItems = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tours/reindex`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

reindexPhotoTourItems.method = "POST" as const;
/**
 * 共用部を名前で検索する
 *
 */
export const searchCommonAreaByName = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/common_area/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchCommonAreaByName.method = "POST" as const;
/**
 * 清掃員IDと日付を指定して、交通経路と交通費を登録する
 *
 */
export const registerCleanerPathWithCleanerId = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner_paths/register_for_cleaner`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

registerCleanerPathWithCleanerId.method = "POST" as const;
/**
 * 清掃員のパスを登録する
 *
 */
export const registerCleanerPath = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner_paths/register`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

registerCleanerPath.method = "POST" as const;
/**
 * 管理者が、清掃員達のパスを取得する
 *
 */
export const listCleanerPath = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner_paths/list`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listCleanerPath.method = "POST" as const;
/**
 * 管理者が、日付とUserIdを指定して、清掃員のパスを取得する
 *
 */
export const listCleanerPathByDateAndUser = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner_paths/list_by_date_and_user`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listCleanerPathByDateAndUser.method = "POST" as const;
/**
 * 管理者が一人の清掃員の自宅を登録する
 *
 */
export const saveCleanerHome = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner_homes/save`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveCleanerHome.method = "PUT" as const;
/**
 * 管理者が、清掃員の交通費を更新する
 *
 */
export const updateCleanerPath = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner_paths`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateCleanerPath.method = "PUT" as const;
/**
 * PlacementIdsからPlacementDetailsを取得する
 *
 */
export const findPlacements = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/placements/find_by_ids`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findPlacements.method = "POST" as const;
/**
 * CleaningId配列からCleaningを取得する
 *
 */
export const findCleanings = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/find_by_ids`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCleanings.method = "POST" as const;
/**
 * 清掃員の備考を保存する
 *
 */
export const saveCleanerRemarks = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner_remarks/save`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveCleanerRemarks.method = "PUT" as const;
/**
 * 清掃員の評価を保存する
 *
 */
export const saveCleanerAssessment = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner_assessment/save`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveCleanerAssessment.method = "PUT" as const;
/**
 * 清掃員の詳細情報を取得する
 *
 */
export const findDetailsByCleanerIds = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaners/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findDetailsByCleanerIds.method = "POST" as const;
/**
 * 清掃員のスキルを保存する
 *
 */
export const saveCleanerSkill = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner_skills/save`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveCleanerSkill.method = "PUT" as const;
/**
 * 清掃員の自動アサイン対象フラグを保存する
 *
 */
export const saveCleanerAutoAssignEnabled = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner_auto_assign_enabled/save`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveCleanerAutoAssignEnabled.method = "PUT" as const;
/**
 * 清掃員の単価を保存する
 *
 */
export const saveCleanerUnitPrice = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner_unit_price/save`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveCleanerUnitPrice.method = "PUT" as const;
/**
 * 清掃員のid列から、清掃員の単価を検索する
 *
 */
export const findCleanerUnitPricesByCleanerIds = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner_unit_price/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCleanerUnitPricesByCleanerIds.method = "POST" as const;
/**
 * 清掃員の基本情報を保存する
 *
 */
export const saveCleanerFactor = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner_factors/save`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveCleanerFactor.method = "PUT" as const;
/**
 * PlacementIdsから住所を取得する
 *
 */
export const searchAddressesByPlacementIds = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/placements/search_addresses`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchAddressesByPlacementIds.method = "POST" as const;
/**
 * エリア(CityGroup)を作成する
 *
 */
export const createCityGroups = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/city_groups`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createCityGroups.method = "POST" as const;
/**
 * エリア(CityGroup)を削除する
 *
 */
export const deleteCityGroup = ({ cityGroupId }: { cityGroupId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/city_groups/${cityGroupId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteCityGroup.method = "DELETE" as const;
/**
 * エリア(CityGroup)を更新する
 *
 */
export const updateCityGroups = ({ cityGroupId }: { cityGroupId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/city_groups/${cityGroupId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateCityGroups.method = "PUT" as const;
/**
 * エリア(CityGroup)を取得する
 *
 */
export const readCityGroups = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/city_groups`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

readCityGroups.method = "GET" as const;
/**
 * エリア(CityGroup)を並び替える
 *
 */
export const reorderCityGroups = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/city_groups/reorder`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

reorderCityGroups.method = "POST" as const;
/**
 * 清掃のチェックインを確認する
 *
 */
export const checkCleaningsCheckinByIds = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/checkin`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

checkCleaningsCheckinByIds.method = "POST" as const;
/**
 * 清掃員が、自身のパスを取得する
 *
 */
export const listCleanerHome = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner_homes/list`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listCleanerHome.method = "POST" as const;
/**
 * 清掃を自動割り当てする
 *
 */
export const autoAssignCleaners = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/auto_assign`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

autoAssignCleaners.method = "POST" as const;
/**
 * 清掃を自動割り当てする
 *
 */
export const autoAssignCleanersForCheckinCleanings = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/auto_assign_for_checkin_cleanings`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

autoAssignCleanersForCheckinCleanings.method = "POST" as const;
/**
 * オペレーションの添付画像をアップロードする
 *
 */
export const uploadOperationAttachmentImage = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/operations/${cleaningId}/attachment_images`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

uploadOperationAttachmentImage.method = "POST" as const;
/**
 * オペレーションの添付画像を取得する
 *
 */
export const findOperationAttachmentImagesForManager = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/operations/${cleaningId}/attachment_images`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findOperationAttachmentImagesForManager.method = "GET" as const;
/**
 * オペレーションの添付画像を削除する
 *
 */
export const deleteOperationAttachmentImage = ({
  imageId,
}: {
  imageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/operations/attachment_images/${imageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteOperationAttachmentImage.method = "DELETE" as const;
/**
 * オペレーションの件数を検索する
 *
 */
export const countSearchOperations = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/operations/count`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

countSearchOperations.method = "POST" as const;
/**
 * オペレーションをsubmission_idで検索する
 *
 */
export const searchOperationsBySubmissionId = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/operations/search/submission_id`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchOperationsBySubmissionId.method = "POST" as const;
/**
 * 清掃員が自身に紐づく清掃の一覧を取得する
 *
 */
export const countActiveOperationsForCleaner = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/mypage/cleanings/count`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

countActiveOperationsForCleaner.method = "GET" as const;
/**
 * 清掃の、ユーザーごとの最新の承認リクエストを取得する
 *
 */
export const listLatestApprovalRequestPerUserByCleaningIds = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/approval_requests/latest`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listLatestApprovalRequestPerUserByCleaningIds.method = "POST" as const;
/**
 * 清掃の特別ボーナスを更新する
 *
 */
export const updateSpecialBonusByCleaningId = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/special_bonus`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateSpecialBonusByCleaningId.method = "POST" as const;
/**
 * 清掃のステータスを取得する
 *
 */
export const getCleaningStatus = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaning/status`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getCleaningStatus.method = "POST" as const;
/**
 * クリーニングIDからスペシャルボーナスを取得する
 *
 */
export const getSpecialBonusByCleaningId = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/special_bonus`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getSpecialBonusByCleaningId.method = "GET" as const;
/**
 * 清掃員のロールをリストする
 *
 */
export const listCleanerRolesByUserIds = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner_roles`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listCleanerRolesByUserIds.method = "POST" as const;
/**
 * 清掃員のロールを保存する
 *
 */
export const saveCleanerRole = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner_roles/save`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveCleanerRole.method = "POST" as const;
/**
 * 1件の清掃に割り当てられた全清掃員を解除する
 *
 */
export const unassignAllCleanerByCleaningId = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/unassign/allCleaners/${cleaningId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

unassignAllCleanerByCleaningId.method = "DELETE" as const;
/**
 * ツアー付きオペレーションの単価を保存する
 *
 */
export const savePhotoTourUnitPrice = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tour_unit_price/save`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

savePhotoTourUnitPrice.method = "PUT" as const;
/**
 * 単価の計算式を保存する
 *
 */
export const saveUnitPriceFormula = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/unit_price_formula/save`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveUnitPriceFormula.method = "PUT" as const;
/**
 * 単価の計算式を取得する
 *
 */
export const findUnitPriceFormula = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/unit_price_formula/find`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findUnitPriceFormula.method = "GET" as const;
/**
 * 清掃のスケジュールを更新する
 *
 */
export const updateCleaningSchedule = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/schedule`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateCleaningSchedule.method = "POST" as const;
/**
 * 清掃のスケジュールを更新する
 *
 */
export const updateCleaningScheduleForBiz = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/public/cleanings/${cleaningId}/schedule`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateCleaningScheduleForBiz.method = "POST" as const;
/**
 * 清掃のタイムラインを取得する
 *
 */
export const getCleaningTimeline = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaning/timeline`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getCleaningTimeline.method = "GET" as const;
/**
 * 清掃員に対してアサインされている清掃IDを取得する
 *
 */
export const findCleaningIdsByDateAndCleanerIds = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaners/cleanings`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCleaningIdsByDateAndCleanerIds.method = "POST" as const;
/**
 * チェックインに遅れそうな清掃を取得する
 *
 */
export const getOverdueCleanings = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/overdue_for_checkin`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getOverdueCleanings.method = "GET" as const;
/**
 * 清掃の変更通知を受け取るWebhook URLを取得する
 *
 */
export const getCleaningWebhook = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaning_event/webhook`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getCleaningWebhook.method = "GET" as const;
/**
 * 清掃の変更通知を受け取るWebhook URLを保存する
 *
 */
export const saveCleaningWebhook = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaning_event/webhook`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveCleaningWebhook.method = "PUT" as const;
/**
 * 通知を取得する
 *
 */
export const getCompanyNotificationForManager = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/company_notification`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getCompanyNotificationForManager.method = "GET" as const;
/**
 * 通知を更新する
 *
 */
export const saveCompanyNotificationForManager = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/company_notification`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveCompanyNotificationForManager.method = "PUT" as const;
/**
 * 物件の滞在状況を取得する
 *
 */
export const findListingStayStatus = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listings/stayStatus`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findListingStayStatus.method = "POST" as const;
/**
 * 清掃員のタグ情報を取得する
 *
 */
export const findCleanerTagsByCleanerIds = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaners/tags`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCleanerTagsByCleanerIds.method = "POST" as const;
/**
 * 全てのタグを取得する
 *
 */
export const getAllTags = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/tags`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getAllTags.method = "GET" as const;
/**
 * 清掃員のタグを保存する
 *
 */
export const saveCleanerTag = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner_tags/save`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveCleanerTag.method = "PUT" as const;
/**
 * 清掃員のタグを全て削除する
 *
 */
export const deleteAllCleanerTags = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner_tags/delete_all`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteAllCleanerTags.method = "PUT" as const;
/**
 * @deprecated 物件管理者がお願いごとを作成する
 *
 */
export const createErrandsByListingManager = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/errands`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createErrandsByListingManager.method = "POST" as const;
/**
 * @deprecated 物件管理者がお願い事を削除する
 *
 */
export const deleteErrandByListingManager = ({
  cleaningId,
  errandId,
}: {
  cleaningId: string;
  errandId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/errands/${errandId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteErrandByListingManager.method = "DELETE" as const;
/**
 * 物件管理者が報告された写真のurlを一覧する
 *
 */
export const listCleaningTaskResultPictures = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/tasks/pictures`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listCleaningTaskResultPictures.method = "GET" as const;
/**
 * 管理者が自社に関わる設定を閲覧する
 *
 */
export const getTenantSetting = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/settings/ownTenant`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getTenantSetting.method = "GET" as const;
/**
 * 管理者が自社へのメール通知に使うアドレスを更新する
 *
 */
export const updateManagerNotifierEmailAddresses = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/settings/ownTenant/managerNotifierEmailAddresses`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateManagerNotifierEmailAddresses.method = "PUT" as const;
/**
 * 物件管理者がリスティングのキーボックス番号履歴を取得する
 *
 */
export const listListingKeyboxRotationHistories = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listings/${listingId}/keybox_rotation_histories`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listListingKeyboxRotationHistories.method = "GET" as const;
/**
 * トラブルを検索する
 *
 */
export const searchTroubles = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/troubles/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchTroubles.method = "POST" as const;
/**
 * トラブルを取得する
 *
 */
export const findTroubleById = ({ troubleId }: { troubleId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/troubles/${troubleId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findTroubleById.method = "GET" as const;
/**
 * トラブルに対するコメント一覧を取得する
 *
 */
export const listCommentsByTroubleId = ({
  troubleId,
}: {
  troubleId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/troubles/${troubleId}/comments`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listCommentsByTroubleId.method = "GET" as const;
/**
 * トラブルにコメントを投稿する
 *
 */
export const createTroubleComment = ({ troubleId }: { troubleId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/troubles/${troubleId}/comments`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createTroubleComment.method = "POST" as const;
/**
 * トラブルのコメントを更新する
 *
 */
export const updateTroubleComment = ({ troubleId }: { troubleId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/troubles/${troubleId}/comments`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateTroubleComment.method = "PUT" as const;
/**
 * トラブルのコメントを削除する
 *
 */
export const deleteTroubleComment = ({
  troubleId,
  commentId,
}: {
  troubleId: string;
  commentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/troubles/${troubleId}/comments/${commentId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteTroubleComment.method = "DELETE" as const;
/**
 * 物件を取得する
 *
 */
export const getListingById = ({ listingId }: { listingId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listings/${listingId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getListingById.method = "GET" as const;
/**
 * 物件に対する清掃一覧を取得する
 *
 */
export const findCleaningsByListingId = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listings/${listingId}/cleanings/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCleaningsByListingId.method = "POST" as const;
/**
 * 物件の請求を検索する
 *
 */
export const searchListingInvoice = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listing_invoices/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchListingInvoice.method = "POST" as const;
/**
 * 物件の請求をエクスポートする
 *
 */
export const exportListingInvoice = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listing_invoices/export`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

exportListingInvoice.method = "POST" as const;
/**
 * 物件の請求を取得する
 *
 */
export const findListingInvoiceById = ({
  invoiceId,
}: {
  invoiceId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listing_invoices/${invoiceId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findListingInvoiceById.method = "GET" as const;
/**
 * 物件に請求を作成する
 *
 */
export const createListingInvoice = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listing_invoices`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createListingInvoice.method = "POST" as const;
/**
 * 物件の請求を更新する
 *
 */
export const patchListingInvoice = ({ invoiceId }: { invoiceId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listing_invoices/${invoiceId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

patchListingInvoice.method = "PATCH" as const;
/**
 * 管理者が清掃員の情報を更新する
 *
 */
export const updateUserByManager = ({ userId }: { userId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/users/${userId}/update_by_manager`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateUserByManager.method = "POST" as const;
/**
 * 清掃員の単価情報を取得する
 *
 */
export const findCleanerCostByUserId = ({ userId }: { userId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaners/${userId}/cost`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCleanerCostByUserId.method = "GET" as const;
/**
 * 清掃員の単価情報を保存する
 *
 */
export const saveCleanerCostByUserId = ({ userId }: { userId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaners/${userId}/cost`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveCleanerCostByUserId.method = "PUT" as const;
/**
 * 清掃の単価情報を取得する
 *
 */
export const findCleaningCostByCleaningId = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/cost`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCleaningCostByCleaningId.method = "GET" as const;
/**
 * 清掃の単価情報を保存する
 *
 */
export const saveCleaningCostByCleaningId = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/cost`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveCleaningCostByCleaningId.method = "PUT" as const;
/**
 * 物件の情報を更新する
 *
 */
export const updateListing = ({ listingId }: { listingId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listings/${listingId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateListing.method = "PUT" as const;
/**
 * 清掃の履歴を取得する
 *
 */
export const findEventHistoriesByCleaningId = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/event_histories/search_by_cleaning_id/${cleaningId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findEventHistoriesByCleaningId.method = "GET" as const;
/**
 * トラブルを管理者が作成する
 *
 */
export const createTroubleByManager = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/troubles`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createTroubleByManager.method = "POST" as const;
/**
 * 三条書面を作成する
 *
 */
export const createSubcontract3 = ({ cleaningId }: { cleaningId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/subcontract3`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createSubcontract3.method = "POST" as const;
/**
 * 管理者が清掃写真をアップロードする
 *
 */
export const uploadEvidencePictureByListingManager = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/evidence`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

uploadEvidencePictureByListingManager.method = "POST" as const;
/**
 * 管理者が清掃写真を削除する
 *
 */
export const deleteEvidencePictureByListingManager = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/evidence`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteEvidencePictureByListingManager.method = "DELETE" as const;
/**
 * 清掃IDのリストから予約を検索する
 *
 */
export const searchReservationsByCleaningIds = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/reservations/search_by_cleaning_ids`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchReservationsByCleaningIds.method = "POST" as const;
/**
 * 清掃IDのリストから次の予約を検索する
 *
 */
export const searchNextReservationsByCleaningIds = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/reservations/search_next_by_cleaning_ids`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchNextReservationsByCleaningIds.method = "POST" as const;
/**
 * 物件IDのリストから物件を検索する
 *
 */
export const findListingsByIds = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listings/search_by_ids`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findListingsByIds.method = "POST" as const;
/**
 * 写真ツアーを作成する
 *
 */
export const createPhotoTour = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tours`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createPhotoTour.method = "POST" as const;
/**
 * 写真ツアーを更新する
 *
 */
export const updatePhotoTour = ({ photoTourId }: { photoTourId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tours/${photoTourId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updatePhotoTour.method = "PUT" as const;
/**
 * 写真ツアーのアイテムを取得する
 *
 */
export const findPhotoTourItemsByPhotoTourId = ({
  photoTourId,
}: {
  photoTourId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tours/${photoTourId}/items`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findPhotoTourItemsByPhotoTourId.method = "GET" as const;
/**
 * 写真ツアーのアイテムを作成する
 *
 */
export const createPhotoTourItem = ({
  photoTourId,
}: {
  photoTourId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tours/${photoTourId}/items`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createPhotoTourItem.method = "POST" as const;
/**
 * 写真ツアーのアイテムを更新する
 *
 */
export const updatePhotoTourItem = ({
  photoTourId,
  photoTourItemId,
}: {
  photoTourId: string;
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tours/${photoTourId}/items/${photoTourItemId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updatePhotoTourItem.method = "PUT" as const;
/**
 * 写真ツアーのアイテムを削除する
 *
 */
export const deletePhotoTourItem = ({
  photoTourId,
  photoTourItemId,
}: {
  photoTourId: string;
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tours/${photoTourId}/items/${photoTourItemId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deletePhotoTourItem.method = "DELETE" as const;
/**
 * 清掃IDから写真ツアーの画像を取得する
 *
 */
export const findPhotoTourImagesByCleaningId = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tour_images/by_cleaning_id/${cleaningId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findPhotoTourImagesByCleaningId.method = "GET" as const;
/**
 * 写真ツアーの画像をアップロードする
 *
 */
export const uploadPhotoTourImage = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tour_images`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

uploadPhotoTourImage.method = "POST" as const;
/**
 * 写真ツアーの画像を削除する
 *
 */
export const deletePhotoTourImage = ({
  photoTourImageId,
}: {
  photoTourImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tour_images/${photoTourImageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deletePhotoTourImage.method = "DELETE" as const;
/**
 * 写真ツアーのアイテムのサンプル画像を作成する
 *
 */
export const createPhotoTourItemSampleImage = ({
  photoTourItemId,
}: {
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tour_items/${photoTourItemId}/sample_images`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createPhotoTourItemSampleImage.method = "POST" as const;
/**
 * 写真ツアーのアイテムのサンプル画像を更新する
 *
 */
export const updatePhotoTourItemSampleImage = ({
  photoTourItemSampleImageId,
}: {
  photoTourItemSampleImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tour_item_sample_images/${photoTourItemSampleImageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updatePhotoTourItemSampleImage.method = "PUT" as const;
/**
 * 写真ツアーのアイテムのサンプル画像を削除する
 *
 */
export const deletePhotoTourItemSampleImage = ({
  photoTourItemSampleImageId,
}: {
  photoTourItemSampleImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tour_item_sample_images/${photoTourItemSampleImageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deletePhotoTourItemSampleImage.method = "DELETE" as const;
/**
 * 写真ツアーのアイテムのサンプル画像を取得する
 *
 */
export const findPhotoTourItemSampleImagesByPhotoTourItemId = ({
  photoTourItemId,
}: {
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tour_items/${photoTourItemId}/sample_images`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findPhotoTourItemSampleImagesByPhotoTourItemId.method = "GET" as const;
/**
 * 指定した物件の清掃を会社間で移動する
 *
 */
export const transferCleaning = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/transfer_cleaning`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

transferCleaning.method = "POST" as const;
/**
 * 会社IDから写真ツアーを取得する
 *
 */
export const findPhotoToursByCompanyId = ({
  companyId,
}: {
  companyId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/photo_tours/by_company_id/${companyId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findPhotoToursByCompanyId.method = "GET" as const;
/**
 * 清掃をPlacementを指定して作成する
 *
 */
export const createCleaningWithPlacement = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/create_with_placement`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createCleaningWithPlacement.method = "POST" as const;
/**
 * 清掃をPlacementを指定して取得する,デフォルトは1000件上限,pageとpageSize両方を指定した場合にはその上限を超えて10000件まで取得可能,pageSize10000超えてリクエスト可能だがその場合10000件指定と同じ結果を返す
 *
 */
export const findCleaningsWithPlacement = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/with_placement`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCleaningsWithPlacement.method = "POST" as const;
/**
 * 清掃をphoto_tour_idを指定して取得する
 *
 */
export const findCleaningsWithPhotoTourIds = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/with_photo_tour_ids`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCleaningsWithPhotoTourIds.method = "POST" as const;
/**
 * 清掃をphoto_tour_idを指定して取得する(system用)
 *
 */
export const findCleaningsWithPhotoTourIdsForSystem = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/system/cleanings/with_photo_tour_ids`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCleaningsWithPhotoTourIdsForSystem.method = "POST" as const;
/**
 * 物件IDから共用部分を取得する
 *
 */
export const findCommonAreaByPropertyId = ({
  propertyId,
}: {
  propertyId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/common_areas/by_property_id/${propertyId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCommonAreaByPropertyId.method = "GET" as const;
/**
 * 会社IDから共用部分を取得する
 *
 */
export const findCommonAreasByCompanyId = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/common_areas/by_company_id`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCommonAreasByCompanyId.method = "GET" as const;
/**
 * 共用部分を取得する
 *
 */
export const findCommonAreaById = ({
  commonAreaId,
}: {
  commonAreaId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/common_areas/${commonAreaId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCommonAreaById.method = "GET" as const;
/**
 * 物件を取得する
 *
 */
export const findPropertyById = ({ propertyId }: { propertyId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/properties/${propertyId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findPropertyById.method = "GET" as const;
/**
 * 会社IDから物件を取得する
 *
 */
export const findPropertiesByCompanyId = ({
  companyId,
}: {
  companyId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/properties/by_company_id/${companyId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findPropertiesByCompanyId.method = "GET" as const;
/**
 * 自社の清掃実績一覧を取得する
 *
 */
export const listCleaningAchievements = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/achievements`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listCleaningAchievements.method = "POST" as const;
/**
 * 委譲先の清掃実績一覧を取得する
 *
 */
export const listDelegateCleaningAchievements = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/delegate_cleanings/achievements`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listDelegateCleaningAchievements.method = "POST" as const;
/**
 * 物件管理者が自社のリスティングの一覧を取得する
 *
 */
export const listListings = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listings`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listListings.method = "GET" as const;
/**
 * 自社に管理を委譲された物件一覧を取得する
 *
 */
export const listDelegatedListings = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/mypage/listings/delegated`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listDelegatedListings.method = "GET" as const;
/**
 * ある物件の全ての清掃タイプの清掃料金を取得する
 *
 */
export const getCleaningTypeFeeListByListing = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listings/${listingId}/cleaning_type_fee_list`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getCleaningTypeFeeListByListing.method = "GET" as const;
/**
 * 物件の清掃タイプごとの清掃料金を更新する
 *
 */
export const putCleaningTypeFeeListByListing = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listings/${listingId}/cleaning_type_fee_list`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

putCleaningTypeFeeListByListing.method = "PUT" as const;
/**
 * 清掃マニュアルの画像をアップロードする
 *
 */
export const uploadManualPicture = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/manual_pictures`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

uploadManualPicture.method = "POST" as const;
/**
 * ヘルスチェックを行う
 *
 */
export const healthCheck = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/health_check`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

healthCheck.method = "GET" as const;
/**
 * チェック項目の一覧を取得する
 *
 */
export const listCheckitems = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/checkitems`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listCheckitems.method = "GET" as const;
/**
 * チェック項目の一覧を保存する
 *
 */
export const setCheckitems = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/checkitems`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

setCheckitems.method = "PUT" as const;
/**
 * 清掃を新規作成する
 *
 */
export const createCleaning = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createCleaning.method = "POST" as const;
/**
 * 清掃を更新する
 *
 */
export const updateCleaning = ({ cleaningId }: { cleaningId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateCleaning.method = "PUT" as const;
/**
 * 物件管理者が清掃を更新する
 *
 */
export const updateCleaningByListingManager = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/listing_manager`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateCleaningByListingManager.method = "PUT" as const;
/**
 * 指定した清掃を取得する
 *
 */
export const getCleaning = ({ cleaningId }: { cleaningId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getCleaning.method = "GET" as const;
/**
 * 委譲先の清掃を取得する
 *
 */
export const getDelegateCleaning = ({ cleaningId }: { cleaningId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/delegate_cleanings/${cleaningId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getDelegateCleaning.method = "GET" as const;
/**
 * 指定した物件の指定した日付以降の清掃を会社間で移動する
 *
 */
export const transferCleanings = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/transfer_cleanings`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

transferCleanings.method = "POST" as const;
/**
 * 清掃に紐づくお願い事を一覧する
 *
 */
export const listErrands = ({ cleaningId }: { cleaningId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/errands`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listErrands.method = "GET" as const;
/**
 * 割り当てられた清掃員の一覧を取得する
 *
 */
export const listAssignedCleaners = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/cleaners`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listAssignedCleaners.method = "GET" as const;
/**
 * 清掃員を割り当てる
 *
 */
export const assignCleaners = ({ cleaningId }: { cleaningId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/cleaners`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

assignCleaners.method = "POST" as const;
/**
 * 清掃員の割り当てを解除する
 *
 */
export const unassignCleaners = ({ cleaningId }: { cleaningId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/cleaners`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

unassignCleaners.method = "DELETE" as const;
/**
 * 清掃管理者が物件名,日付で清掃を検索する
 *
 */
export const searchCleanings = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/search/cleanings`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchCleanings.method = "POST" as const;
/**
 * 清掃管理者が物件名,日付で清掃を検索する
 *
 */
export const searchDelegateCleanings = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/search/delegate_cleanings`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchDelegateCleanings.method = "POST" as const;
/**
 * 物件の清掃管理を委譲する
 *
 */
export const delegateListing = ({ listingId }: { listingId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listings/${listingId}/delegation`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

delegateListing.method = "PUT" as const;
/**
 * 物件の清掃管理の委譲を削除する
 *
 */
export const undelegateListing = ({ listingId }: { listingId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listings/${listingId}/delegation`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

undelegateListing.method = "DELETE" as const;
/**
 * 物件固有の清掃マニュアルの一覧を取得する.
 *
 */
export const getManualsOfListing = ({ listingId }: { listingId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listings/${listingId}/manuals`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getManualsOfListing.method = "GET" as const;
/**
 * 物件固有の清掃マニュアルを作成する.
 *
 */
export const createManualOfListing = ({ listingId }: { listingId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listings/${listingId}/manuals`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createManualOfListing.method = "POST" as const;
/**
 * 物件固有の清掃マニュアルを更新する.
 *
 */
export const updateManualOfListing = ({
  listingId,
  manualId,
}: {
  listingId: string;
  manualId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listings/${listingId}/manuals/${manualId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateManualOfListing.method = "PUT" as const;
/**
 * 物件固有の清掃マニュアルを削除する.
 *
 */
export const deleteManualOfListing = ({
  listingId,
  manualId,
}: {
  listingId: string;
  manualId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listings/${listingId}/manuals/${manualId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteManualOfListing.method = "DELETE" as const;
/**
 * 物件管理者が委譲先に対して清掃を作成する
 *
 */
export const createCleaningOfListing = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/listings/${listingId}/cleanings`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createCleaningOfListing.method = "POST" as const;
/**
 * タスク一覧を取得する
 *
 */
export const listTasks = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/tasks`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listTasks.method = "GET" as const;
/**
 * タスク一覧を設定する
 *
 */
export const setTasks = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/tasks`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

setTasks.method = "POST" as const;
/**
 * 指定したタスクを削除する
 *
 */
export const deleteTask = ({ taskId }: { taskId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/tasks/${taskId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteTask.method = "DELETE" as const;
/**
 * トラブル一覧を取得する
 *
 */
export const listTroubles = ({ cleaningId }: { cleaningId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/troubles`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listTroubles.method = "GET" as const;
/**
 * 清掃マニュアルの一覧を取得する.
 *
 */
export const getManuals = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/manuals`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getManuals.method = "GET" as const;
/**
 * 清掃マニュアルを作成する.
 *
 */
export const createManual = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/manuals`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createManual.method = "POST" as const;
/**
 * 清掃マニュアルを取得する.
 *
 */
export const getManual = ({ manualId }: { manualId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/manuals/${manualId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getManual.method = "GET" as const;
/**
 * 清掃マニュアルを更新する.
 *
 */
export const updateManual = ({ manualId }: { manualId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/manuals/${manualId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateManual.method = "PUT" as const;
/**
 * 清掃マニュアルを削除する.
 *
 */
export const deleteManual = ({ manualId }: { manualId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/manuals/${manualId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteManual.method = "DELETE" as const;
/**
 * 自社の清掃員一覧を取得する
 *
 */
export const listCleaners = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/mypage/cleaners`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listCleaners.method = "GET" as const;
/**
 * 清掃員の情報を更新する
 *
 */
export const updateCleaners = ({ cleanerId }: { cleanerId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/mypage/cleaners/${cleanerId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateCleaners.method = "PUT" as const;
/**
 * 清掃員を作成する
 *
 */
export const createCleaner = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaners`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createCleaner.method = "POST" as const;
/**
 * 清掃員をインポートする
 *
 */
export const importCleanerByEmail = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaners/import`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

importCleanerByEmail.method = "POST" as const;
/**
 * 物件管理者が請求を取得する
 *
 */
export const findInvoiceByListingManager = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/listing_manager_invoices`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findInvoiceByListingManager.method = "GET" as const;
/**
 * 物件管理者が請求を更新する
 *
 */
export const updateInvoiceByListingManager = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/listing_manager_invoices`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateInvoiceByListingManager.method = "PUT" as const;
/**
 * 清掃管理者が請求を取得する
 *
 */
export const findInvoiceByCleaningManager = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/cleaning_manager_invoices`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findInvoiceByCleaningManager.method = "GET" as const;
/**
 * 清掃管理者が請求を更新する
 *
 */
export const updateInvoiceByCleaningManager = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleanings/${cleaningId}/cleaning_manager_invoices`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateInvoiceByCleaningManager.method = "PUT" as const;
/**
 * 清掃の通知を物件ごとに再送する
 *
 */
export const cleaningEventRenotifyByListing = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaningEvents/renotify_by_listing`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

cleaningEventRenotifyByListing.method = "POST" as const;
/**
 * 清掃の通知を日付範囲を指定して再送する
 *
 */
export const cleaningEventRenotifyByDateSpan = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaningEvents/renotify_by_datespan`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

cleaningEventRenotifyByDateSpan.method = "POST" as const;
/**
 * OtaIDから清掃を検索する
 *
 */
export const findCleaningsByOtaId = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/search_cleanings/ota_id`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCleaningsByOtaId.method = "POST" as const;
/**
 * トラブルを解決する
 *
 */
export const resolveTrouble = ({ troubleId }: { troubleId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/troubles/${troubleId}/resolve`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

resolveTrouble.method = "POST" as const;
/**
 * トラブルを未解決に戻す
 *
 */
export const unresolveTrouble = ({ troubleId }: { troubleId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/troubles/${troubleId}/unresolve`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

unresolveTrouble.method = "POST" as const;
export const m2mCleaning_managerV4 = {
  findOperationById,
  searchOperations,
  createOperationManagerTrouble,
  findPlacementById,
  searchPlacements,
  diablePhotoTour,
  enablePhotoTour,
  diablePhotoTourItem,
  enablePhotoTourItem,
  findPhotoTourMovie,
  deletePhotoTourMovie,
  findPhotoTourMovieByPlacementId,
  findCleaningImageViewByListingId,
  findCleaningImageViewByCommonAreaId,
  getCleaningPhotoTourCounts,
  updateShiftsStatus,
  updateShiftDate,
  listShifts,
  findListingCleaningsByIds,
  reindexPhotoTourItems,
  searchCommonAreaByName,
  registerCleanerPathWithCleanerId,
  registerCleanerPath,
  listCleanerPath,
  listCleanerPathByDateAndUser,
  saveCleanerHome,
  updateCleanerPath,
  findPlacements,
  findCleanings,
  saveCleanerRemarks,
  saveCleanerAssessment,
  findDetailsByCleanerIds,
  saveCleanerSkill,
  saveCleanerAutoAssignEnabled,
  saveCleanerUnitPrice,
  findCleanerUnitPricesByCleanerIds,
  saveCleanerFactor,
  searchAddressesByPlacementIds,
  createCityGroups,
  deleteCityGroup,
  updateCityGroups,
  readCityGroups,
  reorderCityGroups,
  checkCleaningsCheckinByIds,
  listCleanerHome,
  autoAssignCleaners,
  autoAssignCleanersForCheckinCleanings,
  uploadOperationAttachmentImage,
  findOperationAttachmentImagesForManager,
  deleteOperationAttachmentImage,
  countSearchOperations,
  searchOperationsBySubmissionId,
  countActiveOperationsForCleaner,
  listLatestApprovalRequestPerUserByCleaningIds,
  updateSpecialBonusByCleaningId,
  getCleaningStatus,
  getSpecialBonusByCleaningId,
  listCleanerRolesByUserIds,
  saveCleanerRole,
  unassignAllCleanerByCleaningId,
  savePhotoTourUnitPrice,
  saveUnitPriceFormula,
  findUnitPriceFormula,
  updateCleaningSchedule,
  updateCleaningScheduleForBiz,
  getCleaningTimeline,
  findCleaningIdsByDateAndCleanerIds,
  getOverdueCleanings,
  getCleaningWebhook,
  saveCleaningWebhook,
  getCompanyNotificationForManager,
  saveCompanyNotificationForManager,
  findListingStayStatus,
  findCleanerTagsByCleanerIds,
  getAllTags,
  saveCleanerTag,
  deleteAllCleanerTags,
  createErrandsByListingManager,
  deleteErrandByListingManager,
  listCleaningTaskResultPictures,
  getTenantSetting,
  updateManagerNotifierEmailAddresses,
  listListingKeyboxRotationHistories,
  searchTroubles,
  findTroubleById,
  listCommentsByTroubleId,
  createTroubleComment,
  updateTroubleComment,
  deleteTroubleComment,
  getListingById,
  findCleaningsByListingId,
  searchListingInvoice,
  exportListingInvoice,
  findListingInvoiceById,
  createListingInvoice,
  patchListingInvoice,
  updateUserByManager,
  findCleanerCostByUserId,
  saveCleanerCostByUserId,
  findCleaningCostByCleaningId,
  saveCleaningCostByCleaningId,
  updateListing,
  findEventHistoriesByCleaningId,
  createTroubleByManager,
  createSubcontract3,
  uploadEvidencePictureByListingManager,
  deleteEvidencePictureByListingManager,
  searchReservationsByCleaningIds,
  searchNextReservationsByCleaningIds,
  findListingsByIds,
  createPhotoTour,
  updatePhotoTour,
  findPhotoTourItemsByPhotoTourId,
  createPhotoTourItem,
  updatePhotoTourItem,
  deletePhotoTourItem,
  findPhotoTourImagesByCleaningId,
  uploadPhotoTourImage,
  deletePhotoTourImage,
  createPhotoTourItemSampleImage,
  updatePhotoTourItemSampleImage,
  deletePhotoTourItemSampleImage,
  findPhotoTourItemSampleImagesByPhotoTourItemId,
  transferCleaning,
  findPhotoToursByCompanyId,
  createCleaningWithPlacement,
  findCleaningsWithPlacement,
  findCleaningsWithPhotoTourIds,
  findCleaningsWithPhotoTourIdsForSystem,
  findCommonAreaByPropertyId,
  findCommonAreasByCompanyId,
  findCommonAreaById,
  findPropertyById,
  findPropertiesByCompanyId,
  listCleaningAchievements,
  listDelegateCleaningAchievements,
  listListings,
  listDelegatedListings,
  getCleaningTypeFeeListByListing,
  putCleaningTypeFeeListByListing,
  uploadManualPicture,
  healthCheck,
  listCheckitems,
  setCheckitems,
  createCleaning,
  updateCleaning,
  updateCleaningByListingManager,
  getCleaning,
  getDelegateCleaning,
  transferCleanings,
  listErrands,
  listAssignedCleaners,
  assignCleaners,
  unassignCleaners,
  searchCleanings,
  searchDelegateCleanings,
  delegateListing,
  undelegateListing,
  getManualsOfListing,
  createManualOfListing,
  updateManualOfListing,
  deleteManualOfListing,
  createCleaningOfListing,
  listTasks,
  setTasks,
  deleteTask,
  listTroubles,
  getManuals,
  createManual,
  getManual,
  updateManual,
  deleteManual,
  listCleaners,
  updateCleaners,
  createCleaner,
  importCleanerByEmail,
  findInvoiceByListingManager,
  updateInvoiceByListingManager,
  findInvoiceByCleaningManager,
  updateInvoiceByCleaningManager,
  cleaningEventRenotifyByListing,
  cleaningEventRenotifyByDateSpan,
  findCleaningsByOtaId,
  resolveTrouble,
  unresolveTrouble,
};
