import { ArrowBack } from "@mui/icons-material"
import { Button, Checkbox, TextField, Typography, useAlert } from "matsuri-ui"
import { CityGroup } from "@/domain/cityGroup"
import { FetcherResult } from "matsuri-hooks"
import { Link } from "react-router-dom"
import { Prefecture } from "@/domain/prefecture"
import { css } from "@emotion/react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useCities } from "@/hooks/useCity"
import { usePrefectures } from "@/hooks/usePrefecture"
import ExpandLessIcon from "@mui/icons-material/ExpandMore"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

const CitySelections = ({
    prefectureId,
    onCitiesChange,
    selectedCityIds
}: {
    prefectureId: string
    selectedCityIds: string[]
    onCitiesChange: (cityIds: string[]) => void
}) => {
    const { data: citiesResp } = useCities({
        prefectureId
    })
    const cities = citiesResp ?? []

    return (
        <ul
            css={css`
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                margin-left: 16px;
                list-style: none;
            `}
        >
            {cities.map(city => (
                <li key={city.cityId}>
                    <Checkbox
                        checked={selectedCityIds.includes(city.cityId)}
                        onChange={e => {
                            const { checked } = e.target
                            if (checked) {
                                onCitiesChange([
                                    ...selectedCityIds,
                                    city.cityId
                                ])
                                return
                            }

                            onCitiesChange(
                                selectedCityIds.filter(c => c !== city.cityId)
                            )
                        }}
                    >
                        {city.cityName}
                    </Checkbox>
                </li>
            ))}
        </ul>
    )
}

const ExpandablePrefecture = ({
    prefecture,
    onCitiesChange,
    selectedCityIds
}: {
    prefecture: Prefecture
    selectedCityIds: string[]
    onCitiesChange: (cityIds: string[]) => void
}) => {
    const [open, setOpen] = useState(false)
    const toggleButtonIcon = useMemo(
        () => (open ? <ExpandMoreIcon /> : <ExpandLessIcon />),
        [open]
    )

    return (
        <div>
            <Button
                type="button"
                endIcon={toggleButtonIcon}
                onClick={() => setOpen(!open)}
            >
                {prefecture.prefectureName}
            </Button>

            {open && (
                <CitySelections
                    prefectureId={prefecture.prefectureId}
                    selectedCityIds={selectedCityIds}
                    onCitiesChange={onCitiesChange}
                />
            )}
        </div>
    )
}

const AreaSelections = ({
    prefectures,
    onCitiesChange,
    selectedCityIds
}: {
    prefectures: Prefecture[]
    selectedCityIds: string[]
    onCitiesChange: (cityIds: string[]) => void
}) => {
    return (
        <ul
            css={css`
                list-style: none;
            `}
        >
            {prefectures.map(prefecture => (
                <li key={prefecture.prefectureId}>
                    <ExpandablePrefecture
                        prefecture={prefecture}
                        selectedCityIds={selectedCityIds}
                        onCitiesChange={onCitiesChange}
                    />
                </li>
            ))}
        </ul>
    )
}

interface AreaEditInput {
    name: string
    cityIds: string[]
}
type CityGroupSaveFunc = (
    cityGroup: AreaEditInput
) => Promise<FetcherResult<void>>

interface AreaEditViewProps {
    saveFunc: CityGroupSaveFunc
    onSave?: () => void
    cityGroup?: CityGroup
}

export const AreaEditView = ({
    saveFunc,
    onSave,
    cityGroup
}: AreaEditViewProps) => {
    const { data: prefecturesResp } = usePrefectures()
    const [prefectureFilter, setPrefectureFilter] = useState("")
    const filteredPrefectures = useMemo(() => {
        const prefectures = prefecturesResp ?? []
        return prefectureFilter
            ? prefectures.filter(p => {
                  return new RegExp(prefectureFilter).test(p.prefectureName)
              })
            : prefectures
    }, [prefecturesResp, prefectureFilter])
    const [areaEditInput, setCityGroup] = useState<AreaEditInput>({
        name: "",
        cityIds: []
    })

    useEffect(() => {
        if (!cityGroup) return
        setCityGroup(cityGroup)
    }, [cityGroup])

    const { throwAlert, addAlert } = useAlert()

    const handleSave = useCallback(async () => {
        if (areaEditInput.name === "") {
            addAlert("エリア名を入力してください", {
                severity: "error"
            })

            return
        } else if (areaEditInput.cityIds.length === 0) {
            addAlert("地区を選択してください", {
                severity: "error"
            })

            return
        }
        const { error } = await saveFunc(areaEditInput)
        throwAlert(error, {
            errorMessage: "エリアの作成に失敗しました"
        })

        // navigate("/area/list")
        onSave?.()
    }, [areaEditInput, throwAlert, addAlert, saveFunc, onSave])

    const handleCitiesChange = useCallback(
        (cityIds: string[]) => {
            setCityGroup(v => ({ ...v, cityIds }))
        },
        [setCityGroup]
    )

    return (
        <div>
            <div
                css={css`
                    margin: 16px 0;
                `}
            >
                <Link
                    to="/area/list"
                    css={css`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <ArrowBack />
                    エリア一覧に戻る
                </Link>
            </div>
            <div
                css={css`
                    display: flex;
                    justify-content: space-between;
                    margin-top: 48px;
                `}
            >
                <TextField
                    placeholder="エリア名を入力"
                    value={areaEditInput.name}
                    onChange={name => setCityGroup(v => ({ ...v, name }))}
                />
                <Button variant="filled" color="primary" onClick={handleSave}>
                    保存
                </Button>
            </div>
            <div
                css={css`
                    margin-top: 16px;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        margin-top: 48px;
                    `}
                >
                    <Typography variant="h4">地区選択</Typography>
                    <Typography variant="body">都道府県を検索</Typography>
                    <TextField
                        placeholder="都道府県を検索"
                        value={prefectureFilter}
                        onChange={value => setPrefectureFilter(value)}
                    />
                    <AreaSelections
                        prefectures={filteredPrefectures}
                        onCitiesChange={handleCitiesChange}
                        selectedCityIds={areaEditInput.cityIds}
                    />
                </div>
            </div>
        </div>
    )
}
