import { FormIDSearch } from "./Tabs/FormIDSearch"
import { OTASearch } from "./Tabs/OTASearch"
import { Paper, Popper, TabUI } from "matsuri-ui"
import { ReactNode } from "react"
import { css } from "@emotion/react"

export const SearchPopper = ({
    anchorEl,
    open,
    closePopper
}: {
    anchorEl: HTMLElement | undefined
    open: boolean
    closePopper: () => void
}) => {
    return (
        <Popper anchorEl={anchorEl} open={open} onClickOutside={closePopper}>
            <Paper
                css={css`
                    padding: 16px 16px 0;
                `}
            >
                <TabUI
                    tabs={[
                        {
                            screen: <FormIDSearch />,
                            title: "フォームID検索"
                        },
                        {
                            screen: <OTASearch onFinished={closePopper} />,
                            title: "OTA ID検索"
                        }
                    ].map(tab => ({
                        ...tab,
                        screen: <TabContainer>{tab.screen}</TabContainer>
                    }))}
                />
            </Paper>
        </Popper>
    )
}

const TabContainer = ({ children }: { children: ReactNode }) => {
    return (
        <div
            css={css`
                padding: 16px 0;
            `}
        >
            {children}
        </div>
    )
}
