/* eslint-disable */
/**
 * A function that returns the URL part common to the endpoints.
 */
export const root = () => {
  let __root = "";

  if (process.env.RUNTIME_ENV === "local") {
    __root = "https://api-notifications.dev.m2msystems.cloud";
  }

  if (process.env.RUNTIME_ENV === "localDev") {
    __root = "";
  }

  if (process.env.RUNTIME_ENV === "development") {
    __root = "https://api-notifications.dev.m2msystems.cloud";
  }

  if (process.env.RUNTIME_ENV === "production") {
    __root = "https://api-notifications.m2msystems.cloud";
  }

  return __root;
};
/**
 * health check
 *
 */
export const healthCheck = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`health_check`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

healthCheck.method = "GET" as const;
/**
 * 通知を登録する
 *
 */
export const registerNotification = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`notifications`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

registerNotification.method = "POST" as const;
/**
 * 自分宛の通知をserviceIdを使って取得する
 *
 */
export const findNotificationsByServiceId = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`notifications/by_service_id/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findNotificationsByServiceId.method = "GET" as const;
/**
 * 通知のステータスを更新する
 *
 */
export const updateNotificationStatus = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`notifications/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateNotificationStatus.method = "PUT" as const;
/**
 * 通知の購読を更新する
 *
 */
export const saveSubscription = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`subscriptions`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveSubscription.method = "POST" as const;
/**
 * 自分の購読している通知を取得する
 *
 */
export const findMySubscriptions = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`subscriptions`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findMySubscriptions.method = "GET" as const;
export const m2mNotifications_v1 = {
  healthCheck,
  registerNotification,
  findNotificationsByServiceId,
  updateNotificationStatus,
  saveSubscription,
  findMySubscriptions,
};
