import styled from "@emotion/styled"

export const Header = styled.header`
    padding: 1em 0;
    height: 90px;
`

export const HeaderCenter = styled(Header)`
    display: flex;
    justify-content: center;
`

export const TableActions = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
`
