import { CustomTheme, Theme, defaultTheme } from "matsuri-ui"

export const cleaningTheme = {
    palette: {
        intentions: {
            primary: {
                light: "#B1E8F3",
                main: "#03B3D2",
                dark: "#0C7791",
                contrastText: "#fff"
            },
            secondary: {
                light: "#FECBB9",
                main: "#FB560F",
                dark: "#EEEEEE",
                contrastText: "#fff"
            },
            unassigned: {
                light: "#EF9A9A",
                main: "#EF9A9A",
                dark: "#EF9A9A",
                contrastText: "#000"
            },
            prepared: {
                light: "#FFE082",
                main: "#FFE082",
                dark: "#FFE082",
                contrastText: "#000"
            },
            started: {
                light: "#A5D6A7",
                main: "#A5D6A7",
                dark: "#A5D6A7",
                contrastText: "#000"
            },
            finished: {
                light: "#A6C3FB",
                main: "#A6C3FB",
                dark: "#A6C3FB",
                contrastText: "#000"
            },
            reported: {
                light: "#E4C1F9",
                main: "#E4C1F9",
                dark: "#E4C1F9",
                contrastText: "#000"
            },
            disabled: {
                light: "#AAAAAA",
                main: "#AAAAAA",
                dark: "#AAAAAA",
                contrastText: "#fff"
            }
        }
    }
}

export const theme: Theme & CustomTheme = {
    ...defaultTheme,
    palette: {
        ...defaultTheme.palette,
        intentions: {
            ...defaultTheme.palette.intentions,
            ...cleaningTheme.palette?.intentions
        }
    }
}
