import { DateString } from "@/helpers/dateString"
import { m2mCleaning_managerV4 } from "@/endpoints/m2m-cleaning.manager-v4"
import { useAuth } from "./useAuth"
import { useAuthBearerFetch } from "matsuri-hooks"

interface FindCleaningIdsByDateAndCleanerInput {
    date: DateString
    cleanerIds: string[]
}

type CleanerId = string
type CleaningId = string

type FindCleaningIdsByDateAndCleanerOutput = Record<CleanerId, CleaningId[]>

export const useFindCleaningIdsByDateAndCleaner = (
    input: FindCleaningIdsByDateAndCleanerInput
) => {
    const { token } = useAuth()
    const endpoint = m2mCleaning_managerV4.findCleaningIdsByDateAndCleanerIds

    return useAuthBearerFetch<FindCleaningIdsByDateAndCleanerOutput>(
        token,
        endpoint(),
        {
            method: endpoint.method,
            body: JSON.stringify(input)
        }
    )
}
