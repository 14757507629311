import { Operation } from "@/domain/operation"
import { bearerFetcher, useAuthBearerFetch } from "matsuri-hooks"
import { m2mCleaning_managerV4 } from "@/endpoints/m2m-cleaning.manager-v4"
import { useAuth } from "./useAuth"

export const useOperations = ({ operationIds }: { operationIds: string[] }) => {
    const { token } = useAuth()

    const endpoint = m2mCleaning_managerV4.findOperationById

    return useAuthBearerFetch<Operation[]>(
        token,
        operationIds.length > 0
            ? `/unused-endpoint/${operationIds.join(",")}`
            : null,
        {
            method: endpoint.method,
            fetch: composeFetcher(operationIds)
        }
    )
}

type Fetcher<T> = (args: { url: string; token?: string }) => Promise<T>

const composeFetcher = (operationIds: string[]) => {
    const fetcher: Fetcher<Operation[]> = async ({ token }) => {
        if (!token) throw new Error("token is not defined")

        const endpoint = m2mCleaning_managerV4.findOperationById

        const promises = operationIds.map(
            async operationId =>
                await bearerFetcher<Operation>(
                    token,
                    endpoint({ operationId }),
                    {
                        method: endpoint.method
                    }
                )
        )

        const results = await Promise.all(promises)

        const ret: Operation[] = []

        results.forEach(({ data, error }) => {
            // 1つでもエラーがあればエラーを投げる
            if (error) throw error
            if (!data) return
            ret.push(data)
        })

        return ret
    }
    return fetcher
}
