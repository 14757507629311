import { CleaningStatus, displayCleaningStatus } from "@/domain/cleaning"
import React from "react"
import styled from "@emotion/styled"

const getLabelAndColor = (
    status: CleaningStatus
): { label: string; color: string } => {
    const label = displayCleaningStatus(status)
    const color = {
        prepared: "#FFE082",
        started: "#A5D6A7",
        finished: "#A6C3FB",
        reported: "#E4C1F9"
    }[status]

    return { label, color }
}

const ChipElement = styled.div<{ backgroundColor: string }>`
    background-color: ${props => props.backgroundColor};
    border-radius: 14px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    width: 122px;
    height: 30px;
    line-height: 30px;
`

export const CleaningStatusChip = (props: {
    status: CleaningStatus
    isDisabled: boolean
}) => {
    const { label, color } = getLabelAndColor(props.status)
    return (
        <ChipElement backgroundColor={props.isDisabled ? "#AAAAAA" : color}>
            {props.isDisabled ? "清掃不要" : label}
        </ChipElement>
    )
}
