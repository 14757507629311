import { TableCellProps } from "../type"
import { TourFlag } from "./type"
import { Typography } from "matsuri-ui"

export const TourCell = ({
    cell: { value }
}: TableCellProps<{ tourFlag: TourFlag }, "tourFlag">) => {
    return (
        <Typography>
            {value.kind === "photoTour" ? value.title : "清掃"}
        </Typography>
    )
}
