import { assignStatusToText } from "@/domain/approvalRequest"
import { css } from "@emotion/react"
import { theme } from "@/Theme"

export const RejectedLabel = () => {
    return (
        <div
            css={css`
                width: max-content;
                padding: 4px 8px;
                text-align: center;
                background-color: ${theme.palette.gray[100]};
                border-radius: 4px;
            `}
        >
            {assignStatusToText("Rejected")}
        </div>
    )
}
