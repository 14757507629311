import { AssignStatus, assignStatusToText } from "@/domain/approvalRequest"
import { AssignableCleaning, useAssignPanelContext } from "./AssignPanel"
import { CleaningNewStatus } from "@/domain/cleaningNewStatus"
import { CleaningNewStatusChip } from "@/components/CleaningNewStatus"
import { CleaningStatusChip } from "@/components/CleaningStatus/Index"
import { CleaningStatusWithDisabled } from "@/domain/cleaningStatus"
import { Link } from "react-router-dom"
import { TableCellProps } from "@/parts/Table/columns/type"
import { TableUIRenderCellProps, TextButton, Typography } from "matsuri-ui"
import { TourFlag } from "@/parts/Table/columns/tourFlag"
import { css } from "@emotion/react"
import SettingsIcon from "@mui/icons-material/Settings"
import dayjs from "dayjs"

export const CleaningDateCell = ({
    cell,
    row
}: TableUIRenderCellProps<
    Pick<AssignableCleaning & { tourFlag: TourFlag }, "id" | "tourFlag">,
    dayjs.Dayjs
>) => {
    return (
        <Link
            to={
                row.tourFlag.kind === "photoTour"
                    ? `/operations/${row.id}`
                    : `/cleanings/${row.id}`
            }
        >
            {cell.value.format("YYYY-MM-DD")}
        </Link>
    )
}

export const CleaningStatusCell = ({
    cell: { value: cleaningStatus }
}: TableUIRenderCellProps<unknown, CleaningNewStatus | undefined>) => {
    return <CleaningNewStatusChip status={cleaningStatus} />
}

export const CleaningOldStatusCell = ({
    cell: {
        value: { cleaningStatus, isDisabled }
    }
}: TableUIRenderCellProps<unknown, CleaningStatusWithDisabled>) => {
    return (
        <CleaningStatusChip status={cleaningStatus} isDisabled={isDisabled} />
    )
}

export const CleanerNamesCell = ({
    cell,
    row
}: TableUIRenderCellProps<
    AssignableCleaning,
    AssignableCleaning["cleanerNames"]
>) => {
    const { openPanel } = useAssignPanelContext()

    return (
        <div
            css={css`
                display: grid;
                grid-template-columns: auto 1fr;
                gap: 8px;
                align-items: center;
            `}
        >
            <TextButton
                icon={<SettingsIcon />}
                onClick={() => openPanel(row)}
            />
            <div
                css={css`
                    display: grid;
                    gap: 8px;
                    justify-content: space-between;
                `}
            >
                {cell.value.map((value, index) => (
                    <span key={index}>{value}</span>
                ))}
            </div>
        </div>
    )
}

export const AddressCell = ({
    cell: { value }
}: TableCellProps<{ address: string }, "address">) => {
    return (
        <Typography
            css={css`
                text-align: left;
            `}
        >
            {value}
        </Typography>
    )
}

export const AssignStatusCell = ({
    cell
}: TableCellProps<{ assignStatus: AssignStatus[] }, "assignStatus">) => {
    return (
        <div
            css={css`
                display: grid;
                gap: 8px;
                justify-content: space-between;
                text-align: right;
            `}
        >
            {cell.value.length > 0
                ? cell.value.map((value, index) => (
                      <span key={index}>{assignStatusToText(value)}</span>
                  ))
                : "未割り当て"}
        </div>
    )
}
