import { blobToString } from "./blob"
import Compressor from "compressorjs"

const imageCompress = (file: Blob): Promise<Blob> =>
    new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.7,
            maxWidth: 1500,
            maxHeight: 1500,
            success(file: Blob): void {
                resolve(file)
            },
            error(error: Error): void {
                reject(error)
            }
        })
    })

export const imageToCompressedString = async (file: Blob): Promise<string> => {
    const compressedImage = await imageCompress(file)
    const stringifyImage = await blobToString(compressedImage)
    return stringifyImage
}
