import { CommonArea } from "./commonArea"
import { Listing } from "./listing"

export const PLACEMENT_TYPES = {
    listing: "listing",
    commonArea: "commonArea"
} as const

export type PlacementType =
    (typeof PLACEMENT_TYPES)[keyof typeof PLACEMENT_TYPES]

export interface Placement {
    id: string
    placementType: PlacementType
    listingId?: string
    commonAreaId?: string
}

export interface PlacementDetail {
    placement: Placement
    listing?: Listing
    commonArea?: CommonArea
}

type PlacementId = string
export type PlacementDetailMap = Record<PlacementId, PlacementDetail>

export const composePlacementDetailMap = (
    list: PlacementDetail[]
): PlacementDetailMap => {
    return list.reduce((acc, cur) => {
        acc[cur.placement.id] = cur
        return acc
    }, {} as PlacementDetailMap)
}

export const getPlacementName = (
    placement: PlacementDetail
): string | undefined => {
    switch (placement.placement.placementType) {
        case PLACEMENT_TYPES.listing:
            return placement.listing?.name
        case PLACEMENT_TYPES.commonArea:
            return placement.commonArea?.name
        default:
            return undefined
    }
}

export const getPlacementNameByPlacementId = (
    placementDetails: PlacementDetail[],
    placementId: string
) => {
    const placement = placementDetails.find(
        placementDetail => placementDetail.placement.id === placementId
    )
    if (!placement) return undefined

    return getPlacementName(placement)
}
