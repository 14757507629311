import { CleaningDateCell } from "@/pages/CleaningsForDay/parts/tableCells"
import {
    PlaceName,
    PlacementNameCell,
    sortPlaceName
} from "@/parts/Table/columns/placeName"
import { PlacementType } from "@/domain/placement"
import { TableUI, TableUIColumn } from "matsuri-ui"
import {
    TourCell,
    TourFlag,
    sortTourFlag
} from "@/parts/Table/columns/tourFlag"
import dayjs from "dayjs"

export interface RowData {
    id: string
    cleaningDate: dayjs.Dayjs
    tourFlag: TourFlag
    placeName: PlaceName
    placementType: PlacementType
}

const columns: TableUIColumn<RowData>[] = [
    {
        key: "cleaningDate",
        title: "清掃日",
        renderCell: CleaningDateCell
    },
    {
        key: "tourFlag",
        title: "ツアー",
        renderCell: TourCell,
        sort: sortTourFlag
    },
    {
        key: "placeName",
        title: "清掃場所名",
        renderCell: PlacementNameCell,
        sort: sortPlaceName
    }
]

export const OperationsTable = ({ tableData }: { tableData: RowData[] }) => {
    return <TableUI stickyHeader columns={columns} data={tableData} />
}
