import {
    Button,
    Column,
    Layout,
    Row,
    TextField,
    Typography,
    useAlert
} from "matsuri-ui"
import { CleaningWithCleaners } from "@/domain/cleaning"
import { Link, useNavigate } from "react-router-dom"
import { requestFindCleaningsByOtaId } from "@/hooks/useCleanings"
import { useAuth } from "m2m-components/storage/useM2mAuth"
import { useCallback, useState } from "react"

const getCleaningPath = (cleaning: CleaningWithCleaners) => {
    return `/cleanings/${cleaning.id}`
}

export const OTASearch = ({ onFinished }: { onFinished: () => void }) => {
    const { token } = useAuth()
    const { throwAlert } = useAlert()

    const navigate = useNavigate()

    const [candidates, setCandidates] = useState<CleaningWithCleaners[]>([])

    const [searchErrorMessage, setSearchErrorMessage] = useState<string>()

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            const formData = new FormData(event.currentTarget)
            const input = Object.fromEntries(formData)

            const { data, error } = await requestFindCleaningsByOtaId(token, {
                otaId: input.otaId as string
            })

            throwAlert(error, {
                errorMessage: "予約の検索に失敗しました",
                ignoreSuccess: true
            })

            if (error) return

            const cleanings = data ?? []
            if (cleanings.length === 0) {
                setSearchErrorMessage("清掃が見つかりませんでした")

                return
            }

            setSearchErrorMessage(undefined)

            if (cleanings.length === 1) {
                navigate(getCleaningPath(cleanings[0]))
            } else {
                setCandidates(cleanings)
                return
            }

            onFinished()
        },
        [throwAlert, onFinished, navigate, token]
    )

    return (
        <Column gap={2}>
            {candidates.length > 0 && (
                <Layout.Item>
                    <Typography>複数の清掃がヒットしました</Typography>
                    {candidates.map((candidate, index) => (
                        <Typography key={index}>
                            <Link to={getCleaningPath(candidate)}>
                                {candidate.id}
                            </Link>
                        </Typography>
                    ))}
                </Layout.Item>
            )}
            <Layout.Item>
                <form onSubmit={handleSubmit}>
                    <Column>
                        <Row gap={2} alignItems="flex-end">
                            <Layout.Item>
                                <TextField name="otaId" label="OTAのIDで検索" />
                            </Layout.Item>

                            <Layout.Item>
                                <Button variant="filled" color="primary">
                                    検索する
                                </Button>
                            </Layout.Item>
                        </Row>
                        {searchErrorMessage && (
                            <Layout.Item>
                                <Typography color="error">
                                    {searchErrorMessage}
                                </Typography>
                            </Layout.Item>
                        )}
                    </Column>
                </form>
            </Layout.Item>
        </Column>
    )
}
