import { CityGroup } from "@/domain/cityGroup"
import { bearerFetcher } from "matsuri-hooks"
import { m2mCleaning } from "../endpoints/m2m-cleaning"
import { useAuth } from "m2m-components/storage/useM2mAuth"
import { useAuthBearerFetch } from "matsuri-hooks"
import { useCallback } from "react"

interface CreateCityGroupsInput {
    name: string
    cityIds: string[]
}

export const useCreateCityGroups = () => {
    const { token } = useAuth()

    return {
        requestCreateCityGroups: (input: CreateCityGroupsInput) =>
            bearerFetcher<void>(
                token,
                m2mCleaning.managerV4.createCityGroups(),
                {
                    method: "POST",
                    body: JSON.stringify(input)
                }
            )
    }
}

interface CityGroupListOutput {
    cityGroups: CityGroup[]
}

export const useCityGroups = () => {
    const { token } = useAuth()
    const result = useAuthBearerFetch<CityGroupListOutput>(
        token,
        m2mCleaning.managerV4.readCityGroups(),
        {}
    )
    return { ...result, data: result.data?.cityGroups }
}

interface RequestReorderCityGroupsInput {
    ids: string[]
}

export const useRequestReoderCityGroups = () => {
    const { token } = useAuth()

    return {
        requestReorderCityGroups: useCallback(
            (input: RequestReorderCityGroupsInput) => {
                const method = m2mCleaning.managerV4.reorderCityGroups.method
                return bearerFetcher<void>(
                    token,
                    m2mCleaning.managerV4.reorderCityGroups(),
                    {
                        method,
                        body: JSON.stringify(input)
                    }
                )
            },
            [token]
        )
    }
}

interface UpdateCityGroupInput {
    name: string
    cityIds: string[]
}

export const useUpdateCityGroup = () => {
    const { token } = useAuth()
    return {
        updateCityGroup: useCallback(
            (id: string, input: UpdateCityGroupInput) => {
                return bearerFetcher<void>(
                    token,
                    m2mCleaning.managerV4.updateCityGroups({ cityGroupId: id }),
                    {
                        method: "PUT",
                        body: JSON.stringify(input)
                    }
                )
            },
            [token]
        )
    }
}

interface DeleteCityGroupInput {
    cityGroupId: string
}

export const useDeleteCityGroup = () => {
    const { token } = useAuth()
    return {
        deleteCityGroup: useCallback(
            ({ cityGroupId }: DeleteCityGroupInput) => {
                return bearerFetcher<void>(
                    token,
                    m2mCleaning.managerV4.deleteCityGroup({ cityGroupId }),
                    {
                        method: "DELETE"
                    }
                )
            },
            [token]
        )
    }
}
