import { copyToClipboard } from "./copyToClipboard"
import { useAlert } from "matsuri-ui"
import { useEffect } from "react"

const genSuccessMessage = (label?: string) =>
    label ? `${label}をコピーしました!` : "コピーしました!"

const genFailureMessage = (label?: string) =>
    label ? `${label}コピーに失敗しました` : "コピーに失敗しました"

export const useCopyToClipboard = ({
    text,
    label,
    key
}: {
    text: string
    label?: string
    key: string
}) => {
    const { addAlert } = useAlert()
    useEffect(() => {
        const handler = async (event: KeyboardEvent) => {
            if (text && (event.ctrlKey || event.metaKey) && event.key === key) {
                await copyToClipboard(text, {
                    onSuccess: () => {
                        addAlert(genSuccessMessage(label), {
                            severity: "success",
                            duration: 3500
                        })
                    },
                    onFailure: () => {
                        addAlert(genFailureMessage(label), {
                            severity: "error"
                        })
                    }
                })
            }
        }
        window.addEventListener("keydown", handler)
        return () => {
            window.removeEventListener("keydown", handler)
        }
    }, [addAlert, label, text, key])
}
