import { AreaEditView } from "@/parts/AreaEditView"
import { Button, Typography, useAlert, useConfirm } from "matsuri-ui"
import { css } from "@emotion/react"
import { useCallback, useMemo } from "react"
import {
    useCityGroups,
    useDeleteCityGroup,
    useUpdateCityGroup
} from "@/hooks/useCityGroup"
import { useNavigate, useParams } from "react-router-dom"

const AreaEditPage = () => {
    const { cityGroupId } = useParams<{ cityGroupId: string }>()
    if (!cityGroupId) {
        throw new Error("cityGroupId is required")
    }
    const { data: cityGroups } = useCityGroups()
    const cityGroup = cityGroups?.find(
        cityGroup => cityGroup.id === cityGroupId
    )

    const { updateCityGroup } = useUpdateCityGroup()

    const saveFunc = useMemo(() => {
        return (cityGroup: { cityIds: string[]; name: string }) =>
            updateCityGroup(cityGroupId, {
                ...cityGroup,
                cityIds: cityGroup.cityIds
            })
    }, [cityGroupId, updateCityGroup])

    const { deleteCityGroup } = useDeleteCityGroup()
    const confirmFunc = useConfirm({
        title: "エリアを削除しますか？",
        message: "この操作は取り消せません"
    })
    const navigate = useNavigate()
    const { throwAlert } = useAlert()

    const handleDelete = useCallback(async () => {
        if (await confirmFunc()) {
            const { error } = await deleteCityGroup({ cityGroupId })
            throwAlert(error, {
                successMessage: "エリアを削除しました",
                errorMessage: "エリアの削除に失敗しました"
            })
            navigate("/area/list")
        }
    }, [cityGroupId, confirmFunc, deleteCityGroup, navigate, throwAlert])

    return (
        <div
            css={css`
                max-width: 800px;
                margin: auto;
            `}
        >
            <Typography variant="h3">エリア編集</Typography>
            <AreaEditView cityGroup={cityGroup} saveFunc={saveFunc} />
            <div
                css={css`
                    margin-top: 32px;
                `}
            >
                <Button variant="filled" color="error" onClick={handleDelete}>
                    エリアを削除
                </Button>
            </div>
        </div>
    )
}

export default AreaEditPage
