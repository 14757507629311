import { CommonArea } from "../domain/commonArea"
import { Listing } from "../domain/listing"
import { Placement, PlacementDetail } from "../domain/placement"
import { m2mCleaning_managerV4 } from "../endpoints/m2m-cleaning.manager-v4"
import { useAuthBearerFetch } from "matsuri-hooks"

export const usePlacementByIds = (token: string, ids: string[] | undefined) => {
    const endpoint = m2mCleaning_managerV4.searchPlacements

    return useAuthBearerFetch<{
        placements: Placement[]
        commonAreas: CommonArea[]
        listings: Listing[]
    }>(token, ids ? endpoint() : null, {
        method: "POST",
        body: JSON.stringify({
            ids
        })
    })
}

export const usePlacementById = (token: string, id: string | undefined) => {
    const endpoint = m2mCleaning_managerV4.findPlacementById

    return useAuthBearerFetch<PlacementDetail>(
        token,
        id ? endpoint({ placementId: id }) : null,
        {}
    )
}

export const usePlacementDetails = (token: string, placementIds: string[]) => {
    const endpoint = m2mCleaning_managerV4.findPlacements

    return useAuthBearerFetch<{ placements: PlacementDetail[] }>(
        token,
        endpoint(),
        {
            method: "POST",
            body: JSON.stringify({
                placementIds
            })
        }
    )
}
