import { City } from "@/domain/city"
import { listCities } from "@/endpoints/m2m-core.v1"
import { useFetch } from "matsuri-hooks"

interface UseCityInput {
    prefectureId: string
}

export const useCities = (input: UseCityInput) => {
    return useFetch<City[]>(listCities({ id: input.prefectureId }), {
        method: "GET"
    })
}
