import { CommonAreaSelectField } from "./CommonAreaSelectField"
import { ListingSelectField } from "./ListingSelectField"
import { PlacementType } from "../domain/placement"
import { RadioButton, RadioButtonGroup } from "matsuri-ui"
import { css } from "@emotion/react"
import { useId, useState } from "react"

export const PlacementSelectField = ({
    onChange
}: {
    onChange: (args: {
        placementType: PlacementType
        listingId?: string
        commonAreaId?: string
    }) => void
}) => {
    const [mode, setMode] = useState<PlacementType>("listing")
    const id = useId()

    return (
        <div
            css={css`
                display: grid;
                gap: 16px;
            `}
        >
            <div
                css={css`
                    display: flex;
                    gap: 8px;
                `}
            >
                <RadioButtonGroup
                    name={id}
                    onChange={({ currentTarget }) =>
                        setMode(currentTarget.value as PlacementType)
                    }
                    value={mode}
                >
                    <RadioButton color="primary" value="listing">
                        物件
                    </RadioButton>
                    <RadioButton color="primary" value="commonArea">
                        共用部
                    </RadioButton>
                </RadioButtonGroup>
            </div>

            {mode === "listing" ? (
                <ListingSelectField
                    onChange={(value: string | undefined) => {
                        if (value) {
                            onChange({
                                placementType: "listing",
                                listingId: value
                            })
                        }
                    }}
                    placeholder="物件名で検索"
                />
            ) : (
                <CommonAreaSelectField
                    onChange={(value: string | undefined) => {
                        if (value) {
                            onChange({
                                placementType: "commonArea",
                                commonAreaId: value
                            })
                        }
                    }}
                    placeholder="共用部名で検索"
                />
            )}
        </div>
    )
}
