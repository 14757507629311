import { css } from "@emotion/react"
import React from "react"

interface MuiIconWrapperProps {
    children: React.ReactNode
}

export const MuiIconWrapper = ({ children }: MuiIconWrapperProps) => {
    return (
        <div
            css={css`
                display: flex;
                align-items: center;

                svg {
                    font-size: 28px;
                }
            `}
        >
            {children}
        </div>
    )
}
