import { SorterResult } from "../type"
import { TourFlag } from "./type"

// 降順ではツアー作業が上に来るようにソート
export const sortTourFlag = (a: TourFlag, b: TourFlag): SorterResult => {
    if (a.kind === "photoTour") {
        if (b.kind === "photoTour") {
            return a.title.localeCompare(b.title)
        }

        return 1
    }

    if (b.kind === "photoTour") {
        // 降順ではツアー作業が上に来るようにソート
        return -1
    }

    // 通常清掃同士では、名称はないので比較できない
    return 0
}
