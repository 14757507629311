export const ASSIGN_STATUS = {
    Pending: "Pending",
    Approved: "Approved",
    Rejected: "Rejected",
    NotRequired: "NotRequired"
} as const

export type AssignStatus = (typeof ASSIGN_STATUS)[keyof typeof ASSIGN_STATUS]

const ASSIGN_STATUS_TEXT = {
    [ASSIGN_STATUS.Pending]: "承認待ち",
    [ASSIGN_STATUS.Approved]: "承認済み",
    [ASSIGN_STATUS.Rejected]: "不承認",
    [ASSIGN_STATUS.NotRequired]: "承認不要"
} as const

export const assignStatusToText = (status: AssignStatus) => {
    return ASSIGN_STATUS_TEXT[status]
}

export const sortAssignStatusArray = (a: AssignStatus[], b: AssignStatus[]) => {
    // 未割り当て > 全て不承認 >  不承認あり > 一件でも承認待ち >> 一件でも承認済み > すべて承認済み

    // 一件でも承認済みがあれば、下の方に出す
    const aHasApproved = a.includes(ASSIGN_STATUS.Approved)
    const bHasApproved = b.includes(ASSIGN_STATUS.Approved)

    if (aHasApproved && bHasApproved) return 0
    if (aHasApproved) return 1
    if (bHasApproved) return -1

    // 承認不要が1つでもあれば下の方に出す
    const aHasNotRequired = a.includes(ASSIGN_STATUS.NotRequired)
    const bHasNotRequired = b.includes(ASSIGN_STATUS.NotRequired)

    if (aHasNotRequired && bHasNotRequired) return 0
    if (aHasNotRequired) return 1
    if (bHasNotRequired) return -1

    // 未割り当ての場合は上の方に出す
    if (a.length === 0 && b.length === 0) return 0
    if (a.length === 0) return -1
    if (b.length === 0) return 1

    // すべてが不承認の場合は上に出す
    const aAllRejected = a.every(status => status === ASSIGN_STATUS.Rejected)
    const bAllRejected = b.every(status => status === ASSIGN_STATUS.Rejected)

    if (aAllRejected && bAllRejected) return 0
    if (aAllRejected) return -1
    if (bAllRejected) return 1

    // 不承認が1つでもあれば上の方に出す
    const aHasRejected = a.includes(ASSIGN_STATUS.Rejected)
    const bHasRejected = b.includes(ASSIGN_STATUS.Rejected)

    if (aHasRejected && bHasRejected) return 0
    if (aHasRejected) return -1
    if (bHasRejected) return 1

    // 承認待ちが1つでもあれば上の方に出す
    const aHasPending = a.includes(ASSIGN_STATUS.Pending)
    const bHasPending = b.includes(ASSIGN_STATUS.Pending)

    if (aHasPending && bHasPending) return 0
    if (aHasPending) return -1
    if (bHasPending) return 1

    return 0
}
