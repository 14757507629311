import { CleaningList, CleaningListItem } from "./CleaningList"
import { Typography } from "matsuri-ui"
import { css } from "@emotion/react"
import { getPlacementNameByPlacementId } from "@/domain/placement"
import { useAuth } from "m2m-components/storage/useM2mAuth"
import { useCleaners } from "@/hooks/useCleaners"
import { useFindCleaningIdsByDateAndCleaner } from "@/hooks/useFindCleaningIdByDateAndCleaner"
import { useMemo } from "react"
import { useOperations } from "@/hooks/useOperations"
import { useParams } from "react-router-dom"
import { usePhotoTours } from "@/hooks/usePhotoTour"
import { usePlacementDetails } from "@/hooks/usePlacement"
import { useQuery } from "@/hooks/useQuery"

const CleaningsForEachUserPage = () => {
    const { token } = useAuth()
    const query = useQuery()
    const params = useParams()
    const cleanerId = params.cleanerId ?? ""

    const { data: cleaners } = useCleaners(token)
    const cleaner = cleaners?.find(e => e.id === cleanerId)
    const date = query.get("date") ?? ""

    const { data: photoTours } = usePhotoTours(token, {
        // 無効化されたツアーも含める
        excludeDisabled: false
    })

    const { data: cleaningIdsByCleanerId } = useFindCleaningIdsByDateAndCleaner(
        {
            cleanerIds: [cleanerId],
            date
        }
    )

    const cleaningIds = cleaningIdsByCleanerId?.[cleanerId] ?? []
    const { data: operations } = useOperations({ operationIds: cleaningIds })
    const { data: placementsResp } = usePlacementDetails(
        token,
        operations
            ?.map(op => op.placementId)
            .filter((id): id is string => Boolean(id)) ?? []
    )

    const cleaningList: CleaningListItem[] = useMemo(() => {
        const placementDetails = placementsResp?.placements ?? []

        return (
            operations?.map(op => {
                const placementName = op.placementId
                    ? getPlacementNameByPlacementId(
                          placementDetails,
                          op.placementId
                      )
                    : ""

                return {
                    cleaningId: op.id,
                    placementName: placementName ?? "",
                    photoTour: op.photoTourId
                        ? photoTours?.find(({ id }) => id === op.photoTourId)
                        : undefined
                }
            }) ?? []
        )
    }, [operations, photoTours, placementsResp?.placements])

    return (
        <div
            css={css`
                display: grid;
                gap: 32px;
                padding: 0 20px;
            `}
        >
            <Typography variant="h2">
                {cleaner?.name} ({cleaner?.email})
            </Typography>

            <CleaningList cleaningList={cleaningList} />
        </div>
    )
}

export default CleaningsForEachUserPage
