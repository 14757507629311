import {
    ApprovalRequest,
    useApprovalRequestsByOperationIds
} from "@/hooks/useApprovalRequestsByOperationIds"
import {
    Button,
    Checkbox,
    FieldGroup,
    Loading,
    TabUI,
    TextField,
    Typography,
    useAlert
} from "matsuri-ui"
import { CleanerTags } from "@/components/CleanerTags"
import { PLACEMENT_TYPES } from "@/domain/placement"
import { RejectedLabel } from "@/pages/CleaningDetail/parts/RejectedLabel"
import { css } from "@emotion/react"
import {
    requestAssignCleaner,
    requestGetAssignedCleanersByCleaningId,
    requestUnassignCleaner,
    useCleaningCleaners
} from "../../../hooks/useCleanings"
import { sleep } from "../../../helpers/sleep"
import { sort } from "fast-sort"
import { useAreaViewTab } from "@/hooks/useAreaViewTab"
import { useAuth } from "m2m-components/storage/useM2mAuth"
import { useGetCleanersWithTags } from "@/hooks/useCleanerTag"
import React, { useMemo, useState } from "react"

export type AssignableCleaning = {
    id: string
    cleanerNames: string[]
} & FieldsByPlacementType

export type FieldsByPlacementType =
    | {
          placementType: (typeof PLACEMENT_TYPES)["commonArea"]
          commonAreaName: string
      }
    | {
          placementType: (typeof PLACEMENT_TYPES)["listing"]
          listingName: string
      }

export const AssignPanel = ({
    cleanings
}: {
    cleanings: AssignableCleaning[]
}) => {
    const { token } = useAuth()

    const { selectedCityGroupId, onCityGroupIdSelected, areaTabs } =
        useAreaViewTab()
    const { data: cleaners } = useGetCleanersWithTags(
        token,
        selectedCityGroupId
    )

    const { throwAlert } = useAlert()

    const [filterCleanerQuery, setFilterCleanerQuery] = useState("")
    const [processingUnassignAll, setProcessingUnassignAll] = useState(false)

    // 選択された清掃が単一の場合のみ、割り当ての解除ができるようにする
    // (テーブルから選択せずいきなりパネルをオープンするようなケースでは、割り当て解除などができる方が自然であるため)
    const isSingle = cleanings.length === 1
    const cleaningId = isSingle ? cleanings[0].id : undefined
    const { data: assigned, refetch } = useCleaningCleaners(token, cleaningId)
    const { onUpdate } = useAssignPanelContext()
    const { data: approvalRequest } = useApprovalRequestsByOperationIds(
        token,
        cleaningId ? [cleaningId] : []
    )

    const approvalRequestByCleanerId: Record<string, ApprovalRequest> =
        useMemo(() => {
            if (!approvalRequest || !cleaningId) return {}

            const approvalRequests = approvalRequest[cleaningId]

            return approvalRequests
        }, [approvalRequest, cleaningId])

    return (
        <FieldGroup
            key={JSON.stringify(cleanings.map(c => c.id))}
            loading={isSingle && !assigned}
        >
            <div
                css={css`
                    display: grid;
                    gap: 32px;
                `}
            >
                <div
                    css={css`
                        display: grid;
                        gap: 8px;
                    `}
                >
                    <Typography>{cleanings.length}件の清掃割り当て</Typography>
                    <Typography
                        variant="h3"
                        css={css`
                            display: grid;
                            gap: 16px;
                        `}
                    >
                        {cleanings.map((c, index) => (
                            <span key={index}>
                                {c.placementType === "commonArea"
                                    ? c.commonAreaName
                                    : c.listingName}
                            </span>
                        ))}
                    </Typography>
                </div>

                <section>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={async () => {
                            const ok = window.confirm(
                                `${cleanings.length}件の清掃の清掃員を解除します。この操作は取り消せません。よろしいですか？`
                            )
                            if (!ok) {
                                return
                            }

                            setProcessingUnassignAll(true)

                            const errors: Error[] = []
                            await Promise.all(
                                cleanings.map(async cleaning => {
                                    const { data: cleaners, error } =
                                        await requestGetAssignedCleanersByCleaningId(
                                            token,
                                            cleaning.id
                                        )
                                    if (error) {
                                        errors.push(error)
                                        return
                                    }
                                    if (!cleaners) {
                                        return
                                    }

                                    await Promise.all(
                                        cleaners.map(async cleaner => {
                                            const { error } =
                                                await requestUnassignCleaner(
                                                    token,
                                                    cleaning.id,
                                                    cleaner.id
                                                )
                                            if (error) {
                                                errors.push(error)
                                            }
                                        })
                                    )
                                })
                            )

                            // 上記のAPIの処理が早すぎてボタンの変更が見えないことがあるので、ちょっと待つ
                            await sleep(500)

                            setProcessingUnassignAll(false)

                            throwAlert(
                                errors.length > 0
                                    ? new Error("エラーがあります")
                                    : undefined,
                                {
                                    errorMessage: {
                                        happend: "割り当ての解除に失敗しました",
                                        reason: errors.join(",")
                                    },
                                    successMessage: {
                                        happend: "割り当てを解除しました"
                                    }
                                }
                            )
                            onUpdate()
                        }}
                        disabled={processingUnassignAll}
                    >
                        {processingUnassignAll ? (
                            <Loading />
                        ) : (
                            <>割り当てを全て解除</>
                        )}
                    </Button>
                </section>
                <section
                    css={css`
                        display: grid;
                        gap: 16px;
                    `}
                >
                    <Typography variant="h4">清掃員を割り当てる</Typography>

                    <div
                        css={css`
                            display: grid;
                            grid-template-columns: 1fr auto;
                            gap: 8px;
                            align-items: flex-end;
                        `}
                    >
                        <TextField
                            label="清掃員をフィルタ"
                            placeholder="名前、メールアドレスを入力 (部分一致)"
                            onChange={value => setFilterCleanerQuery(value)}
                            value={filterCleanerQuery}
                        />
                        <Button
                            onClick={() => {
                                setFilterCleanerQuery("")
                            }}
                        >
                            リセット
                        </Button>
                    </div>
                    <div>
                        <Typography
                            css={css`
                                margin-bottom: 8px;
                            `}
                        >
                            清掃担当者:{" "}
                        </Typography>

                        {assigned
                            ?.filter(a => a.name && a.name.trim() !== "")
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(cleaner => (
                                <Typography key={cleaner.id}>
                                    {cleaner.name}
                                </Typography>
                            ))}
                    </div>

                    <TabUI
                        variant="buttons"
                        tabs={areaTabs}
                        onChange={index =>
                            onCityGroupIdSelected(areaTabs[index].data?.id)
                        }
                        defaultIndex={0}
                        tabPosition="start"
                    >
                        <div
                            // このセクションでは、清掃員の更新時に楽観的な更新を行うためにdefaultCheckedを使っている
                            // そして、これをcleaningIdが変わるたびに切り替える必要があるのでkeyを用いている
                            // このようなケースではswrのmutateを使って楽観更新した方がいいかもしれない
                            key={cleaningId}
                            css={css`
                                display: grid;
                            `}
                        >
                            {sort(cleaners ?? [])
                                .asc(entry => entry.name)
                                .filter(cleaner =>
                                    filterCleanerQuery.trim() !== ""
                                        ? cleaner.name
                                              .toLowerCase()
                                              .includes(filterCleanerQuery) ||
                                          cleaner.email
                                              .toLowerCase()
                                              .includes(filterCleanerQuery)
                                        : true
                                )
                                ?.map(cleaner => {
                                    const approvalRequestStatus =
                                        approvalRequestByCleanerId[cleaner.id]
                                            ?.status

                                    return (
                                        <div
                                            // 選択されるcleaningが切り替わった時にdefaultCheckedの値を切り替えられるようにするためにassignedをkeyに入れている
                                            // FIXME: matsuri-ui v13.3以降ではFieldGroupのloadingを自動で参照するようになったので、不要になるため削除して良いです
                                            key={`${cleaner.id}-${JSON.stringify(
                                                assigned
                                            )}`}
                                        >
                                            <Checkbox
                                                defaultChecked={
                                                    assigned?.find(
                                                        entry =>
                                                            entry.id ===
                                                            cleaner.id
                                                    ) !== undefined
                                                }
                                                onChange={async event => {
                                                    const shouldAssign =
                                                        event.currentTarget
                                                            .checked
                                                    const request = shouldAssign
                                                        ? requestAssignCleaner
                                                        : requestUnassignCleaner

                                                    const errors: Error[] = []
                                                    await Promise.all(
                                                        cleanings.map(
                                                            async cleaning => {
                                                                const {
                                                                    error
                                                                } =
                                                                    await request(
                                                                        token,
                                                                        cleaning.id,
                                                                        cleaner.id
                                                                    )
                                                                if (error) {
                                                                    errors.push(
                                                                        error
                                                                    )
                                                                }
                                                            }
                                                        )
                                                    )
                                                    throwAlert(
                                                        errors.length > 0
                                                            ? new Error(
                                                                  "エラーがあります"
                                                              )
                                                            : undefined,
                                                        {
                                                            errorMessage:
                                                                "割り当てに失敗しました",
                                                            ignoreSuccess: true
                                                        }
                                                    )
                                                    void refetch()
                                                    onUpdate()
                                                }}
                                            >
                                                <div
                                                    css={css`
                                                        display: flex;
                                                        flex-direction: row;
                                                        gap: 4px;
                                                        align-items: center;
                                                        max-width: 400px;
                                                    `}
                                                >
                                                    <div>
                                                        {cleaner.name} (
                                                        {cleaner.email})
                                                    </div>
                                                    <CleanerTags
                                                        tags={cleaner.tags}
                                                    />
                                                    <div>
                                                        {approvalRequestStatus ===
                                                            "Rejected" && (
                                                            <RejectedLabel />
                                                        )}
                                                    </div>
                                                </div>
                                            </Checkbox>
                                        </div>
                                    )
                                })}
                        </div>
                    </TabUI>
                </section>
            </div>
        </FieldGroup>
    )
}

export interface AssignPanelContextState {
    openPanel: (cleaning: AssignableCleaning) => void
    closePanel: () => void
    selectCleanings: (cleanings: AssignableCleaning[]) => void
    onUpdate: () => void
}

export const AssignPanelContext = React.createContext<
    AssignPanelContextState | undefined
>(undefined)

export const useAssignPanelContext = () => {
    const ctx = React.useContext(AssignPanelContext)
    if (!ctx) {
        throw new Error(
            "useAssignPanelContext must be used within a AssignPanelContextProvider"
        )
    }

    return ctx
}
