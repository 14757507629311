import { Tag } from "@/domain/tag"
import { bearerFetcher } from "matsuri-hooks"
import { m2mCleaning } from "@/endpoints/m2m-cleaning"
import { useAuth } from "@/hooks/useAuth"
import { useAuthBearerFetch } from "matsuri-hooks"
import { useCallback } from "react"
import { useCleaners } from "./useCleaners"
import { useMemo } from "react"

type CleanerTagsOutput = Record<string, Tag[]>

export const useGetCleanersWithTags = (token: string, cityGroupId?: string) => {
    const { data: cleaners, isLoading: isLoadingCleaners } = useCleaners(
        token,
        { cityGroupId }
    )

    const cleanerIds = useMemo(() => cleaners?.map(c => c.id), [cleaners])
    const { data: cleanerTags, isLoading: isLoadingTags } =
        useAuthBearerFetch<CleanerTagsOutput>(
            token,
            cleanerIds
                ? m2mCleaning.managerV4.findCleanerTagsByCleanerIds()
                : null,
            {
                method: "POST",
                body: JSON.stringify({ cleanerIds: cleanerIds })
            }
        )

    const cleanersWithTags = useMemo(() => {
        if (!cleaners || !cleanerTags) {
            return undefined
        }

        return cleaners.map(cleaner => ({
            ...cleaner,
            tags: cleanerTags[cleaner.id] ?? []
        }))
    }, [cleaners, cleanerTags])

    return {
        isLoading: isLoadingCleaners || isLoadingTags,
        data: cleanersWithTags,
        rawData: {
            cleaners,
            cleanerTags
        }
    }
}

export const useSaveCleanerTags = () => {
    const { token } = useAuth()
    const requestFunc = useCallback(
        ({ cleanerId, tagId }: { cleanerId: string; tagId: string }) => {
            const endpoint = m2mCleaning.managerV4.saveCleanerTag
            return bearerFetcher<void>(token, endpoint(), {
                method: endpoint.method,
                body: JSON.stringify({ userId: cleanerId, tagId })
            })
        },
        [token]
    )
    return { requestSaveCleanerTag: requestFunc }
}

export const useDeleteAllCleanerTags = () => {
    const { token } = useAuth()
    const requestFunc = useCallback(
        ({ cleanerId }: { cleanerId: string }) => {
            const endpoint = m2mCleaning.managerV4.deleteAllCleanerTags
            return bearerFetcher<void>(token, endpoint(), {
                method: endpoint.method,
                body: JSON.stringify({ userId: cleanerId })
            })
        },
        [token]
    )
    return { requestDeleteAllCleanerTags: requestFunc }
}
