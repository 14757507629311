export const copyToClipboard = async (
    text: string,
    options?: {
        onSuccess?: () => void
        onFailure?: () => void
    }
) => {
    const succeeded = await navigator?.clipboard
        ?.writeText(text)
        .then(() => true)
        .catch(() => false)
    if (succeeded) {
        options?.onSuccess?.()
    } else {
        options?.onFailure?.()
    }
}
