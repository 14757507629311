/* eslint-disable */
/**
 * A function that returns the URL part common to the endpoints.
 */
export const root = () => {
  let __root = "";

  if (process.env.RUNTIME_ENV === "local") {
    __root = "https://api-core.dev.m2msystems.cloud";
  }

  if (process.env.RUNTIME_ENV === "localDev") {
    __root = "https://api-core.dev.m2msystems.cloud";
  }

  if (process.env.RUNTIME_ENV === "development") {
    __root = "https://api-core.dev.m2msystems.cloud";
  }

  if (process.env.RUNTIME_ENV === "production") {
    __root = "https://api-core.m2msystems.cloud";
  }

  return __root;
};
/**
 * ヘルスチェック
 *
 */
export const healthCheck = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`health_check`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

healthCheck.method = "GET" as const;
healthCheck.authSchema = { type: "", header: "" } as const;
/**
 * 都道府県の一覧を返す
 *
 */
export const listPrefectures = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`address/prefectures`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listPrefectures.method = "GET" as const;
listPrefectures.authSchema = { type: "", header: "" } as const;
/**
 * 指定された都道府県の中の市町村区の一覧を返す
 *
 */
export const listCities = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`address/prefectures/${id}/cities`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listCities.method = "GET" as const;
listCities.authSchema = { type: "", header: "" } as const;
/**
 * 自社の物件一覧を取得する
 *
 */
export const findMyListings = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findMyListings.method = "GET" as const;
findMyListings.authSchema = { type: "", header: "" } as const;
/**
 * 物件一覧を検索する
 *
 */
export const searchListings = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchListings.method = "POST" as const;
searchListings.authSchema = { type: "", header: "" } as const;
/**
 * 物件名で検索する
 *
 */
export const listingSearchByName = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/search_by_name`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingSearchByName.method = "POST" as const;
listingSearchByName.authSchema = { type: "", header: "" } as const;
/**
 * 物件の部屋タイプを変更する
 *
 */
export const transferListing = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/transfer`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

transferListing.method = "POST" as const;
transferListing.authSchema = { type: "", header: "" } as const;
/**
 * リスティング詳細の情報
 *
 */
export const listingDetail = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingDetail.method = "GET" as const;
listingDetail.authSchema = { type: "", header: "" } as const;
/**
 * リスティングを更新する
 *
 */
export const updateListing = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateListing.method = "PUT" as const;
updateListing.authSchema = { type: "", header: "" } as const;
/**
 * 予約も施設も専有など何も関連づけてない物件だけ削除する
 *
 */
export const deleteListing = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteListing.method = "DELETE" as const;
deleteListing.authSchema = { type: "", header: "" } as const;
/**
 * 物件個別に設定されたすべての自動価格設定関連情報を取得する
 *
 */
export const listingGetAutoPricingSetting = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${id}/auto_pricing_settings`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingGetAutoPricingSetting.method = "GET" as const;
listingGetAutoPricingSetting.authSchema = { type: "", header: "" } as const;
/**
 * 物件個別に設定されたすべての自動価格設定関連情報を更新する
 *
 */
export const listingUpdateAutoPricingSetting = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${id}/auto_pricing_settings`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingUpdateAutoPricingSetting.method = "PUT" as const;
listingUpdateAutoPricingSetting.authSchema = { type: "", header: "" } as const;
/**
 * 自動価格設定により設定される価格のプレビューを取得する
 *
 */
export const listingAutoPricingPricing = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${id}/auto_pricing_pricings`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingAutoPricingPricing.method = "GET" as const;
listingAutoPricingPricing.authSchema = { type: "", header: "" } as const;
/**
 * 自動価格設定により設定される価格のプレビューを、設定を保存せずに取得する
 *
 */
export const listingAutoPricingPricingWithoutSave = ({
  id,
}: {
  id: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${id}/auto_pricing_pricings`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingAutoPricingPricingWithoutSave.method = "POST" as const;
listingAutoPricingPricingWithoutSave.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * リスティングイベントを再通知する
 *
 */
export const listingRenotify = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${id}/re_notify`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingRenotify.method = "POST" as const;
listingRenotify.authSchema = { type: "", header: "" } as const;
/**
 * 予約を再通知する
 *
 */
export const listingRenotifyReservations = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${id}/re_notify_reservations`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingRenotifyReservations.method = "POST" as const;
listingRenotifyReservations.authSchema = { type: "", header: "" } as const;
/**
 * Matsuriのリスティング情報を設定する
 *
 */
export const listingSetMatsuriListingInfo = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${id}/matsuri_listing_info`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingSetMatsuriListingInfo.method = "POST" as const;
listingSetMatsuriListingInfo.authSchema = { type: "", header: "" } as const;
/**
 * 物件の清掃情報を取得する
 *
 */
export const listingGetCleaningInfo = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${id}/cleaning_info`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingGetCleaningInfo.method = "GET" as const;
listingGetCleaningInfo.authSchema = { type: "", header: "" } as const;
/**
 * 物件の清掃情報を更新する
 *
 */
export const listingUpdateCleaningInfo = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${id}/cleaning_info`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingUpdateCleaningInfo.method = "PUT" as const;
listingUpdateCleaningInfo.authSchema = { type: "", header: "" } as const;
/**
 * 収支管理用のリスティング情報を取得する
 *
 */
export const listingGetListingInfoForDepositManagement = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/deposit_management`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingGetListingInfoForDepositManagement.method = "POST" as const;
listingGetListingInfoForDepositManagement.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 清掃・インチェックに行くべき物件一覧を取得
 *
 */
export const listingGetInCheckListings = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/in_check_listings`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingGetInCheckListings.method = "POST" as const;
listingGetInCheckListings.authSchema = { type: "", header: "" } as const;
/**
 * 備品の画像一覧を取得する
 *
 */
export const findListingEquipmentImagesById = ({
  listingId,
  equipmentId,
}: {
  listingId: string;
  equipmentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${listingId}/equipments/${equipmentId}/images`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findListingEquipmentImagesById.method = "GET" as const;
findListingEquipmentImagesById.authSchema = { type: "", header: "" } as const;
/**
 * 備品の画像をアップロードする
 *
 */
export const uploadEquipmentImage = ({
  listingId,
  equipmentId,
}: {
  listingId: string;
  equipmentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${listingId}/equipments/${equipmentId}/images/upload`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

uploadEquipmentImage.method = "POST" as const;
uploadEquipmentImage.authSchema = { type: "", header: "" } as const;
/**
 * 備品の画像のコメントを更新する
 *
 */
export const updateEquipmentImage = ({
  listingId,
  equipmentId,
  imageId,
}: {
  listingId: string;
  equipmentId: string;
  imageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${listingId}/equipments/${equipmentId}/images/${imageId}/update`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateEquipmentImage.method = "PUT" as const;
updateEquipmentImage.authSchema = { type: "", header: "" } as const;
/**
 * 物件の画像をアップロードする
 *
 */
export const uploadListingImage = ({ listingId }: { listingId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${listingId}/images`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

uploadListingImage.method = "POST" as const;
uploadListingImage.authSchema = { type: "", header: "" } as const;
/**
 * 物件の画像一覧を取得する
 *
 */
export const findListingImagesById = ({ listingId }: { listingId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${listingId}/images`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findListingImagesById.method = "GET" as const;
findListingImagesById.authSchema = { type: "", header: "" } as const;
/**
 * 物件の清掃画像一覧を取得する
 *
 */
export const findListingCleaningImagesById = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${listingId}/cleaningImages/`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findListingCleaningImagesById.method = "GET" as const;
findListingCleaningImagesById.authSchema = { type: "", header: "" } as const;
/**
 * 物件の動画をアップロードする
 *
 */
export const uploadListingMovie = ({ listingId }: { listingId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${listingId}/movies`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

uploadListingMovie.method = "POST" as const;
uploadListingMovie.authSchema = { type: "", header: "" } as const;
/**
 * 物件の動画一覧を取得する
 *
 */
export const findListingMoviesById = ({ listingId }: { listingId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${listingId}/movies`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findListingMoviesById.method = "GET" as const;
findListingMoviesById.authSchema = { type: "", header: "" } as const;
/**
 * 物件の動画を更新する
 *
 */
export const updateListingMovie = ({ movieId }: { movieId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listing_movies/${movieId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateListingMovie.method = "PATCH" as const;
updateListingMovie.authSchema = { type: "", header: "" } as const;
/**
 * 物件の動画を削除する
 *
 */
export const deleteListingMovie = ({ movieId }: { movieId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listing_movies/${movieId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteListingMovie.method = "DELETE" as const;
deleteListingMovie.authSchema = { type: "", header: "" } as const;
/**
 * 物件の画像の表示順を取得する
 *
 */
export const findListingImageOrdersById = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${listingId}/images/orders`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findListingImageOrdersById.method = "GET" as const;
findListingImageOrdersById.authSchema = { type: "", header: "" } as const;
/**
 * 物件の画像の表示順を更新する
 *
 */
export const updateListingImageOrders = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${listingId}/images/orders`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateListingImageOrders.method = "PUT" as const;
updateListingImageOrders.authSchema = { type: "", header: "" } as const;
/**
 * 物件のメイン画像を削除する
 *
 */
export const detachListingMainImage = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${listingId}/images/mainImage`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

detachListingMainImage.method = "DELETE" as const;
detachListingMainImage.authSchema = { type: "", header: "" } as const;
/**
 * 物件の間取り画像を削除する
 *
 */
export const detachListingLayoutImage = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${listingId}/images/layoutImage`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

detachListingLayoutImage.method = "DELETE" as const;
detachListingLayoutImage.authSchema = { type: "", header: "" } as const;
/**
 * 物件の備品配置を検索する
 *
 */
export const searchListingCommodityPlacements = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${listingId}/placements/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchListingCommodityPlacements.method = "PUT" as const;
searchListingCommodityPlacements.authSchema = { type: "", header: "" } as const;
/**
 * listing_idをpkとするbigqueryのテーブルを全て取得する
 *
 */
export const findBigQueryAllTablesById = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${listingId}/bigquery`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findBigQueryAllTablesById.method = "GET" as const;
findBigQueryAllTablesById.authSchema = { type: "", header: "" } as const;
/**
 * 日時とlistingIdを指定して該当する物件のlistingCalendarEvent情報を取得する
 * @param {string} startDate 2020-10-01
 * @param {string} endDate 2020-11-30
 */
export const findlistingCalendarEventById = ({
  startDate,
  endDate,
  listingId,
}: {
  startDate?: string;
  endDate?: string;
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({ startDate, endDate })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listings/${listingId}/listingCalendarEvent`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findlistingCalendarEventById.method = "GET" as const;
findlistingCalendarEventById.authSchema = { type: "", header: "" } as const;
/**
 * listing_idをpkとするbigqueryのテーブルを全て取得する
 *
 */
export const findBigQueryAllTablesByIdAsSystemUser = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`system_user/listings/${listingId}/bigquery`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findBigQueryAllTablesByIdAsSystemUser.method = "GET" as const;
findBigQueryAllTablesByIdAsSystemUser.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * システムユーザーが予約を再通知する
 *
 */
export const listingRenotifyReservationsAsSystemUser = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`system_user/listings/${listingId}/re_notify_reservations`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingRenotifyReservationsAsSystemUser.method = "GET" as const;
listingRenotifyReservationsAsSystemUser.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * airbnb_listing_idを指定して該当する物件のlisting情報を取得する
 *
 */
export const findListingByAirbnbId = ({
  airListingId,
}: {
  airListingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`system_user/listings/${airListingId}/findByAirbnbListingId`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findListingByAirbnbId.method = "GET" as const;
findListingByAirbnbId.authSchema = { type: "", header: "" } as const;
/**
 * 物件の画像を削除する
 *
 */
export const deleteListingImage = ({ imageId }: { imageId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listing_images/${imageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteListingImage.method = "DELETE" as const;
deleteListingImage.authSchema = { type: "", header: "" } as const;
/**
 * 物件の画像を更新する
 *
 */
export const updateListingImage = ({ imageId }: { imageId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listing_images/${imageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateListingImage.method = "PATCH" as const;
updateListingImage.authSchema = { type: "", header: "" } as const;
/**
 * 施設を作成する
 *
 */
export const propertyCreate = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`property`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

propertyCreate.method = "POST" as const;
propertyCreate.authSchema = { type: "", header: "" } as const;
/**
 * 施設を編集する
 *
 */
export const propertyEdit = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`property/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

propertyEdit.method = "PUT" as const;
propertyEdit.authSchema = { type: "", header: "" } as const;
/**
 * 施設を削除する
 *
 */
export const propertyDelete = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`property/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

propertyDelete.method = "DELETE" as const;
propertyDelete.authSchema = { type: "", header: "" } as const;
/**
 * 施設の一覧を取得する
 *
 */
export const propertyList = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`property`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

propertyList.method = "GET" as const;
propertyList.authSchema = { type: "", header: "" } as const;
/**
 * 施設を取得する
 *
 */
export const propertyGet = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`property/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

propertyGet.method = "GET" as const;
propertyGet.authSchema = { type: "", header: "" } as const;
/**
 * 施設に紐づく部屋の一覧を取得する
 *
 */
export const propertyListRooms = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`property/${id}/rooms`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

propertyListRooms.method = "GET" as const;
propertyListRooms.authSchema = { type: "", header: "" } as const;
/**
 * 施設の1部屋タイプ1部屋のカレンダーの予約状況を取得する
 * @param {string} startDate 2020-01-01
 * @param {string} endDate 2020-01-31
 */
export const getPropertyCalendarItemsOnlySingleRoomTypesById = ({
  startDate,
  endDate,
  propertyId,
}: {
  startDate?: string;
  endDate?: string;
  propertyId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({ startDate, endDate })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`property/${propertyId}/calendar/only_single_room_types`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getPropertyCalendarItemsOnlySingleRoomTypesById.method = "GET" as const;
getPropertyCalendarItemsOnlySingleRoomTypesById.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * OTAからの予約を通知する
 *
 */
export const notificationReservationOta = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`notifications/reservations/ota`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

notificationReservationOta.method = "POST" as const;
notificationReservationOta.authSchema = { type: "", header: "" } as const;
/**
 * カスタム自動価格調整ポリシーを新規作成する
 *
 */
export const autoPricingPolicyCreate = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`auto_pricing_policies`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

autoPricingPolicyCreate.method = "POST" as const;
autoPricingPolicyCreate.authSchema = { type: "", header: "" } as const;
/**
 * カスタム自動価格調整ポリシーの一覧を取得する
 * @param {number} page 3
 */
export const autoPricingPolicyList = ({ page }: { page?: number }) => {
  const __root = root();
  const __queries = Object.entries({ page })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`auto_pricing_policies`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

autoPricingPolicyList.method = "GET" as const;
autoPricingPolicyList.authSchema = { type: "", header: "" } as const;
/**
 * 指定idの自動価格設定ポリシーを取得する
 *
 */
export const autoPricingPolicyGet = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`auto_pricing_policies/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

autoPricingPolicyGet.method = "GET" as const;
autoPricingPolicyGet.authSchema = { type: "", header: "" } as const;
/**
 * 指定idの自動価格設定ポリシーを更新する
 *
 */
export const autoPricingPolicyUpdate = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`auto_pricing_policies/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

autoPricingPolicyUpdate.method = "PUT" as const;
autoPricingPolicyUpdate.authSchema = { type: "", header: "" } as const;
/**
 * 指定idのカスタム自動価格調整ポリシーを削除する
 *
 */
export const autoPricingPolicyDelete = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`auto_pricing_policies/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

autoPricingPolicyDelete.method = "DELETE" as const;
autoPricingPolicyDelete.authSchema = { type: "", header: "" } as const;
/**
 * リスティングのオーナーを作成する
 *
 */
export const ownerCreate = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`owners`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

ownerCreate.method = "POST" as const;
ownerCreate.authSchema = { type: "", header: "" } as const;
/**
 * 管理しているリスティングのオーナー一覧を取得する
 *
 */
export const findAllMyOwners = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`owners`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findAllMyOwners.method = "GET" as const;
findAllMyOwners.authSchema = { type: "", header: "" } as const;
/**
 * firebaseAuthにログイン試行
 *
 */
export const loginForOwner = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`owners/login`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

loginForOwner.method = "POST" as const;
loginForOwner.authSchema = { type: "", header: "" } as const;
/**
 * ファイルをアップロードする
 *
 */
export const uploadFiles = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`owners/upload`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

uploadFiles.method = "POST" as const;
uploadFiles.authSchema = { type: "", header: "" } as const;
/**
 * オーナーごとのファイル一覧を取得する(管理者(弊社のユーザー)が叩く用)
 * @param {string} ownerId XXX
 */
export const getFileListsByOwner = ({ ownerId }: { ownerId?: string }) => {
  const __root = root();
  const __queries = Object.entries({ ownerId })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`owners/filelists`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getFileListsByOwner.method = "GET" as const;
getFileListsByOwner.authSchema = { type: "", header: "" } as const;
/**
 *
 * @param {string} fileId XXX
 */
export const deleteFile = ({ fileId }: { fileId?: string }) => {
  const __root = root();
  const __queries = Object.entries({ fileId })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`owners/delete`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteFile.method = "DELETE" as const;
deleteFile.authSchema = { type: "", header: "" } as const;
/**
 * オーナー履歴の作成
 *
 */
export const createOwnerHistory = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`owners/ownerHistory`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createOwnerHistory.method = "POST" as const;
createOwnerHistory.authSchema = { type: "", header: "" } as const;
/**
 * ownerHistoryIdを使って管理しているリスティングのオーナー履歴一覧を取得する
 *
 */
export const findOwnerHistoryById = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`owners/ownerHistory/${id}/`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findOwnerHistoryById.method = "GET" as const;
findOwnerHistoryById.authSchema = { type: "", header: "" } as const;
/**
 * listingIdを使って管理しているリスティングのオーナー履歴一覧を取得する
 *
 */
export const findAllOwnerHistoryByIdByListingId = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`owners/listings/${listingId}/ownerHistories`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findAllOwnerHistoryByIdByListingId.method = "GET" as const;
findAllOwnerHistoryByIdByListingId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * ownerIdを使って管理しているリスティングのオーナー履歴一覧を取得する
 *
 */
export const findAllOwnerHistoryByOwnerId = ({
  ownerId,
}: {
  ownerId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`owners/owners/${ownerId}/ownerHistories`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findAllOwnerHistoryByOwnerId.method = "GET" as const;
findAllOwnerHistoryByOwnerId.authSchema = { type: "", header: "" } as const;
/**
 * ownerHistoryIdを使って該当のオーナー履歴を更新する
 *
 */
export const updateOwnerHistory = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`owners/ownerHistory/${id}/`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateOwnerHistory.method = "PATCH" as const;
updateOwnerHistory.authSchema = { type: "", header: "" } as const;
/**
 * 指定idのオーナー履歴を削除する
 *
 */
export const deleteOwnerHistory = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`owners/ownerHistory/${id}/`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteOwnerHistory.method = "DELETE" as const;
deleteOwnerHistory.authSchema = { type: "", header: "" } as const;
/**
 * リスティングのオーナーを取得する
 *
 */
export const findOwnerByListingId = ({ listingId }: { listingId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`ownersByListingId/${listingId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findOwnerByListingId.method = "GET" as const;
findOwnerByListingId.authSchema = { type: "", header: "" } as const;
/**
 * 自社の部屋タイプ一覧を取得する
 *
 */
export const findMyRoomTypes = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`roomTypes`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findMyRoomTypes.method = "GET" as const;
findMyRoomTypes.authSchema = { type: "", header: "" } as const;
/**
 * 部屋タイプに対して手動作成を作成する
 *
 */
export const createRoomTypeManually = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`roomTypes/${id}/reservation`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createRoomTypeManually.method = "POST" as const;
createRoomTypeManually.authSchema = { type: "", header: "" } as const;
/**
 * 予約の移動を行う
 *
 */
export const transferRoomTypeReservation = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`roomTypes/${id}/reservation/transfer`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

transferRoomTypeReservation.method = "POST" as const;
transferRoomTypeReservation.authSchema = { type: "", header: "" } as const;
/**
 * 部屋タイプに対して専有を作成する
 *
 */
export const createRoomTypeProprietary = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`roomTypes/${id}/proprietary`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createRoomTypeProprietary.method = "POST" as const;
createRoomTypeProprietary.authSchema = { type: "", header: "" } as const;
/**
 * 部屋タイプに対して専有を作成する
 * @param {string} tokenId XXX
 * @param {string} tokenString XXX
 */
export const createRoomTypeProprietaryForOwner = ({
  tokenId,
  tokenString,
  id,
}: {
  tokenId?: string;
  tokenString?: string;
  id: string;
}) => {
  const __root = root();
  const __queries = Object.entries({ tokenId, tokenString })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`roomTypes/${id}/owner/proprietary`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createRoomTypeProprietaryForOwner.method = "POST" as const;
createRoomTypeProprietaryForOwner.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 部屋タイプとOTA上の物件を紐付ける
 *
 */
export const associateToOTA = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`roomTypes/${id}/ota`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

associateToOTA.method = "POST" as const;
associateToOTA.authSchema = { type: "", header: "" } as const;
/**
 * core外のサービスがcoreの部屋タイプとota上の物件の対応関係を把握するための情報を同期する
 *
 */
export const syncOtaRoomTypeRelation = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`roomTypes/${id}/sync_ota`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

syncOtaRoomTypeRelation.method = "POST" as const;
syncOtaRoomTypeRelation.authSchema = { type: "", header: "" } as const;
/**
 * 部屋タイプとOTA上の物件を紐付けを解除する
 *
 */
export const detachFromOTA = ({
  id,
  otaType,
  otaId,
}: {
  id: string;
  otaType: string;
  otaId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`roomTypes/${id}/ota/${otaType}/${otaId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

detachFromOTA.method = "DELETE" as const;
detachFromOTA.authSchema = { type: "", header: "" } as const;
/**
 * 専有を削除する
 *
 */
export const deleteRoomTypeProprietary = ({
  id,
  proprietaryId,
}: {
  id: string;
  proprietaryId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`roomTypes/${id}/proprietary/${proprietaryId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteRoomTypeProprietary.method = "DELETE" as const;
deleteRoomTypeProprietary.authSchema = { type: "", header: "" } as const;
/**
 * 専有を削除する
 * @param {string} tokenId XXX
 * @param {string} tokenString XXX
 */
export const deleteRoomTypeProprietaryForOwner = ({
  tokenId,
  tokenString,
  id,
  proprietaryId,
}: {
  tokenId?: string;
  tokenString?: string;
  id: string;
  proprietaryId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({ tokenId, tokenString })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`roomTypes/${id}/owner/proprietary/${proprietaryId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteRoomTypeProprietaryForOwner.method = "DELETE" as const;
deleteRoomTypeProprietaryForOwner.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 部屋タイプに対して手動作成の更新を行う
 *
 */
export const editRoomTypeManually = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`roomTypes/${id}/reservation`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

editRoomTypeManually.method = "PUT" as const;
editRoomTypeManually.authSchema = { type: "", header: "" } as const;
/**
 * 部屋タイプに対して専有の更新を行う
 *
 */
export const editRoomTypeProprietary = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`roomTypes/${id}/proprietary`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

editRoomTypeProprietary.method = "PUT" as const;
editRoomTypeProprietary.authSchema = { type: "", header: "" } as const;
/**
 * 部屋タイプに対して専有の更新を行う
 * @param {string} tokenId XXX
 * @param {string} tokenString XXX
 */
export const editRoomTypeProprietaryForOwner = ({
  tokenId,
  tokenString,
  id,
}: {
  tokenId?: string;
  tokenString?: string;
  id: string;
}) => {
  const __root = root();
  const __queries = Object.entries({ tokenId, tokenString })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`roomTypes/${id}/owner/proprietary`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

editRoomTypeProprietaryForOwner.method = "PUT" as const;
editRoomTypeProprietaryForOwner.authSchema = { type: "", header: "" } as const;
/**
 * OTA予約の固定状況,メモを更新する
 *
 */
export const updateOTAReservation = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`roomTypes/${id}/ota_reservation`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateOTAReservation.method = "PUT" as const;
updateOTAReservation.authSchema = { type: "", header: "" } as const;
/**
 * 部屋タイプを取得する
 *
 */
export const getRoomType = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`roomTypes/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getRoomType.method = "GET" as const;
getRoomType.authSchema = { type: "", header: "" } as const;
/**
 * 部屋タイプを更新する
 *
 */
export const updateRoomType = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`roomTypes/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateRoomType.method = "PUT" as const;
updateRoomType.authSchema = { type: "", header: "" } as const;
/**
 * 部屋タイプを削除する
 *
 */
export const deleteRoomType = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`roomTypes/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteRoomType.method = "DELETE" as const;
deleteRoomType.authSchema = { type: "", header: "" } as const;
/**
 * 部屋タイプに紐づく予約の割り当て履歴を取得する
 *
 */
export const findKeysByRoomTypeId = ({
  roomTypeId,
}: {
  roomTypeId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`roomTypes/${roomTypeId}/assignment_log`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findKeysByRoomTypeId.method = "GET" as const;
findKeysByRoomTypeId.authSchema = { type: "", header: "" } as const;
/**
 * @deprecated 部屋のカレンダー情報を取得する
 * @param {string} startDate 2020-01-01
 * @param {string} endDate 2020-12-31
 */
export const getRoomCalendar = ({
  startDate,
  endDate,
  id,
}: {
  startDate?: string;
  endDate?: string;
  id: string;
}) => {
  const __root = root();
  const __queries = Object.entries({ startDate, endDate })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`calendar/room/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getRoomCalendar.method = "GET" as const;
getRoomCalendar.authSchema = { type: "", header: "" } as const;
/**
 * 物件のカレンダー情報を取得する
 * @param {string} startDate 2020-01-01
 * @param {string} endDate 2020-12-31
 */
export const getListingCalendar = ({
  startDate,
  endDate,
  id,
}: {
  startDate?: string;
  endDate?: string;
  id: string;
}) => {
  const __root = root();
  const __queries = Object.entries({ startDate, endDate })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`calendar/listing/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getListingCalendar.method = "GET" as const;
getListingCalendar.authSchema = { type: "", header: "" } as const;
/**
 * 物件の民泊残日数を返す
 *
 */
export const getMinpakuYearStockByListingId = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`calendar/listing/${id}/minpaku_year_stock`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getMinpakuYearStockByListingId.method = "GET" as const;
getMinpakuYearStockByListingId.authSchema = { type: "", header: "" } as const;
/**
 * 部屋タイプに紐づくカレンダー情報を取得する
 *
 */
export const getRoomTypeCalendar = ({ roomTypeId }: { roomTypeId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`calendar/room_type/${roomTypeId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getRoomTypeCalendar.method = "GET" as const;
getRoomTypeCalendar.authSchema = { type: "", header: "" } as const;
/**
 * 部屋タイプカレンダーの操作ログ一覧を取得する
 *
 */
export const searchCalendarOperationLogByRoomTypeId = ({
  roomTypeId,
}: {
  roomTypeId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`calendar/room_type/${roomTypeId}/operations`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchCalendarOperationLogByRoomTypeId.method = "POST" as const;
searchCalendarOperationLogByRoomTypeId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 部屋タイプの民泊残日数を返す
 *
 */
export const getMinpakuYearStockByRoomTypeId = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`calendar/room_type/${id}/minpaku_year_stock`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getMinpakuYearStockByRoomTypeId.method = "GET" as const;
getMinpakuYearStockByRoomTypeId.authSchema = { type: "", header: "" } as const;
/**
 * @Deprecated 一時発行トークンを使って外部ユーザーがカレンダーを閲覧できるようにする
 * @param {number} page 0
 * @param {number} itemNum 30
 * @param {string} nameQuery hoge
 * @param {string} startDate 2020-10-01
 * @param {string} endDate 2020-11-30
 * @param {string} tokenId foo
 * @param {string} token bar
 */
export const getRoomCalendarByTemporalToken = ({
  page,
  itemNum,
  nameQuery,
  startDate,
  endDate,
  tokenId,
  token,
}: {
  page?: number;
  itemNum?: number;
  nameQuery?: string;
  startDate?: string;
  endDate?: string;
  tokenId?: string;
  token?: string;
}) => {
  const __root = root();
  const __queries = Object.entries({
    page,
    itemNum,
    nameQuery,
    startDate,
    endDate,
    tokenId,
    token,
  })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`calendar/external/calendar`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getRoomCalendarByTemporalToken.method = "GET" as const;
getRoomCalendarByTemporalToken.authSchema = { type: "", header: "" } as const;
/**
 * カレンダーの空き状況を取得する
 * @param {number} page 0
 * @param {number} itemsPerPage 30
 * @param {string} nameQuery hoge
 * @param {string} startDate 2020-10-01
 * @param {string} endDate 2020-11-30
 * @param {string} filterBySpan false
 * @param {string} listingIds a1f560f1-948b-44a8-8493-e50d3c1924da
 * @param {string} prefectureId XXX
 * @param {string} cityIds XXX,YYY,ZZZ
 * @param {string} rest hoge
 */
export const getCalendarAvailabilitiesByNameAndSpan = ({
  page,
  itemsPerPage,
  nameQuery,
  startDate,
  endDate,
  filterBySpan,
  listingIds,
  prefectureId,
  cityIds,
  rest,
}: {
  page?: number;
  itemsPerPage?: number;
  nameQuery?: string;
  startDate?: string;
  endDate?: string;
  filterBySpan?: string;
  listingIds?: string;
  prefectureId?: string;
  cityIds?: string;
  rest?: string;
}) => {
  const __root = root();
  const __queries = Object.entries({
    page,
    itemsPerPage,
    nameQuery,
    startDate,
    endDate,
    filterBySpan,
    listingIds,
    prefectureId,
    cityIds,
    rest,
  })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`calendar/availabilities`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getCalendarAvailabilitiesByNameAndSpan.method = "GET" as const;
getCalendarAvailabilitiesByNameAndSpan.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 民泊残日数一覧を返す
 * @param {number} targetYear 2021
 * @param {number} maxStock 30
 */
export const findMinpakuYearStocksByTargetYear = ({
  targetYear,
  maxStock,
}: {
  targetYear?: number;
  maxStock?: number;
}) => {
  const __root = root();
  const __queries = Object.entries({ targetYear, maxStock })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`calendar/minpaku_year_stocks`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findMinpakuYearStocksByTargetYear.method = "GET" as const;
findMinpakuYearStocksByTargetYear.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 一時発行トークンを使ってオーナーが自分の物件のカレンダーを取得する
 *
 */
export const searchListingCalendarsByTemporalTokenForOwner = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`calendar/owner/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchListingCalendarsByTemporalTokenForOwner.method = "POST" as const;
searchListingCalendarsByTemporalTokenForOwner.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * オーナーが予約を手動で作成する
 * @param {string} tokenId foo
 * @param {string} tokenString bar
 */
export const createReservationManuallyForOwner = ({
  tokenId,
  tokenString,
}: {
  tokenId?: string;
  tokenString?: string;
}) => {
  const __root = root();
  const __queries = Object.entries({ tokenId, tokenString })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`calendar/owner/reservation`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createReservationManuallyForOwner.method = "POST" as const;
createReservationManuallyForOwner.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * オーナーが予約を手動で更新する
 * @param {string} tokenId foo
 * @param {string} tokenString bar
 */
export const updateReservationManuallyForOwner = ({
  tokenId,
  tokenString,
}: {
  tokenId?: string;
  tokenString?: string;
}) => {
  const __root = root();
  const __queries = Object.entries({ tokenId, tokenString })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`calendar/owner/reservation`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateReservationManuallyForOwner.method = "PUT" as const;
updateReservationManuallyForOwner.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * オーナーがgeneralUserとcompanyを紐付ける
 *
 */
export const createUserWithCompanyRelation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`calendar/availabilities/user_company_relation`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createUserWithCompanyRelation.method = "POST" as const;
createUserWithCompanyRelation.authSchema = { type: "", header: "" } as const;
/**
 * 外部ユーザー向けカレンダーの空き状況を取得する
 * @param {number} page 0
 * @param {number} itemsPerPage 30
 * @param {string} nameQuery hoge
 * @param {string} startDate 2020-10-01
 * @param {string} endDate 2020-11-30
 * @param {string} filterBySpan false
 * @param {string} listingIds a1f560f1-948b-44a8-8493-e50d3c1924da
 */
export const getExternalAvailabilitiyCalendarDelegationByNameAndSpan = ({
  page,
  itemsPerPage,
  nameQuery,
  startDate,
  endDate,
  filterBySpan,
  listingIds,
}: {
  page?: number;
  itemsPerPage?: number;
  nameQuery?: string;
  startDate?: string;
  endDate?: string;
  filterBySpan?: string;
  listingIds?: string;
}) => {
  const __root = root();
  const __queries = Object.entries({
    page,
    itemsPerPage,
    nameQuery,
    startDate,
    endDate,
    filterBySpan,
    listingIds,
  })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`calendar/availabilities/external_availability_calendar_delegation`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getExternalAvailabilitiyCalendarDelegationByNameAndSpan.method = "GET" as const;
getExternalAvailabilitiyCalendarDelegationByNameAndSpan.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 部屋タイプのカレンダー在庫を検索する
 *
 */
export const searchRoomTypeCalendarStocks = ({
  roomTypeId,
}: {
  roomTypeId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`calendar_stocks/room_type/${roomTypeId}/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchRoomTypeCalendarStocks.method = "POST" as const;
searchRoomTypeCalendarStocks.authSchema = { type: "", header: "" } as const;
/**
 * リスティングをインポートする
 *
 */
export const importListing = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`imports/listings`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

importListing.method = "POST" as const;
importListing.authSchema = { type: "", header: "" } as const;
/**
 * otaの予約をimportする
 *
 */
export const importReservations = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`imports/ota_reservations`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

importReservations.method = "POST" as const;
importReservations.authSchema = { type: "", header: "" } as const;
/**
 * 部屋タイプに適切な運用形態が入るように修正するためのもの
 *
 */
export const importOperationType = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`imports/operation_type`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

importOperationType.method = "POST" as const;
importOperationType.authSchema = { type: "", header: "" } as const;
/**
 * 割り当て失敗予約の一覧を取得する
 *
 */
export const assignFailedReservations = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`reservations/assign_failed`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

assignFailedReservations.method = "GET" as const;
assignFailedReservations.authSchema = { type: "", header: "" } as const;
/**
 * 予約の詳細を取得する
 *
 */
export const reservationDetail = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`reservations/detail/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

reservationDetail.method = "GET" as const;
reservationDetail.authSchema = { type: "", header: "" } as const;
/**
 * 予約の情報をOTA情報から取得する
 *
 */
export const searchByOtaInfo = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`reservations/search_by_ota_info`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchByOtaInfo.method = "POST" as const;
searchByOtaInfo.authSchema = { type: "", header: "" } as const;
/**
 * 予約を検索する
 *
 */
export const searchReservation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`reservations/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchReservation.method = "POST" as const;
searchReservation.authSchema = { type: "", header: "" } as const;
/**
 * stayTypeを更新する
 *
 */
export const updateStayType = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`reservations/stay_type/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateStayType.method = "PUT" as const;
updateStayType.authSchema = { type: "", header: "" } as const;
/**
 * OTA上のIDを用いて予約を更新する
 *
 */
export const updateReservationByOtaInfo = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`reservations/ota_info`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateReservationByOtaInfo.method = "PUT" as const;
updateReservationByOtaInfo.authSchema = { type: "", header: "" } as const;
/**
 * matsuri固有の予約情報を更新する
 *
 */
export const registerMatsuriReservationInfo = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`reservations/matsuri_reservation_info/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

registerMatsuriReservationInfo.method = "POST" as const;
registerMatsuriReservationInfo.authSchema = { type: "", header: "" } as const;
/**
 * matsuri固有の予約情報を取得する
 *
 */
export const getMatsuriReservationInfo = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`reservations/matsuri_reservation_info/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getMatsuriReservationInfo.method = "GET" as const;
getMatsuriReservationInfo.authSchema = { type: "", header: "" } as const;
/**
 * 手動予約のotaIdを再設定する
 *
 */
export const resetManualReservationOtaId = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`reservations/reset_manual_reservation_ota_id/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

resetManualReservationOtaId.method = "PUT" as const;
resetManualReservationOtaId.authSchema = { type: "", header: "" } as const;
/**
 * 予約が割り当てられている部屋タイプ名を取得する
 *
 */
export const getRoomTypeNameByReservationId = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`reservations/${id}/room_type`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getRoomTypeNameByReservationId.method = "GET" as const;
getRoomTypeNameByReservationId.authSchema = { type: "", header: "" } as const;
/**
 * 予約のacceptedを更新する
 *
 */
export const updateReservationAccepted = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`reservations/${id}/update_accepted`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateReservationAccepted.method = "PUT" as const;
updateReservationAccepted.authSchema = { type: "", header: "" } as const;
/**
 * 予約割り当てログを取得する
 *
 */
export const findReservationAssignmentLogById = ({
  logId,
}: {
  logId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`reservations/assignment_log/${logId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findReservationAssignmentLogById.method = "GET" as const;
findReservationAssignmentLogById.authSchema = { type: "", header: "" } as const;
/**
 * 予約をキャンセルする
 *
 */
export const cancelReservation = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`reservations/${id}/cancel`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

cancelReservation.method = "POST" as const;
cancelReservation.authSchema = { type: "", header: "" } as const;
/**
 * 予約を強制的に未割り当てにする
 *
 */
export const forceUnassignedReservation = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`reservations/${id}/force_unassigned`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

forceUnassignedReservation.method = "POST" as const;
forceUnassignedReservation.authSchema = { type: "", header: "" } as const;
/**
 * 自社のairbnbのカレンダーを表示
 *
 */
export const showAirbnbCalendar = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`airbnb_calendars`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

showAirbnbCalendar.method = "GET" as const;
showAirbnbCalendar.authSchema = { type: "", header: "" } as const;
/**
 * 部屋タイプに紐づくairbnbのカレンダー一覧を返す
 *
 */
export const showAirbnbCalendarByRoomType = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`airbnb_calendars/room_type`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

showAirbnbCalendarByRoomType.method = "POST" as const;
showAirbnbCalendarByRoomType.authSchema = { type: "", header: "" } as const;
/**
 * 自社のairbnbのカレンダーを更新
 *
 */
export const applyAirbnbCalendar = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`airbnb_calendars`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

applyAirbnbCalendar.method = "POST" as const;
applyAirbnbCalendar.authSchema = { type: "", header: "" } as const;
/**
 * 最新の部屋タイプの日毎の稼働状況を取得する
 *
 */
export const getLatestOccupancyCalendar = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listing_monitor/latest_occupancy_calendar`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getLatestOccupancyCalendar.method = "GET" as const;
getLatestOccupancyCalendar.authSchema = { type: "", header: "" } as const;
/**
 * 実績を月単位で出力
 *
 */
export const exportAchievement = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`achievement/export`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

exportAchievement.method = "POST" as const;
exportAchievement.authSchema = { type: "", header: "" } as const;
/**
 * 予約詳細を出力
 *
 */
export const exportReservationDetails = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`achievement/reservation_details`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

exportReservationDetails.method = "POST" as const;
exportReservationDetails.authSchema = { type: "", header: "" } as const;
/**
 * 売上情報を登録する
 *
 */
export const saveMinpakuAndMonthlySales = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`achievement/sales`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveMinpakuAndMonthlySales.method = "POST" as const;
saveMinpakuAndMonthlySales.authSchema = { type: "", header: "" } as const;
/**
 * 予約Idから売り上げを取得する
 *
 */
export const findSalesByReservationId = ({
  reservationId,
}: {
  reservationId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`achievement/sales/${reservationId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findSalesByReservationId.method = "GET" as const;
findSalesByReservationId.authSchema = { type: "", header: "" } as const;
/**
 * 追加売り上げを作成する
 *
 */
export const registerAdditionalSales = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`achievement/additional_sales`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

registerAdditionalSales.method = "POST" as const;
registerAdditionalSales.authSchema = { type: "", header: "" } as const;
/**
 * 手動管理お問い合わせidと予約idの紐付けを行う
 *
 */
export const registerInquiryReservationRelation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`achievement/inquiry_reservation_relation`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

registerInquiryReservationRelation.method = "POST" as const;
registerInquiryReservationRelation.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 手動管理お問い合わせidと予約idの紐付けを取得する
 *
 */
export const getInquiryReservationRelation = ({
  reservationId,
}: {
  reservationId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`achievement/inquiry_reservation_relation/${reservationId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getInquiryReservationRelation.method = "GET" as const;
getInquiryReservationRelation.authSchema = { type: "", header: "" } as const;
/**
 * 手動リスティングidとリスティングidの紐付けを行う
 *
 */
export const registerListingIdListingManualIdRelation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`achievement/listing_id_listing_manual_id_relation`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

registerListingIdListingManualIdRelation.method = "POST" as const;
registerListingIdListingManualIdRelation.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 手動リスティングidとリスティングidの紐付けを習得する
 *
 */
export const getListingIdListingManualIdRelation = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`achievement/listing_id_listing_manual_id_relation/${listingId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getListingIdListingManualIdRelation.method = "GET" as const;
getListingIdListingManualIdRelation.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * AirbnbのPayout情報を検索する
 *
 */
export const searchAirbnbPayouts = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`charge/airbnb_payouts/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchAirbnbPayouts.method = "POST" as const;
searchAirbnbPayouts.authSchema = { type: "", header: "" } as const;
/**
 * Airbnbの入金情報を検索する
 *
 */
export const searchAirbnbStatements = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`charge/airbnb_statements/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchAirbnbStatements.method = "POST" as const;
searchAirbnbStatements.authSchema = { type: "", header: "" } as const;
/**
 * Airbnbの入金情報と銀行の入金情報を紐づける
 *
 */
export const createAirbnbBankStatementRelation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`charge/airbnb_statements/bank_statement_relation`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createAirbnbBankStatementRelation.method = "POST" as const;
createAirbnbBankStatementRelation.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * Airbnbの入金情報と銀行の入金情報の紐付けを削除する
 *
 */
export const deleteAirbnbBankStatementRelation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`charge/airbnb_statements/bank_statement_relation`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteAirbnbBankStatementRelation.method = "DELETE" as const;
deleteAirbnbBankStatementRelation.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * Airbnbの入金情報とCoreの予約を紐づける
 *
 */
export const createAirbnbStatementCoreReservationRelation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`charge/airbnb_statements/core_reservation_relation`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createAirbnbStatementCoreReservationRelation.method = "POST" as const;
createAirbnbStatementCoreReservationRelation.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * Airbnbの入金情報とCoreの予約の紐付けを削除する
 *
 */
export const deleteAirbnbStatementCoreReservationRelation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`charge/airbnb_statements/core_reservation_relation`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteAirbnbStatementCoreReservationRelation.method = "DELETE" as const;
deleteAirbnbStatementCoreReservationRelation.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * Airbnbの入金CSVをインポートする
 *
 */
export const importAirbnbCsv = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`charge/import_airbnb`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

importAirbnbCsv.method = "POST" as const;
importAirbnbCsv.authSchema = { type: "", header: "" } as const;
/**
 * Airbnbの入金をSystemsからインポートする
 *
 */
export const importAirbnbChargeFromSystems = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`charge/import_airbnb_from_systems`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

importAirbnbChargeFromSystems.method = "POST" as const;
importAirbnbChargeFromSystems.authSchema = { type: "", header: "" } as const;
/**
 * 銀行の入金情報を検索する
 *
 */
export const searchBankStatements = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`charge/bank_statements/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchBankStatements.method = "POST" as const;
searchBankStatements.authSchema = { type: "", header: "" } as const;
/**
 * みずほの入金CSVをインポートする
 *
 */
export const importMizuhoBankCsv = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`charge/import_mizuho`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

importMizuhoBankCsv.method = "POST" as const;
importMizuhoBankCsv.authSchema = { type: "", header: "" } as const;
/**
 * 一時発行トークンを発行する
 *
 */
export const generateTemporalToken = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`temporal_token`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

generateTemporalToken.method = "POST" as const;
generateTemporalToken.authSchema = { type: "", header: "" } as const;
/**
 * 一時発行トークンを破棄する
 *
 */
export const revokeTemporalToken = ({ tokenId }: { tokenId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`temporal_token/${tokenId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

revokeTemporalToken.method = "DELETE" as const;
revokeTemporalToken.authSchema = { type: "", header: "" } as const;
/**
 * オーナー向けに一時発行トークンを発行する
 *
 */
export const generateTemporalTokenForOwner = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`temporal_token_for_owners`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

generateTemporalTokenForOwner.method = "POST" as const;
generateTemporalTokenForOwner.authSchema = { type: "", header: "" } as const;
/**
 * 清掃請求書を取得する
 *
 */
export const findCleaningInvoiceById = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`invoice/cleaning_invoice/${cleaningId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCleaningInvoiceById.method = "GET" as const;
findCleaningInvoiceById.authSchema = { type: "", header: "" } as const;
/**
 * 清掃請求書を保存する
 *
 */
export const saveCleaningInvoice = ({ cleaningId }: { cleaningId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`invoice/cleaning_invoice/${cleaningId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveCleaningInvoice.method = "PUT" as const;
saveCleaningInvoice.authSchema = { type: "", header: "" } as const;
/**
 * 建物オーナー向け月別清掃請求書一覧を取得する
 *
 */
export const cleaningInvoiceMonthlyReportForOwner = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`invoice/cleaning_invoice/monthly_report`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

cleaningInvoiceMonthlyReportForOwner.method = "POST" as const;
cleaningInvoiceMonthlyReportForOwner.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 会社向け月別清掃請求書一覧を取得する
 *
 */
export const cleaningInvoiceMonthlyReportForCompany = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`invoice/cleaning_invoice/monthly_report_for_company`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

cleaningInvoiceMonthlyReportForCompany.method = "POST" as const;
cleaningInvoiceMonthlyReportForCompany.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 専有を検索する
 *
 */
export const searchProprietaries = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`proprietaries/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchProprietaries.method = "POST" as const;
searchProprietaries.authSchema = { type: "", header: "" } as const;
/**
 * (仮ブロック, 設営専用)専有を検索する
 *
 */
export const searchTemporaryBlockProprietaries = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`proprietaries/search_temporary_block`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchTemporaryBlockProprietaries.method = "POST" as const;
searchTemporaryBlockProprietaries.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 専有を取得する
 *
 */
export const getProprietary = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`proprietaries/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getProprietary.method = "GET" as const;
getProprietary.authSchema = { type: "", header: "" } as const;
/**
 * 手動予約タイプの一覧を取得する
 *
 */
export const getOtaManualTypes = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`ota_manual_types`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getOtaManualTypes.method = "GET" as const;
getOtaManualTypes.authSchema = { type: "", header: "" } as const;
/**
 * 手動予約タイプを新規作成する
 *
 */
export const putOtaManualType = ({ manualType }: { manualType: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`ota_manual_types/${manualType}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

putOtaManualType.method = "PUT" as const;
putOtaManualType.authSchema = { type: "", header: "" } as const;
/**
 * 手動予約タイプを削除する
 *
 */
export const deleteOtaManualType = ({ manualType }: { manualType: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`ota_manual_types/${manualType}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteOtaManualType.method = "DELETE" as const;
deleteOtaManualType.authSchema = { type: "", header: "" } as const;
/**
 * 観光庁システム向けに予約の営業日数CSVを出力する
 * @param {string} startDate 2020-01-01
 * @param {string} endDate 2021-12-31
 */
export const exportTjaReservationCsv = ({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) => {
  const __root = root();
  const __queries = Object.entries({ startDate, endDate })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`jta/exportReservationCsv`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

exportTjaReservationCsv.method = "POST" as const;
exportTjaReservationCsv.authSchema = { type: "", header: "" } as const;
/**
 * 物件の収支を月の範囲を指定して取得する
 * @param {string} start 2021-01
 * @param {string} end 2021-03
 */
export const findListingMonthBopByListingIdAndSpan = ({
  start,
  end,
  id,
}: {
  start?: string;
  end?: string;
  id: string;
}) => {
  const __root = root();
  const __queries = Object.entries({ start, end })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`bop/listings/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findListingMonthBopByListingIdAndSpan.method = "GET" as const;
findListingMonthBopByListingIdAndSpan.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 物件の収支を保存する
 *
 */
export const saveListingMonthBop = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`bop/listings/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveListingMonthBop.method = "PUT" as const;
saveListingMonthBop.authSchema = { type: "", header: "" } as const;
/**
 * 物件の収支をまとめて保存する
 *
 */
export const saveAllListingMonthBops = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`bop/listings`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveAllListingMonthBops.method = "PUT" as const;
saveAllListingMonthBops.authSchema = { type: "", header: "" } as const;
/**
 * 物件収支の精算を行う
 * @param {string} yearMonth 2021-01
 */
export const settleListingMonthBops = ({
  yearMonth,
}: {
  yearMonth?: string;
}) => {
  const __root = root();
  const __queries = Object.entries({ yearMonth })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`bop/settle`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

settleListingMonthBops.method = "POST" as const;
settleListingMonthBops.authSchema = { type: "", header: "" } as const;
/**
 * 全ての物件の収支を出力する
 * @param {string} yearMonths 2021-04,2021-05,2021-12
 */
export const exportAllListingBops = ({
  yearMonths,
}: {
  yearMonths?: string;
}) => {
  const __root = root();
  const __queries = Object.entries({ yearMonths })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`bop/export`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

exportAllListingBops.method = "GET" as const;
exportAllListingBops.authSchema = { type: "", header: "" } as const;
/**
 * 賃貸詳細を出力
 *
 */
export const exportLeaseDetails = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`leases/export`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

exportLeaseDetails.method = "POST" as const;
exportLeaseDetails.authSchema = { type: "", header: "" } as const;
/**
 * カレンダー上に賃貸を作成する
 *
 */
export const createLease = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`leases/`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createLease.method = "POST" as const;
createLease.authSchema = { type: "", header: "" } as const;
/**
 * 賃貸を取得する
 *
 */
export const findLeaseById = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`leases/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findLeaseById.method = "GET" as const;
findLeaseById.authSchema = { type: "", header: "" } as const;
/**
 * カレンダー上の賃貸を更新する
 *
 */
export const updateLease = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`leases/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateLease.method = "PUT" as const;
updateLease.authSchema = { type: "", header: "" } as const;
/**
 * カレンダー上の賃貸を削除する
 *
 */
export const deleteLease = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`leases/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteLease.method = "DELETE" as const;
deleteLease.authSchema = { type: "", header: "" } as const;
/**
 * 賃貸の更新通知に既読をつける
 *
 */
export const readLeaseNotification = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`leases/${id}/read`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

readLeaseNotification.method = "POST" as const;
readLeaseNotification.authSchema = { type: "", header: "" } as const;
/**
 * 更新作業が必要な賃貸の一覧を取得する
 *
 */
export const findNeedActionLeases = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`need_action_leases/`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findNeedActionLeases.method = "GET" as const;
findNeedActionLeases.authSchema = { type: "", header: "" } as const;
/**
 * イベント履歴をResourceKeyを使って取得する
 * @param {string} resourceType Reservation
 * @param {string} resourceId abcde
 */
export const findEventHistoriesByResourceKey = ({
  resourceType,
  resourceId,
}: {
  resourceType?: string;
  resourceId?: string;
}) => {
  const __root = root();
  const __queries = Object.entries({ resourceType, resourceId })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`eventHistories/resourceKey/find`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findEventHistoriesByResourceKey.method = "GET" as const;
findEventHistoriesByResourceKey.authSchema = { type: "", header: "" } as const;
/**
 * イベント履歴を予約IDを使って取得する
 *
 */
export const findEventHistoriesByReservationId = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`eventHistories/reservation/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findEventHistoriesByReservationId.method = "GET" as const;
findEventHistoriesByReservationId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * イベント履歴を専有IDを使って取得する
 *
 */
export const findEventHistoriesByProprietaryId = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`eventHistories/proprietary/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findEventHistoriesByProprietaryId.method = "GET" as const;
findEventHistoriesByProprietaryId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * イベント履歴を物件IDを使って取得する
 *
 */
export const findEventHistoriesByListingId = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`eventHistories/listing/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findEventHistoriesByListingId.method = "GET" as const;
findEventHistoriesByListingId.authSchema = { type: "", header: "" } as const;
/**
 * (devonly)OTA予約を作成する
 *
 */
export const devonlyCreateOtaReservation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`devonly/reservation/ota`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

devonlyCreateOtaReservation.method = "POST" as const;
devonlyCreateOtaReservation.authSchema = { type: "", header: "" } as const;
/**
 * 物品を作成する
 *
 */
export const createCommodity = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodities/`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createCommodity.method = "POST" as const;
createCommodity.authSchema = { type: "", header: "" } as const;
/**
 * 物品を検索する
 *
 */
export const searchCommodity = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodities/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchCommodity.method = "POST" as const;
searchCommodity.authSchema = { type: "", header: "" } as const;
/**
 * 物品を取得する
 *
 */
export const getCommodityById = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodities/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getCommodityById.method = "GET" as const;
getCommodityById.authSchema = { type: "", header: "" } as const;
/**
 * 物品の在庫を取得する
 *
 */
export const getCommodityStockById = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodities/${id}/stocks`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getCommodityStockById.method = "GET" as const;
getCommodityStockById.authSchema = { type: "", header: "" } as const;
/**
 * 物品を更新する
 *
 */
export const updateCommodity = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodities/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateCommodity.method = "PATCH" as const;
updateCommodity.authSchema = { type: "", header: "" } as const;
/**
 * 物品の画像をアップロードする
 *
 */
export const uploadCommodityImage = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodities/${id}/images`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

uploadCommodityImage.method = "POST" as const;
uploadCommodityImage.authSchema = { type: "", header: "" } as const;
/**
 * 物品の画像を取得する
 *
 */
export const getCommodityImagesByCommodityId = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodities/${id}/images`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getCommodityImagesByCommodityId.method = "GET" as const;
getCommodityImagesByCommodityId.authSchema = { type: "", header: "" } as const;
/**
 * 物品の仕入れ情報を商品IDで検索する
 *
 */
export const searchCommodityOrderEventsByCommodityId = ({
  id,
}: {
  id: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodities/${id}/orderEvents/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchCommodityOrderEventsByCommodityId.method = "POST" as const;
searchCommodityOrderEventsByCommodityId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 物品の配置情報を商品IDで取得する
 *
 */
export const getCommodityPlacementEventsByCommodityId = ({
  id,
}: {
  id: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodities/${id}/placementEvents`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getCommodityPlacementEventsByCommodityId.method = "GET" as const;
getCommodityPlacementEventsByCommodityId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 物品の画像を削除する
 *
 */
export const deleteCommodityImage = ({ imageId }: { imageId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityImages/${imageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteCommodityImage.method = "DELETE" as const;
deleteCommodityImage.authSchema = { type: "", header: "" } as const;
/**
 * @Deprecated 物品の配置を登録する
 *
 */
export const createCommodityLocation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityLocations`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createCommodityLocation.method = "POST" as const;
createCommodityLocation.authSchema = { type: "", header: "" } as const;
/**
 * @Deprecated 物品の配置を更新する
 *
 */
export const updateCommodityLocation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityLocations`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateCommodityLocation.method = "PATCH" as const;
updateCommodityLocation.authSchema = { type: "", header: "" } as const;
/**
 * 物品の配置を更新する
 *
 */
export const saveCommodityLocation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityLocations`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveCommodityLocation.method = "PUT" as const;
saveCommodityLocation.authSchema = { type: "", header: "" } as const;
/**
 * 物品の配置を物品IDで取得する
 *
 */
export const getCommodityLocationsByCommodityId = ({
  commodityId,
}: {
  commodityId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityLocations/commodityId/${commodityId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getCommodityLocationsByCommodityId.method = "GET" as const;
getCommodityLocationsByCommodityId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 物品の配置から物品を検索する
 *
 */
export const searchCommodityLocationsByLocation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityLocations/search/location`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchCommodityLocationsByLocation.method = "GET" as const;
searchCommodityLocationsByLocation.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 物品の仕入れを作成する
 *
 */
export const createCommodityOrderEvent = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityOrderEvents`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createCommodityOrderEvent.method = "POST" as const;
createCommodityOrderEvent.authSchema = { type: "", header: "" } as const;
/**
 * 物品の仕入れを更新する
 *
 */
export const updateCommodityOrderEvent = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityOrderEvents/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateCommodityOrderEvent.method = "PATCH" as const;
updateCommodityOrderEvent.authSchema = { type: "", header: "" } as const;
/**
 * 物品の仕入れを更新する(WMSとの連携なし)
 *
 */
export const noSyncUpdateCommodityOrderEvent = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityOrderEvents/${id}/update_nosync`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

noSyncUpdateCommodityOrderEvent.method = "PATCH" as const;
noSyncUpdateCommodityOrderEvent.authSchema = { type: "", header: "" } as const;
/**
 * 物品の仕入れを取得する
 *
 */
export const findCommodityOrderEventById = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityOrderEvents/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCommodityOrderEventById.method = "GET" as const;
findCommodityOrderEventById.authSchema = { type: "", header: "" } as const;
/**
 * 物品の仕入れを検索する
 *
 */
export const searchCommodityOrderEvents = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityOrderEvents/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchCommodityOrderEvents.method = "POST" as const;
searchCommodityOrderEvents.authSchema = { type: "", header: "" } as const;
/**
 * 物品の仕入れを削除する
 *
 */
export const deleteCommodityOrderEvent = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityOrderEvents/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteCommodityOrderEvent.method = "DELETE" as const;
deleteCommodityOrderEvent.authSchema = { type: "", header: "" } as const;
/**
 * 倉庫管理者が物品の仕入れを検索する
 *
 */
export const searchStoreKeeperCommodityOrderEvents = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/storeKeeper/commodityOrderEvents/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchStoreKeeperCommodityOrderEvents.method = "POST" as const;
searchStoreKeeperCommodityOrderEvents.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 倉庫管理者が物品の仕入れを更新する
 *
 */
export const updateStoreKeeperCommodityOrderEvent = ({
  id,
}: {
  id: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/storeKeeper/commodityOrderEvents/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateStoreKeeperCommodityOrderEvent.method = "PATCH" as const;
updateStoreKeeperCommodityOrderEvent.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 倉庫管理者が物品の配置を検索する
 *
 */
export const searchStoreKeeperCommodityPlacementEvents = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/storeKeeper/commodityPlacementEvents/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchStoreKeeperCommodityPlacementEvents.method = "POST" as const;
searchStoreKeeperCommodityPlacementEvents.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 倉庫管理者が物品の配置を更新する
 *
 */
export const updateStoreKeeperCommodityPlacementEvent = ({
  id,
}: {
  id: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/storeKeeper/commodityPlacementEvents/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateStoreKeeperCommodityPlacementEvent.method = "PATCH" as const;
updateStoreKeeperCommodityPlacementEvent.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 設置作業の委譲を更新する
 *
 */
export const saveCarryInDelegation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/carryIn/delegation`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveCarryInDelegation.method = "PUT" as const;
saveCarryInDelegation.authSchema = { type: "", header: "" } as const;
/**
 * 設置作業の委譲を取得する
 *
 */
export const getCarryInDelegationByListingId = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/carryIn/delegation/listings/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getCarryInDelegationByListingId.method = "GET" as const;
getCarryInDelegationByListingId.authSchema = { type: "", header: "" } as const;
/**
 * 外部業者が委譲されている物件の詳細を取得する
 *
 */
export const getCarryInDelegatedListingById = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/carryIn/delegatedListings/${listingId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getCarryInDelegatedListingById.method = "GET" as const;
getCarryInDelegatedListingById.authSchema = { type: "", header: "" } as const;
/**
 * 倉庫管理者が委譲されている物件一覧を取得する
 *
 */
export const getStoreKeeperDelegatedListings = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/carryIn/delegatedListings/storeKeeper`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getStoreKeeperDelegatedListings.method = "POST" as const;
getStoreKeeperDelegatedListings.authSchema = { type: "", header: "" } as const;
/**
 * 運送業者が委譲されている物件一覧を取得する
 *
 */
export const getCarryInDelegatedListings = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/carryIn/delegatedListings/carryIn`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getCarryInDelegatedListings.method = "POST" as const;
getCarryInDelegatedListings.authSchema = { type: "", header: "" } as const;
/**
 * 設置作業の配置情報を検索する
 *
 */
export const searchCarryInCommodityPlacementEvents = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/carryIn/commodityPlacementEvents/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchCarryInCommodityPlacementEvents.method = "POST" as const;
searchCarryInCommodityPlacementEvents.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 設置作業の配置情報を更新する
 *
 */
export const updateCarryInCommodityPlacementEvent = ({
  id,
}: {
  id: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/carryIn/commodityPlacementEvents/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateCarryInCommodityPlacementEvent.method = "PATCH" as const;
updateCarryInCommodityPlacementEvent.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 物品の配置イベントを作成する
 *
 */
export const createCommodityPlacementEvent = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityPlacementEvents`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createCommodityPlacementEvent.method = "POST" as const;
createCommodityPlacementEvent.authSchema = { type: "", header: "" } as const;
/**
 * 物品の配置情報を検索する
 *
 */
export const searchCommodityPlacementEvents = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityPlacementEvents/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchCommodityPlacementEvents.method = "POST" as const;
searchCommodityPlacementEvents.authSchema = { type: "", header: "" } as const;
/**
 * 物品の出荷指示を行う
 *
 */
export const instructShipmentGroup = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityPlacementEvents/shipment/instruct`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

instructShipmentGroup.method = "POST" as const;
instructShipmentGroup.authSchema = { type: "", header: "" } as const;
/**
 * 物品の出荷指示を削除する
 *
 */
export const deleteShipmentGroup = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityPlacementEvents/shipment`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteShipmentGroup.method = "DELETE" as const;
deleteShipmentGroup.authSchema = { type: "", header: "" } as const;
/**
 * 物品の配置イベントを更新する
 *
 */
export const updateCommodityPlacementEvent = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityPlacementEvents/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateCommodityPlacementEvent.method = "PATCH" as const;
updateCommodityPlacementEvent.authSchema = { type: "", header: "" } as const;
/**
 * 物品の配置イベントを取得する
 *
 */
export const findCommodityPlacementEventById = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityPlacementEvents/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCommodityPlacementEventById.method = "GET" as const;
findCommodityPlacementEventById.authSchema = { type: "", header: "" } as const;
/**
 * 物品の配置を削除する
 *
 */
export const deleteCommodityPlacementEvent = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityPlacementEvents/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteCommodityPlacementEvent.method = "DELETE" as const;
deleteCommodityPlacementEvent.authSchema = { type: "", header: "" } as const;
/**
 * 物品の回収を行う
 *
 */
export const collectCommodityPlacementEvent = ({
  placementId,
}: {
  placementId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityPlacementEvents/${placementId}/collect`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

collectCommodityPlacementEvent.method = "POST" as const;
collectCommodityPlacementEvent.authSchema = { type: "", header: "" } as const;
/**
 * 物品の一部回収を行う
 *
 */
export const splitAndCollectCommodityPlacementEvent = ({
  placementId,
}: {
  placementId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityPlacementEvents/${placementId}/splitAndCollect`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

splitAndCollectCommodityPlacementEvent.method = "POST" as const;
splitAndCollectCommodityPlacementEvent.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 物品の一部廃棄を行う
 *
 */
export const splitAndTrashCommodityPlacementEvent = ({
  placementId,
}: {
  placementId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityPlacementEvents/${placementId}/splitAndTrash`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

splitAndTrashCommodityPlacementEvent.method = "POST" as const;
splitAndTrashCommodityPlacementEvent.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 物品の廃棄を行う
 *
 */
export const trashCommodityPlacementEvent = ({
  placementId,
}: {
  placementId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityPlacementEvents/${placementId}/trash`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

trashCommodityPlacementEvent.method = "POST" as const;
trashCommodityPlacementEvent.authSchema = { type: "", header: "" } as const;
/**
 * 物品の仕入れと配置の紐付けを更新する
 *
 */
export const saveAllCommodityOrderPlacementRelations = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityOrderPlacementRelations`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveAllCommodityOrderPlacementRelations.method = "PUT" as const;
saveAllCommodityOrderPlacementRelations.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 物品の仕入れと配置の紐付けを削除する
 *
 */
export const deleteCommodityOrderPlacementRelation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityOrderPlacementRelations`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteCommodityOrderPlacementRelation.method = "DELETE" as const;
deleteCommodityOrderPlacementRelation.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 物品の仕入れと配置の紐付けを仕入れIDから取得する
 *
 */
export const findCommodityOrderPlacementRelationByOrderId = ({
  orderId,
}: {
  orderId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityOrderPlacementRelations/find_by_order_id/${orderId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCommodityOrderPlacementRelationByOrderId.method = "GET" as const;
findCommodityOrderPlacementRelationByOrderId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 物品の仕入れと配置の紐付けを配置IDから取得する
 *
 */
export const findCommodityOrderPlacementRelationByPlacementId = ({
  placementId,
}: {
  placementId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityOrderPlacementRelations/find_by_placement_id/${placementId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCommodityOrderPlacementRelationByPlacementId.method = "GET" as const;
findCommodityOrderPlacementRelationByPlacementId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 紐付け可能な仕入れを検索する
 *
 */
export const searchAvailableOrders = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/commodityOrderPlacementRelations/searchAvailableOrders`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchAvailableOrders.method = "POST" as const;
searchAvailableOrders.authSchema = { type: "", header: "" } as const;
/**
 * 倉庫を作成する
 *
 */
export const createStorage = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/storages/`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createStorage.method = "POST" as const;
createStorage.authSchema = { type: "", header: "" } as const;
/**
 * 倉庫一覧を取得する
 *
 */
export const findStoragesByCompanyId = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/storages/`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findStoragesByCompanyId.method = "GET" as const;
findStoragesByCompanyId.authSchema = { type: "", header: "" } as const;
/**
 * 倉庫を更新する
 *
 */
export const updateStorage = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/storages/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateStorage.method = "PATCH" as const;
updateStorage.authSchema = { type: "", header: "" } as const;
/**
 * リソースキーで物品のイベント履歴を取得する
 * @param {string} resourceType resource
 * @param {string} resourceId xxx
 */
export const findInventoryEventHistoryByResourceKey = ({
  resourceType,
  resourceId,
}: {
  resourceType?: string;
  resourceId?: string;
}) => {
  const __root = root();
  const __queries = Object.entries({ resourceType, resourceId })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/eventHistory/resourceKey/find`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findInventoryEventHistoryByResourceKey.method = "GET" as const;
findInventoryEventHistoryByResourceKey.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 物品注文イベントIDで物品のイベント履歴を取得する
 * @param {string} eventId xxx
 */
export const findInventoryEventHistoryByCommodityOrderEvent = ({
  eventId,
}: {
  eventId?: string;
}) => {
  const __root = root();
  const __queries = Object.entries({ eventId })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/eventHistory/resourceKey/commodityOrderEvent/find`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findInventoryEventHistoryByCommodityOrderEvent.method = "GET" as const;
findInventoryEventHistoryByCommodityOrderEvent.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 物品配置イベントIDで物品のイベント履歴を取得する
 * @param {string} eventId xxx
 */
export const findInventoryEventHistoryByCommodityPlacementEvent = ({
  eventId,
}: {
  eventId?: string;
}) => {
  const __root = root();
  const __queries = Object.entries({ eventId })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/eventHistory/resourceKey/commodityPlacementEvent/find`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findInventoryEventHistoryByCommodityPlacementEvent.method = "GET" as const;
findInventoryEventHistoryByCommodityPlacementEvent.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 物品IDとロケーションで物品のイベント履歴を取得する
 * @param {string} commodityId xxx
 * @param {string} locationType coreListing
 * @param {string} coreListing ccc
 * @param {string} storageId sss
 */
export const findInventoryEventHistoryByCommodityLocationRelation = ({
  commodityId,
  locationType,
  coreListing,
  storageId,
}: {
  commodityId?: string;
  locationType?: string;
  coreListing?: string;
  storageId?: string;
}) => {
  const __root = root();
  const __queries = Object.entries({
    commodityId,
    locationType,
    coreListing,
    storageId,
  })
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/eventHistory/resourceKey/commodityLocationRelation/find`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findInventoryEventHistoryByCommodityLocationRelation.method = "GET" as const;
findInventoryEventHistoryByCommodityLocationRelation.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 棚卸し補正を作成する
 *
 */
export const createStockAdjustment = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/stockAdjustments`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createStockAdjustment.method = "POST" as const;
createStockAdjustment.authSchema = { type: "", header: "" } as const;
/**
 * 棚卸し補正を取得する
 *
 */
export const findStockAdjustmentById = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/stockAdjustments/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findStockAdjustmentById.method = "GET" as const;
findStockAdjustmentById.authSchema = { type: "", header: "" } as const;
/**
 * 棚卸し補正を物品IDで取得する
 *
 */
export const findStockAdjustmentByCommodityId = ({
  commodityId,
}: {
  commodityId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/stockAdjustments/commodity/${commodityId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findStockAdjustmentByCommodityId.method = "GET" as const;
findStockAdjustmentByCommodityId.authSchema = { type: "", header: "" } as const;
/**
 * 棚卸し補正を更新する
 *
 */
export const updateStockAdjustment = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/stockAdjustments/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateStockAdjustment.method = "PATCH" as const;
updateStockAdjustment.authSchema = { type: "", header: "" } as const;
/**
 * 調達先一覧を取得する
 *
 */
export const findSupplierListByCompanyId = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/supplierList`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findSupplierListByCompanyId.method = "GET" as const;
findSupplierListByCompanyId.authSchema = { type: "", header: "" } as const;
/**
 * 調達先一覧を保存する
 *
 */
export const saveSupplierList = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/supplierList`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveSupplierList.method = "PUT" as const;
saveSupplierList.authSchema = { type: "", header: "" } as const;
/**
 * 仕入れグループを作成する
 *
 */
export const createOrderGroup = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/orderGroups`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createOrderGroup.method = "POST" as const;
createOrderGroup.authSchema = { type: "", header: "" } as const;
/**
 * 仕入れグループを更新する
 *
 */
export const updateOrderGroup = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/orderGroups/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateOrderGroup.method = "PATCH" as const;
updateOrderGroup.authSchema = { type: "", header: "" } as const;
/**
 * 仕入れグループを取得する
 *
 */
export const findOrderGroupById = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/orderGroups/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findOrderGroupById.method = "GET" as const;
findOrderGroupById.authSchema = { type: "", header: "" } as const;
/**
 * 仕入れグループを検索する
 *
 */
export const searchOrderGroup = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/orderGroups/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchOrderGroup.method = "POST" as const;
searchOrderGroup.authSchema = { type: "", header: "" } as const;
/**
 * 仕入れグループのドキュメントを取得する
 *
 */
export const findOrderGroupDocumentByOrderGroupId = ({
  orderGroupId,
}: {
  orderGroupId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/orderGroups/${orderGroupId}/documents`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findOrderGroupDocumentByOrderGroupId.method = "GET" as const;
findOrderGroupDocumentByOrderGroupId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 仕入れグループのドキュメントをアップロードする
 *
 */
export const uploadOrderGroupDocument = ({
  orderGroupId,
}: {
  orderGroupId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/orderGroups/${orderGroupId}/documents`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

uploadOrderGroupDocument.method = "POST" as const;
uploadOrderGroupDocument.authSchema = { type: "", header: "" } as const;
/**
 * 仕入れグループのドキュメントを更新する
 *
 */
export const updateOrderGroupDocument = ({
  documentId,
}: {
  documentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/orderGroupDocument/${documentId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateOrderGroupDocument.method = "PATCH" as const;
updateOrderGroupDocument.authSchema = { type: "", header: "" } as const;
/**
 * 仕入れグループのドキュメントを削除する
 *
 */
export const deleteOrderGroupDocument = ({
  documentId,
}: {
  documentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/orderGroupDocument/${documentId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteOrderGroupDocument.method = "DELETE" as const;
deleteOrderGroupDocument.authSchema = { type: "", header: "" } as const;
/**
 * 仕入れグループと仕入れの紐付けを作成する
 *
 */
export const createOrderGroupRelation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/orderGroupRelations`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createOrderGroupRelation.method = "POST" as const;
createOrderGroupRelation.authSchema = { type: "", header: "" } as const;
/**
 * 仕入れグループと仕入れの紐付けを削除する
 *
 */
export const deleteOrderGroupRelation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/orderGroupRelations`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteOrderGroupRelation.method = "DELETE" as const;
deleteOrderGroupRelation.authSchema = { type: "", header: "" } as const;
/**
 * 仕入れグループと仕入れの紐付けを仕入れグループIDから取得する
 *
 */
export const findOrderGroupRelationByGroupId = ({
  groupId,
}: {
  groupId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/orderGroupRelations/group/${groupId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findOrderGroupRelationByGroupId.method = "GET" as const;
findOrderGroupRelationByGroupId.authSchema = { type: "", header: "" } as const;
/**
 * 仕入れグループと仕入れの紐付けを仕入れIDから取得する
 *
 */
export const findOrderGroupRelationByOrderId = ({
  orderId,
}: {
  orderId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/orderGroupRelations/order/${orderId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findOrderGroupRelationByOrderId.method = "GET" as const;
findOrderGroupRelationByOrderId.authSchema = { type: "", header: "" } as const;
/**
 * 備品を登録する
 *
 */
export const createEquipment = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/equipments/`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createEquipment.method = "POST" as const;
createEquipment.authSchema = { type: "", header: "" } as const;
/**
 * 備品一覧を取得する
 *
 */
export const findEquipmentsByCompanyId = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/equipments/`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findEquipmentsByCompanyId.method = "GET" as const;
findEquipmentsByCompanyId.authSchema = { type: "", header: "" } as const;
/**
 * 備品を取得する
 *
 */
export const findEquipmentById = ({ equipmentId }: { equipmentId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/equipments/${equipmentId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findEquipmentById.method = "GET" as const;
findEquipmentById.authSchema = { type: "", header: "" } as const;
/**
 * 備品を登録する
 *
 */
export const updateEquipment = ({ equipmentId }: { equipmentId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/equipments/${equipmentId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateEquipment.method = "PATCH" as const;
updateEquipment.authSchema = { type: "", header: "" } as const;
/**
 * 参考画像を登録する
 *
 */
export const createEquipmentRefImage = ({
  equipmentId,
}: {
  equipmentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/equipments/${equipmentId}/refImages`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createEquipmentRefImage.method = "POST" as const;
createEquipmentRefImage.authSchema = { type: "", header: "" } as const;
/**
 * 参考画像を更新する
 *
 */
export const updateEquipmentRefImage = ({
  equipmentId,
  imageId,
}: {
  equipmentId: string;
  imageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/equipments/${equipmentId}/refImages/${imageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateEquipmentRefImage.method = "PATCH" as const;
updateEquipmentRefImage.authSchema = { type: "", header: "" } as const;
/**
 * 参考画像を削除する
 *
 */
export const deleteEquipmentRefImage = ({
  equipmentId,
  imageId,
}: {
  equipmentId: string;
  imageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/equipments/${equipmentId}/refImages/${imageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteEquipmentRefImage.method = "DELETE" as const;
deleteEquipmentRefImage.authSchema = { type: "", header: "" } as const;
/**
 * 参考画像を取得する
 *
 */
export const findEquipmentRefImage = ({
  equipmentId,
}: {
  equipmentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/equipments/${equipmentId}/refImages`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findEquipmentRefImage.method = "GET" as const;
findEquipmentRefImage.authSchema = { type: "", header: "" } as const;
/**
 * 備品の画像を削除する
 *
 */
export const deleteEquipmentImage = ({ imageId }: { imageId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/equipmentImages/${imageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteEquipmentImage.method = "DELETE" as const;
deleteEquipmentImage.authSchema = { type: "", header: "" } as const;
/**
 * 画像URIからOCRテキストを取得する
 *
 */
export const ocrDetectTextURI = ({
  equipmentId,
  equipmentImageId,
}: {
  equipmentId: string;
  equipmentImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/equipmentImageOcr/equipment/${equipmentId}/${equipmentImageId}/detectTextURI`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

ocrDetectTextURI.method = "GET" as const;
ocrDetectTextURI.authSchema = { type: "", header: "" } as const;
/**
 * 画像からDocumentTextを取得する
 *
 */
export const ocrDetectDocumentText = ({
  equipmentId,
  equipmentImageId,
}: {
  equipmentId: string;
  equipmentImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/equipmentImageOcr/equipment/${equipmentId}/${equipmentImageId}/detectDocumentText`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

ocrDetectDocumentText.method = "POST" as const;
ocrDetectDocumentText.authSchema = { type: "", header: "" } as const;
/**
 * 画像URIからDocumentTextを取得する
 *
 */
export const ocrDetectDocumentTextURI = ({
  equipmentId,
  equipmentImageId,
}: {
  equipmentId: string;
  equipmentImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/equipmentImageOcr/equipment/${equipmentId}/${equipmentImageId}/detectDocumentTextURI`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

ocrDetectDocumentTextURI.method = "GET" as const;
ocrDetectDocumentTextURI.authSchema = { type: "", header: "" } as const;
/**
 * 画像URIからラベルを取得する
 *
 */
export const ocrDetectLabelsURI = ({
  equipmentId,
  equipmentImageId,
}: {
  equipmentId: string;
  equipmentImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/equipmentImageOcr/equipment/${equipmentId}/${equipmentImageId}/detectLabelsURI`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

ocrDetectLabelsURI.method = "GET" as const;
ocrDetectLabelsURI.authSchema = { type: "", header: "" } as const;
/**
 * 画像をWebで検索する
 *
 */
export const ocrDetectWeb = ({
  equipmentId,
  equipmentImageId,
}: {
  equipmentId: string;
  equipmentImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/equipmentImageOcr/equipment/${equipmentId}/${equipmentImageId}/detectWeb`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

ocrDetectWeb.method = "POST" as const;
ocrDetectWeb.authSchema = { type: "", header: "" } as const;
/**
 * 画像URIをWebで検索する
 *
 */
export const ocrDetectWebURI = ({
  equipmentId,
  equipmentImageId,
}: {
  equipmentId: string;
  equipmentImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/equipmentImageOcr/equipment/${equipmentId}/${equipmentImageId}/detectWebURI`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

ocrDetectWebURI.method = "GET" as const;
ocrDetectWebURI.authSchema = { type: "", header: "" } as const;
/**
 * 参考動画を登録する
 *
 */
export const createEquipmentRefMovie = ({
  equipmentId,
}: {
  equipmentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/equipmentRefMovie/${equipmentId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createEquipmentRefMovie.method = "POST" as const;
createEquipmentRefMovie.authSchema = { type: "", header: "" } as const;
/**
 * 参考動画を取得する
 *
 */
export const findEquipmentRefMovie = ({
  equipmentId,
}: {
  equipmentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/equipmentRefMovie/${equipmentId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findEquipmentRefMovie.method = "GET" as const;
findEquipmentRefMovie.authSchema = { type: "", header: "" } as const;
/**
 * 参考動画を削除する
 *
 */
export const deleteEquipmentRefMovie = ({ movieId }: { movieId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/equipmentRefMovie/${movieId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteEquipmentRefMovie.method = "DELETE" as const;
deleteEquipmentRefMovie.authSchema = { type: "", header: "" } as const;
/**
 * 物件IDから現在の備品画像ID一覧を取得する
 *
 */
export const findCurrentImageIdsByListingId = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/listing_equipment_current_images/${listingId}/ids`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCurrentImageIdsByListingId.method = "GET" as const;
findCurrentImageIdsByListingId.authSchema = { type: "", header: "" } as const;
/**
 * 物件IDから現在の備品画像一覧を取得する
 *
 */
export const findCurrentImagesByListingId = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/listing_equipment_current_images/${listingId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCurrentImagesByListingId.method = "GET" as const;
findCurrentImagesByListingId.authSchema = { type: "", header: "" } as const;
/**
 * 物件IDから現在の備品画像一覧を更新する
 *
 */
export const updateCurrentImagesByListingId = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inventory/listing_equipment_current_images/${listingId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateCurrentImagesByListingId.method = "PUT" as const;
updateCurrentImagesByListingId.authSchema = { type: "", header: "" } as const;
/**
 * 内見を作成
 *
 */
export const createInspection = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/inspections`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createInspection.method = "POST" as const;
createInspection.authSchema = { type: "", header: "" } as const;
/**
 * 内見を作成し、作業員を割り当てる
 *
 */
export const createInspectionWithOperators = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/inspections/createInspectionWithOperators`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createInspectionWithOperators.method = "POST" as const;
createInspectionWithOperators.authSchema = { type: "", header: "" } as const;
/**
 * 内見を削除
 *
 */
export const deleteInspection = ({
  inspectionId,
}: {
  inspectionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/inspections/${inspectionId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteInspection.method = "DELETE" as const;
deleteInspection.authSchema = { type: "", header: "" } as const;
/**
 * 内見を更新
 *
 */
export const updateInspection = ({
  inspectionId,
}: {
  inspectionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/inspections/${inspectionId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateInspection.method = "POST" as const;
updateInspection.authSchema = { type: "", header: "" } as const;
/**
 * 内見を取得
 *
 */
export const getInspection = ({ inspectionId }: { inspectionId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/inspections/${inspectionId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getInspection.method = "GET" as const;
getInspection.authSchema = { type: "", header: "" } as const;
/**
 * 内見を無効化
 *
 */
export const invalidateInspection = ({
  inspectionId,
}: {
  inspectionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/inspections/${inspectionId}/invalidate`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

invalidateInspection.method = "POST" as const;
invalidateInspection.authSchema = { type: "", header: "" } as const;
/**
 * 内見を有効化
 *
 */
export const validateInspection = ({
  inspectionId,
}: {
  inspectionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/inspections/${inspectionId}/validate`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

validateInspection.method = "POST" as const;
validateInspection.authSchema = { type: "", header: "" } as const;
/**
 * 内見を日付で検索
 *
 */
export const searchInspectionsByInspectionDateSpan = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/inspections/search_by_inspection_date`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchInspectionsByInspectionDateSpan.method = "POST" as const;
searchInspectionsByInspectionDateSpan.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 内見を日付で検索(operators付き)
 *
 */
export const searchInspectionsByInspectionDateSpanWithOperators = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/inspections/search_by_inspection_date_operators`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchInspectionsByInspectionDateSpanWithOperators.method = "POST" as const;
searchInspectionsByInspectionDateSpanWithOperators.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 複数の内見を作業員とともに作成する
 *
 */
export const createInspectionsWithOperators = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/inspections/createInspectionsWithOperators`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createInspectionsWithOperators.method = "POST" as const;
createInspectionsWithOperators.authSchema = { type: "", header: "" } as const;
/**
 * 割り当てられている作業員一覧を取得する
 *
 */
export const findAssignedOperatorsByInspectionId = ({
  inspectionId,
}: {
  inspectionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/inspections/${inspectionId}/operators`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findAssignedOperatorsByInspectionId.method = "GET" as const;
findAssignedOperatorsByInspectionId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 作業員を内見に割り当てる
 *
 */
export const assignOperatorsToInspection = ({
  inspectionId,
}: {
  inspectionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/inspections/${inspectionId}/operators/assign`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

assignOperatorsToInspection.method = "POST" as const;
assignOperatorsToInspection.authSchema = { type: "", header: "" } as const;
/**
 * 複数作業員を内見に割り当てる
 *
 */
export const assignMultipleOperatorsToInspection = ({
  inspectionId,
}: {
  inspectionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/inspections/${inspectionId}/operators/assignMultiple`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

assignMultipleOperatorsToInspection.method = "POST" as const;
assignMultipleOperatorsToInspection.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 作業員を内見の割り当てから外す
 *
 */
export const unassignOperatorsToInspection = ({
  inspectionId,
}: {
  inspectionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/inspections/${inspectionId}/operators/unassign`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

unassignOperatorsToInspection.method = "POST" as const;
unassignOperatorsToInspection.authSchema = { type: "", header: "" } as const;
/**
 * 複数作業員を内見の割り当てから外す
 *
 */
export const unassignMultipleOperatorsToInspection = ({
  inspectionId,
}: {
  inspectionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/inspections/${inspectionId}/operators/unassignMultiple`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

unassignMultipleOperatorsToInspection.method = "POST" as const;
unassignMultipleOperatorsToInspection.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 内見のトラブルを取得する
 *
 */
export const findTroublesByInspectionId = ({
  inspectionId,
}: {
  inspectionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/inspections/${inspectionId}/troubles`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findTroublesByInspectionId.method = "GET" as const;
findTroublesByInspectionId.authSchema = { type: "", header: "" } as const;
/**
 * トラブルを取得する
 *
 */
export const findTroubleById = ({ troubleId }: { troubleId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/troubles/${troubleId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findTroubleById.method = "GET" as const;
findTroubleById.authSchema = { type: "", header: "" } as const;
/**
 * トラブルを解決にする
 *
 */
export const resolveTrouble = ({ troubleId }: { troubleId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/troubles/${troubleId}/resolve`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

resolveTrouble.method = "POST" as const;
resolveTrouble.authSchema = { type: "", header: "" } as const;
/**
 * トラブルを未解決にする
 *
 */
export const resolveUntrouble = ({ troubleId }: { troubleId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/troubles/${troubleId}/unresolve`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

resolveUntrouble.method = "POST" as const;
resolveUntrouble.authSchema = { type: "", header: "" } as const;
/**
 * トラブルを検索する
 *
 */
export const searchTroubles = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/troubles/search`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchTroubles.method = "POST" as const;
searchTroubles.authSchema = { type: "", header: "" } as const;
/**
 * 物件IDからトラブルを取得する
 *
 */
export const findTroublesByListingId = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/troubles/listingId/${listingId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findTroublesByListingId.method = "GET" as const;
findTroublesByListingId.authSchema = { type: "", header: "" } as const;
/**
 * トラブルを作成する
 *
 */
export const createTroubleManager = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/troubles/`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createTroubleManager.method = "POST" as const;
createTroubleManager.authSchema = { type: "", header: "" } as const;
/**
 * メールアドレスを使って作業員をインポートする
 *
 */
export const importOperatorByEmail = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/operators/import`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

importOperatorByEmail.method = "POST" as const;
importOperatorByEmail.authSchema = { type: "", header: "" } as const;
/**
 * 作業員を登録
 *
 */
export const createOperator = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/operators`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createOperator.method = "POST" as const;
createOperator.authSchema = { type: "", header: "" } as const;
/**
 * 作業員を削除
 *
 */
export const deleteOperator = ({ operatorId }: { operatorId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/operators/${operatorId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteOperator.method = "DELETE" as const;
deleteOperator.authSchema = { type: "", header: "" } as const;
/**
 * 作業員一覧を取得
 *
 */
export const listOperators = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspection/operators/list`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listOperators.method = "GET" as const;
listOperators.authSchema = { type: "", header: "" } as const;
/**
 * 内見を開始
 *
 */
export const startInspection = ({ inspectionId }: { inspectionId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/op_inspections/${inspectionId}/start`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

startInspection.method = "POST" as const;
startInspection.authSchema = { type: "", header: "" } as const;
/**
 * 内見完了を報告
 *
 */
export const reportInspection = ({
  inspectionId,
}: {
  inspectionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/op_inspections/${inspectionId}/report`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

reportInspection.method = "POST" as const;
reportInspection.authSchema = { type: "", header: "" } as const;
/**
 * 自分に割り当てられている内見の一覧を取得
 *
 */
export const listAssigned = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/op_inspections/assigned`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listAssigned.method = "POST" as const;
listAssigned.authSchema = { type: "", header: "" } as const;
/**
 * 内見で必要な備品一覧を取得
 *
 */
export const getEquipmentsByInspectionId = ({
  inspectionId,
}: {
  inspectionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/op_inspections/${inspectionId}/equipments`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getEquipmentsByInspectionId.method = "GET" as const;
getEquipmentsByInspectionId.authSchema = { type: "", header: "" } as const;
/**
 * 内見で備品画像をアップロード
 *
 */
export const uploadEquipmentImageByInspectionId = ({
  inspectionId,
  equipmentId,
}: {
  inspectionId: string;
  equipmentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/op_inspections/${inspectionId}/equipments/${equipmentId}/upload`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

uploadEquipmentImageByInspectionId.method = "POST" as const;
uploadEquipmentImageByInspectionId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 内見で備品画像のコメントを更新する
 *
 */
export const updateEquipmentImageByInspectionId = ({
  inspectionId,
  equipmentId,
  imageId,
}: {
  inspectionId: string;
  equipmentId: string;
  imageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/op_inspections/${inspectionId}/equipments/${equipmentId}/images/${imageId}/update`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateEquipmentImageByInspectionId.method = "PUT" as const;
updateEquipmentImageByInspectionId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 内見で備品画像の現在の画像を更新
 *
 */
export const saveCurrentImagesByInspectionId = ({
  inspectionId,
}: {
  inspectionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/op_inspections/${inspectionId}/current_images`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

saveCurrentImagesByInspectionId.method = "PUT" as const;
saveCurrentImagesByInspectionId.authSchema = { type: "", header: "" } as const;
/**
 * 内見で備品画像の現在の画像を取得
 *
 */
export const findCurrentImagesByInspectionId = ({
  inspectionId,
}: {
  inspectionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/op_inspections/${inspectionId}/current_images`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCurrentImagesByInspectionId.method = "GET" as const;
findCurrentImagesByInspectionId.authSchema = { type: "", header: "" } as const;
/**
 * 内見を取得する(Deprecated)
 *
 */
export const findInspectionById = ({
  inspectionId,
}: {
  inspectionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/op_inspections/${inspectionId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findInspectionById.method = "POST" as const;
findInspectionById.authSchema = { type: "", header: "" } as const;
/**
 * 内見を取得する
 *
 */
export const getInspectionById = ({
  inspectionId,
}: {
  inspectionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/op_inspections/${inspectionId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getInspectionById.method = "GET" as const;
getInspectionById.authSchema = { type: "", header: "" } as const;
/**
 * トラブルを作成する
 *
 */
export const createTroubleForOperator = ({
  inspectionId,
}: {
  inspectionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/op_inspections/${inspectionId}/troubles`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createTroubleForOperator.method = "POST" as const;
createTroubleForOperator.authSchema = { type: "", header: "" } as const;
/**
 * トラブルを完了する
 *
 */
export const resolveTroubleForOperator = ({
  inspectionId,
  troubleId,
}: {
  inspectionId: string;
  troubleId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/op_inspections/${inspectionId}/troubles/${troubleId}/resolve`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

resolveTroubleForOperator.method = "POST" as const;
resolveTroubleForOperator.authSchema = { type: "", header: "" } as const;
/**
 * 物件に紐づくトラブル一覧を取得する
 *
 */
export const getListingTroublesByInspectionIdForOperator = ({
  inspectionId,
}: {
  inspectionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/op_inspections/${inspectionId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getListingTroublesByInspectionIdForOperator.method = "GET" as const;
getListingTroublesByInspectionIdForOperator.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 画像のアップロードをスキップする
 *
 */
export const skipUploadEquipmentImageForOperator = ({
  inspectionId,
  equipmentId,
}: {
  inspectionId: string;
  equipmentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/op_inspections/${inspectionId}/${equipmentId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

skipUploadEquipmentImageForOperator.method = "GET" as const;
skipUploadEquipmentImageForOperator.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 画像からOCRテキストを取得する
 *
 */
export const inspectionOCRDetectText = ({
  equipmentId,
}: {
  equipmentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/equipmentImageOcr/equipment/${equipmentId}/detectText`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

inspectionOCRDetectText.method = "POST" as const;
inspectionOCRDetectText.authSchema = { type: "", header: "" } as const;
/**
 * 画像URIからOCRテキストを取得する
 *
 */
export const inspectionOCRDetectTextURI = ({
  equipmentId,
  equipmentImageId,
}: {
  equipmentId: string;
  equipmentImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/equipmentImageOcr/equipment/${equipmentId}/${equipmentImageId}/detectTextURI`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

inspectionOCRDetectTextURI.method = "GET" as const;
inspectionOCRDetectTextURI.authSchema = { type: "", header: "" } as const;
/**
 * 画像からDocumentTextを取得する
 *
 */
export const inspectionOCRDetectDocumentText = ({
  equipmentId,
}: {
  equipmentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/equipmentImageOcr/equipment/${equipmentId}/detectDocumentText`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

inspectionOCRDetectDocumentText.method = "POST" as const;
inspectionOCRDetectDocumentText.authSchema = { type: "", header: "" } as const;
/**
 * 画像URIからDocumentTextを取得する
 *
 */
export const inspectionOCRDetectDocumentTextURI = ({
  equipmentId,
  equipmentImageId,
}: {
  equipmentId: string;
  equipmentImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/equipmentImageOcr/equipment/${equipmentId}/${equipmentImageId}/detectDocumentTextURI`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

inspectionOCRDetectDocumentTextURI.method = "GET" as const;
inspectionOCRDetectDocumentTextURI.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 画像からラベルを取得する
 *
 */
export const inspectionOCRDetectLabels = ({
  equipmentId,
}: {
  equipmentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/equipmentImageOcr/equipment/${equipmentId}/detectLabels`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

inspectionOCRDetectLabels.method = "POST" as const;
inspectionOCRDetectLabels.authSchema = { type: "", header: "" } as const;
/**
 * 画像URIからラベルを取得する
 *
 */
export const inspectionOCRDetectLabelsURI = ({
  equipmentId,
  equipmentImageId,
}: {
  equipmentId: string;
  equipmentImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/equipmentImageOcr/equipment/${equipmentId}/${equipmentImageId}/detectLabelsURI`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

inspectionOCRDetectLabelsURI.method = "GET" as const;
inspectionOCRDetectLabelsURI.authSchema = { type: "", header: "" } as const;
/**
 * 画像をWebで検索する
 *
 */
export const inspectionOCRDetectWeb = ({
  equipmentId,
}: {
  equipmentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/equipmentImageOcr/equipment/${equipmentId}/detectWeb`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

inspectionOCRDetectWeb.method = "POST" as const;
inspectionOCRDetectWeb.authSchema = { type: "", header: "" } as const;
/**
 * 画像URIをWebで検索する
 *
 */
export const inspectionOCRDetectWebURI = ({
  equipmentId,
  equipmentImageId,
}: {
  equipmentId: string;
  equipmentImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/equipmentImageOcr/equipment/${equipmentId}/${equipmentImageId}/detectWebURI`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

inspectionOCRDetectWebURI.method = "GET" as const;
inspectionOCRDetectWebURI.authSchema = { type: "", header: "" } as const;
/**
 * PDFを取得する
 *
 */
export const findListingPDF = ({ listingId }: { listingId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/listing_pdf/${listingId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findListingPDF.method = "GET" as const;
findListingPDF.authSchema = { type: "", header: "" } as const;
/**
 * PDFをアップロードする
 *
 */
export const uploadListingPDF = ({ listingId }: { listingId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/listing_pdf/${listingId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

uploadListingPDF.method = "POST" as const;
uploadListingPDF.authSchema = { type: "", header: "" } as const;
/**
 * PDFを更新する
 *
 */
export const updateListingPDF = ({
  listingId,
  PDFId,
}: {
  listingId: string;
  PDFId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/listing_pdf/${listingId}/${PDFId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateListingPDF.method = "PUT" as const;
updateListingPDF.authSchema = { type: "", header: "" } as const;
/**
 * PDFを削除する
 *
 */
export const deleteListingPDF = ({
  listingId,
  PDFId,
}: {
  listingId: string;
  PDFId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_operator/listing_pdf/${listingId}/${PDFId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteListingPDF.method = "DELETE" as const;
deleteListingPDF.authSchema = { type: "", header: "" } as const;
/**
 * 管理画面で、PDFを取得する
 *
 */
export const findListingPDFManager = ({ listingId }: { listingId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_manager/listing_pdf/${listingId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findListingPDFManager.method = "GET" as const;
findListingPDFManager.authSchema = { type: "", header: "" } as const;
/**
 * 管理画面で、PDFをアップロードする
 *
 */
export const uploadListingPDFManager = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_manager/listing_pdf/${listingId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

uploadListingPDFManager.method = "POST" as const;
uploadListingPDFManager.authSchema = { type: "", header: "" } as const;
/**
 * 管理画面で、PDFを更新する
 *
 */
export const updateListingPDFManager = ({
  listingId,
  PDFId,
}: {
  listingId: string;
  PDFId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_manager/listing_pdf/${listingId}/${PDFId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateListingPDFManager.method = "PUT" as const;
updateListingPDFManager.authSchema = { type: "", header: "" } as const;
/**
 * 管理画面で、PDFを削除する
 *
 */
export const deleteListingPDFManager = ({
  listingId,
  PDFId,
}: {
  listingId: string;
  PDFId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspections_manager/listing_pdf/${listingId}/${PDFId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteListingPDFManager.method = "DELETE" as const;
deleteListingPDFManager.authSchema = { type: "", header: "" } as const;
/**
 * 内見を作成
 *
 */
export const createSchedule = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`schedule/schedules`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createSchedule.method = "POST" as const;
createSchedule.authSchema = { type: "", header: "" } as const;
/**
 * 内見を作成し、作業員を割り当てる
 *
 */
export const createScheduleWithOperators = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`schedule/schedules/createScheduleWithOperators`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

createScheduleWithOperators.method = "POST" as const;
createScheduleWithOperators.authSchema = { type: "", header: "" } as const;
/**
 * 内見を削除
 *
 */
export const deleteSchedule = ({ scheduleId }: { scheduleId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`schedule/schedules/${scheduleId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

deleteSchedule.method = "DELETE" as const;
deleteSchedule.authSchema = { type: "", header: "" } as const;
/**
 * 内見を更新
 *
 */
export const updateSchedule = ({ scheduleId }: { scheduleId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`schedule/schedules/${scheduleId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

updateSchedule.method = "POST" as const;
updateSchedule.authSchema = { type: "", header: "" } as const;
/**
 * 内見を取得
 *
 */
export const getSchedule = ({ scheduleId }: { scheduleId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`schedule/schedules/${scheduleId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getSchedule.method = "GET" as const;
getSchedule.authSchema = { type: "", header: "" } as const;
/**
 * 内見を無効化
 *
 */
export const invalidateSchedule = ({ scheduleId }: { scheduleId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`schedule/schedules/${scheduleId}/invalidate`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

invalidateSchedule.method = "POST" as const;
invalidateSchedule.authSchema = { type: "", header: "" } as const;
/**
 * 内見を有効化
 *
 */
export const validateSchedule = ({ scheduleId }: { scheduleId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`schedule/schedules/${scheduleId}/validate`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

validateSchedule.method = "POST" as const;
validateSchedule.authSchema = { type: "", header: "" } as const;
/**
 * 内見を日付で検索
 *
 */
export const searchSchedulesByScheduleDateSpan = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`schedule/schedules/search_by_Schedule_date`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchSchedulesByScheduleDateSpan.method = "POST" as const;
searchSchedulesByScheduleDateSpan.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 内見を日付で検索(operators付き)
 *
 */
export const searchSchedulesByScheduleDateSpanWithOperators = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`schedule/schedules/search_by_Schedule_date_operators`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchSchedulesByScheduleDateSpanWithOperators.method = "POST" as const;
searchSchedulesByScheduleDateSpanWithOperators.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 内見を日付で検索
 *
 */
export const searchSchedulesByScheduleTypeDateSpan = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`schedule/schedules/search_by_schedule_type_date_span`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchSchedulesByScheduleTypeDateSpan.method = "POST" as const;
searchSchedulesByScheduleTypeDateSpan.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 内見を日付で検索(operators付き)
 *
 */
export const searchSchedulesByScheduleTypeDateSpanWithOperators = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`schedule/schedules/search_by_schedule_type_date_span_operators`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

searchSchedulesByScheduleTypeDateSpanWithOperators.method = "POST" as const;
searchSchedulesByScheduleTypeDateSpanWithOperators.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourを検索
 *
 */
export const photoTourManagerFindById = ({
  photoTourListId,
}: {
  photoTourListId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/${photoTourListId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourManagerFindById.method = "GET" as const;
photoTourManagerFindById.authSchema = { type: "", header: "" } as const;
/**
 * photoTourをcompanyIdとscheduleTypeで検索
 *
 */
export const photoTourManagerFindByCompanyIdAndScheduleType = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/FindByCompanyIdAndScheduleType`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourManagerFindByCompanyIdAndScheduleType.method = "POST" as const;
photoTourManagerFindByCompanyIdAndScheduleType.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourを作成
 *
 */
export const photoTourManagerCreate = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourManagerCreate.method = "POST" as const;
photoTourManagerCreate.authSchema = { type: "", header: "" } as const;
/**
 * photoTourImageを作成
 *
 */
export const photoTourImageManagerCreate = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/photoTourImage`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourImageManagerCreate.method = "POST" as const;
photoTourImageManagerCreate.authSchema = { type: "", header: "" } as const;
/**
 * photoTourImageを作成
 *
 */
export const photoTourImageManagerCreateWithoutScheduleId = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/photoTourImageWithoutScheduleId`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourImageManagerCreateWithoutScheduleId.method = "POST" as const;
photoTourImageManagerCreateWithoutScheduleId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourImageを検索
 *
 */
export const photoTourImageManagerFindById = ({
  photoTourImageId,
}: {
  photoTourImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/photoTourImage/${photoTourImageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourImageManagerFindById.method = "GET" as const;
photoTourImageManagerFindById.authSchema = { type: "", header: "" } as const;
/**
 * photoTourImageを更新
 *
 */
export const photoTourImageManagerUpdate = ({
  photoTourImageId,
}: {
  photoTourImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/photoTourImage/${photoTourImageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourImageManagerUpdate.method = "PUT" as const;
photoTourImageManagerUpdate.authSchema = { type: "", header: "" } as const;
/**
 * photoTourImageを削除
 *
 */
export const photoTourImageManagerDelete = ({
  photoTourImageId,
}: {
  photoTourImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/photoTourImage/${photoTourImageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourImageManagerDelete.method = "DELETE" as const;
photoTourImageManagerDelete.authSchema = { type: "", header: "" } as const;
/**
 * photoTourImageをphotoTourListIdで検索
 *
 */
export const photoTourImageManagerFindByListingId = ({
  photoTourListId,
}: {
  photoTourListId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/photoTourImage/photoTourListId/${photoTourListId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourImageManagerFindByListingId.method = "GET" as const;
photoTourImageManagerFindByListingId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourImageをcompanyIdとscheduleTypeで検索
 *
 */
export const photoTourImageManagerFindByCompanyIdAndScheduleType = ({
  scheduleType,
}: {
  scheduleType: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/photoTourImage/scheduleType/${scheduleType}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourImageManagerFindByCompanyIdAndScheduleType.method = "GET" as const;
photoTourImageManagerFindByCompanyIdAndScheduleType.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourImageをlistingIdとscheduleTypeで検索
 *
 */
export const photoTourImageManagerFindByListingIdAndScheduleType = ({
  listingId,
  scheduleType,
}: {
  listingId: string;
  scheduleType: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourphotoTourImage/listingId/${listingId}/scheduleType/${scheduleType}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourImageManagerFindByListingIdAndScheduleType.method = "GET" as const;
photoTourImageManagerFindByListingIdAndScheduleType.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourItemを作成
 *
 */
export const photoTourItemManagerCreate = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/photoTourItem`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourItemManagerCreate.method = "POST" as const;
photoTourItemManagerCreate.authSchema = { type: "", header: "" } as const;
/**
 * photoTourItemを検索
 *
 */
export const photoTourItemManagerFindById = ({
  photoTourItemId,
}: {
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/photoTourItem/${photoTourItemId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourItemManagerFindById.method = "GET" as const;
photoTourItemManagerFindById.authSchema = { type: "", header: "" } as const;
/**
 * photoTourItemをcompanyIdで検索
 *
 */
export const photoTourItemManagerFindByCompanyId = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/photoTourItem`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourItemManagerFindByCompanyId.method = "GET" as const;
photoTourItemManagerFindByCompanyId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourItemをphotoTourListIdで検索
 *
 */
export const photoTourItemManagerFindByPhotoTourListId = ({
  photoTourListId,
}: {
  photoTourListId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/photoTourItem/FindByPhotoTourListId/${photoTourListId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourItemManagerFindByPhotoTourListId.method = "GET" as const;
photoTourItemManagerFindByPhotoTourListId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourItemを更新
 *
 */
export const photoTourItemManagerUpdate = ({
  photoTourItemId,
}: {
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/photoTourItem/${photoTourItemId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourItemManagerUpdate.method = "PATCH" as const;
photoTourItemManagerUpdate.authSchema = { type: "", header: "" } as const;
/**
 * photoTourItemとphotoTourListを関連付け
 *
 */
export const photoTourItemManagerCreateRelation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/photoTourItemAndPhotoTourList`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourItemManagerCreateRelation.method = "POST" as const;
photoTourItemManagerCreateRelation.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourItemを削除
 *
 */
export const photoTourItemManagerDelete = ({
  photoTourListId,
  photoTourItemId,
}: {
  photoTourListId: string;
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/photoTourItemAndPhotoTourList/photoTourListId/${photoTourListId}/photoTourItemId/${photoTourItemId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourItemManagerDelete.method = "DELETE" as const;
photoTourItemManagerDelete.authSchema = { type: "", header: "" } as const;
/**
 * photoTourItemとphotoTourListの関連付けを削除
 *
 */
export const photoTourItemManagerDeleteRelation = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/photoTourItemAndPhotoTourList`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourItemManagerDeleteRelation.method = "DELETE" as const;
photoTourItemManagerDeleteRelation.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourItemとphotoTourListを関連付けして作成
 *
 */
export const photoTourItemManagerCreateRelationWithPhotoTourList = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/photoTourItemAndPhotoTourList/CreateWithPhotoTourList`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourItemManagerCreateRelationWithPhotoTourList.method = "POST" as const;
photoTourItemManagerCreateRelationWithPhotoTourList.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourItemを有効化
 *
 */
export const photoTourItemManagerEnable = ({
  photoTourItemId,
}: {
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/phtoTourItemId/${photoTourItemId}/enable`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourItemManagerEnable.method = "PUT" as const;
photoTourItemManagerEnable.authSchema = { type: "", header: "" } as const;
/**
 * photoTourItemを無効化
 *
 */
export const photoTourItemManagerDisable = ({
  photoTourItemId,
}: {
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTour/phtoTourItemId/${photoTourItemId}/disable`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourItemManagerDisable.method = "PUT" as const;
photoTourItemManagerDisable.authSchema = { type: "", header: "" } as const;
/**
 * inspectionOperatorMovieを検索
 *
 */
export const inspectionOperatorMovieFindById = ({
  movieId,
}: {
  movieId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspectionOperatorMovie/${movieId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

inspectionOperatorMovieFindById.method = "GET" as const;
inspectionOperatorMovieFindById.authSchema = { type: "", header: "" } as const;
/**
 * inspectionOperatorMovieを作成
 *
 */
export const inspectionOperatorMovieCreate = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspectionOperatorMovie/listingId/${listingId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

inspectionOperatorMovieCreate.method = "POST" as const;
inspectionOperatorMovieCreate.authSchema = { type: "", header: "" } as const;
/**
 * inspectionOperatorMovieをストリームでアップロード
 *
 */
export const inspectionOperatorMovieStreamUpload = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspectionOperatorMovie/listingId/${listingId}/upload_stream`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

inspectionOperatorMovieStreamUpload.method = "POST" as const;
inspectionOperatorMovieStreamUpload.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * inspectionOperatorMovieを削除
 *
 */
export const inspectionOperatorMovieDelete = ({
  movieId,
}: {
  movieId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspectionOperatorMovie/${movieId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

inspectionOperatorMovieDelete.method = "DELETE" as const;
inspectionOperatorMovieDelete.authSchema = { type: "", header: "" } as const;
/**
 * inspectionOperatorMovieをlistingIdで検索
 *
 */
export const inspectionOperatorMovieFindByListingId = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspectionOperatorMovie/listingId/${listingId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

inspectionOperatorMovieFindByListingId.method = "GET" as const;
inspectionOperatorMovieFindByListingId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * inspectionOperatorMovieを作成(equipmentIdとlistingIdを指定)
 *
 */
export const uploadMovieWithEquipmentIdAndListingId = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspectionOperatorMovie/uploadWithEquipmentId`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

uploadMovieWithEquipmentIdAndListingId.method = "POST" as const;
uploadMovieWithEquipmentIdAndListingId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * inspectionOperatorMovieをバイナリでアップロード(inspectionIdを指定)
 *
 */
export const uploadMovieWithInspectionId = ({
  inspectionId,
  equipmentId,
}: {
  inspectionId: string;
  equipmentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspectionOperatorMovie/inspections/${inspectionId}/equipment/${equipmentId}/upload`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

uploadMovieWithInspectionId.method = "POST" as const;
uploadMovieWithInspectionId.authSchema = { type: "", header: "" } as const;
/**
 * inspectionOperatorMovieをequipmentIdで検索
 *
 */
export const inspectionOperatorMovieFindByEquipmentId = ({
  equipmentId,
}: {
  equipmentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspectionOperatorMovie/equipmentId/${equipmentId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

inspectionOperatorMovieFindByEquipmentId.method = "GET" as const;
inspectionOperatorMovieFindByEquipmentId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * inspectionOperatorMovieをlistingIdとequipmentIdで検索
 *
 */
export const inspectionOperatorMovieFindByListingIdAndEquipmentId = ({
  listingId,
  equipmentId,
}: {
  listingId: string;
  equipmentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`inspectionOperatorMovie/listingId/${listingId}/equipmentId/${equipmentId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

inspectionOperatorMovieFindByListingIdAndEquipmentId.method = "GET" as const;
inspectionOperatorMovieFindByListingIdAndEquipmentId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourItemをphotoTourListIdで検索
 *
 */
export const photoTourOperatorFindByPhotoTourListId = ({
  photoTourListId,
}: {
  photoTourListId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourItemOperator/${photoTourListId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourOperatorFindByPhotoTourListId.method = "GET" as const;
photoTourOperatorFindByPhotoTourListId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourItemをcompanyIdで検索
 *
 */
export const photoTourOperatorFindByCompanyId = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourItemOperator/findByCompanyId`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourOperatorFindByCompanyId.method = "GET" as const;
photoTourOperatorFindByCompanyId.authSchema = { type: "", header: "" } as const;
/**
 * photoTourOperatorをScheduleIdで検索
 *
 */
export const photoTourOperatorFindByScheduleId = ({
  scheduleId,
}: {
  scheduleId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourItemAndListOperator/scheduleId/${scheduleId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourOperatorFindByScheduleId.method = "GET" as const;
photoTourOperatorFindByScheduleId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourRefImageを検索
 *
 */
export const photoTourRefImageFindById = ({
  photoTourRefImageId,
}: {
  photoTourRefImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourRefImage/${photoTourRefImageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourRefImageFindById.method = "GET" as const;
photoTourRefImageFindById.authSchema = { type: "", header: "" } as const;
/**
 * photoTourRefImageを作成
 *
 */
export const photoTourRefImageCreate = ({
  photoTourItemId,
}: {
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourRefImage/photoTourItemId/${photoTourItemId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourRefImageCreate.method = "POST" as const;
photoTourRefImageCreate.authSchema = { type: "", header: "" } as const;
/**
 * photoTourRefImageを更新
 *
 */
export const photoTourRefImageUpdate = ({
  photoTourRefImageId,
}: {
  photoTourRefImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourRefImage/${photoTourRefImageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourRefImageUpdate.method = "PATCH" as const;
photoTourRefImageUpdate.authSchema = { type: "", header: "" } as const;
/**
 * photoTourRefImageを削除
 *
 */
export const photoTourRefImageDelete = ({
  photoTourRefImageId,
}: {
  photoTourRefImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourRefImage/${photoTourRefImageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourRefImageDelete.method = "DELETE" as const;
photoTourRefImageDelete.authSchema = { type: "", header: "" } as const;
/**
 * photoTourRefImageをphotoTourItemIdで検索
 *
 */
export const photoTourRefImageFindByPhotoTourItemId = ({
  photoTourItemId,
}: {
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourRefImage/photoTourItemId/${photoTourItemId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourRefImageFindByPhotoTourItemId.method = "GET" as const;
photoTourRefImageFindByPhotoTourItemId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 動画のラベルを解析するManager用
 *
 */
export const analyzeMovieLabelManager = ({ movieId }: { movieId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`movieAnalyzeManager/movieId/${movieId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

analyzeMovieLabelManager.method = "POST" as const;
analyzeMovieLabelManager.authSchema = { type: "", header: "" } as const;
/**
 * photoTourImageOperatorを検索
 *
 */
export const photoTourImageOperatorFindById = ({
  scheduleId,
  photoTourImageId,
}: {
  scheduleId: string;
  photoTourImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourImageOperatorscheduleId/${scheduleId}/photoTourImageId/${photoTourImageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourImageOperatorFindById.method = "GET" as const;
photoTourImageOperatorFindById.authSchema = { type: "", header: "" } as const;
/**
 * photoTourImageOperatorを作成
 *
 */
export const photoTourImageOperatorCreate = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourImageOperatorupload`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourImageOperatorCreate.method = "POST" as const;
photoTourImageOperatorCreate.authSchema = { type: "", header: "" } as const;
/**
 * photoTourImageOperatorをphotoTourItemIdで検索
 *
 */
export const photoTourImageOperatorFindByPhotoTourItemId = ({
  scheduleId,
  photoTourItemId,
}: {
  scheduleId: string;
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourImageOperator/scheduleId/${scheduleId}/photoTourItemId/${photoTourItemId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourImageOperatorFindByPhotoTourItemId.method = "GET" as const;
photoTourImageOperatorFindByPhotoTourItemId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourListOperatorを検索
 *
 */
export const photoTourListOperatorFindByCompanyIdAndScheduleType = ({
  scheduleId,
}: {
  scheduleId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourListOperator/scheduleId/${scheduleId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourListOperatorFindByCompanyIdAndScheduleType.method = "GET" as const;
photoTourListOperatorFindByCompanyIdAndScheduleType.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourListOperatorをScheduleIdで検索
 *
 */
export const photoTourListOperatorFindByScheduleId = ({
  scheduleId,
}: {
  scheduleId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourListOperator/scheduleId/${scheduleId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourListOperatorFindByScheduleId.method = "GET" as const;
photoTourListOperatorFindByScheduleId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourListOperatorを検索
 *
 */
export const photoTourListOperatorFindById = ({
  scheduleId,
  photoTourListId,
}: {
  scheduleId: string;
  photoTourListId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourListOperator/scheduleId/${scheduleId}/photoTourListId/${photoTourListId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourListOperatorFindById.method = "GET" as const;
photoTourListOperatorFindById.authSchema = { type: "", header: "" } as const;
/**
 * photoTourItemAndListManagerをScheduleIdで検索
 *
 */
export const photoTourItemAndListManagerFindByScheduleId = ({
  scheduleId,
}: {
  scheduleId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourItemAndListManager/scheduleId/${scheduleId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourItemAndListManagerFindByScheduleId.method = "GET" as const;
photoTourItemAndListManagerFindByScheduleId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourItemAndListManagerをScheduleTypeで検索
 *
 */
export const photoTourItemAndListManagerFindByScheduleType = ({
  scheduleType,
}: {
  scheduleType: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourItemAndListManager/scheduleType/${scheduleType}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourItemAndListManagerFindByScheduleType.method = "GET" as const;
photoTourItemAndListManagerFindByScheduleType.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 画像からOCRテキストを取得する
 *
 */
export const photoTourImageOperatorOCRDetectText = ({
  photoTourItemId,
}: {
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`operator/photoTourImageOcr/photoTourItemId/${photoTourItemId}/detectText`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourImageOperatorOCRDetectText.method = "POST" as const;
photoTourImageOperatorOCRDetectText.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 画像からDocumentTextを取得する
 *
 */
export const photoTourImageOperatorOCRDetectDocumentText = ({
  photoTourItemId,
}: {
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`operator/photoTourImageOcr/photoTourItemId/${photoTourItemId}/detectDocumentText`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourImageOperatorOCRDetectDocumentText.method = "POST" as const;
photoTourImageOperatorOCRDetectDocumentText.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 画像からOCRテキストを取得する
 *
 */
export const photoTourImageManagerOcrDetectText = ({
  photoTourItemId,
}: {
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`manager/photoTourImageOcr/photoTourItemId/${photoTourItemId}/detectText`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourImageManagerOcrDetectText.method = "POST" as const;
photoTourImageManagerOcrDetectText.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 画像からDocumentTextを取得する
 *
 */
export const photoTourImageManagerOcrDetectDocumentText = ({
  photoTourItemId,
}: {
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`manager/photoTourImageOcr/photoTourItemId/${photoTourItemId}/detectDocumentText`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourImageManagerOcrDetectDocumentText.method = "POST" as const;
photoTourImageManagerOcrDetectDocumentText.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * photoTourItemAndMovieOperatorをphotoTourItemIdとscheduleIdで検索
 *
 */
export const photoTourItemAndMovieOperatorFindByPhotoTourItemIdAndScheduleId =
  ({
    scheduleId,
    photoTourItemId,
  }: {
    scheduleId: string;
    photoTourItemId: string;
  }) => {
    const __root = root();
    const __queries = Object.entries({})
      .filter(([_, value]) => {
        return value !== undefined;
      })
      .map(([key, value]) => {
        return `${key}=${value}`;
      })
      .join("&");
    const __path = `${__root}/${`photoTourItemAndMovieOperator/scheduleId/${scheduleId}/photoTourItemId/${photoTourItemId}`}`;
    return __queries ? `${__path}?${__queries}` : __path;
  };

photoTourItemAndMovieOperatorFindByPhotoTourItemIdAndScheduleId.method =
  "GET" as const;
photoTourItemAndMovieOperatorFindByPhotoTourItemIdAndScheduleId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * @deprecated photoTourItemAndMovieOperator動画を作成
 *
 */
export const photoTourItemAndMovieOperatorCreate = ({
  scheduleId,
  photoTourItemId,
}: {
  scheduleId: string;
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourItemAndMovieOperator/scheduleId/${scheduleId}/photoTourItemId/${photoTourItemId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourItemAndMovieOperatorCreate.method = "POST" as const;
photoTourItemAndMovieOperatorCreate.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * @deprecated photoTourItemAndMovieOperatorWithScheduleId動画を作成
 *
 */
export const photoTourItemAndMovieOperatorCreateWithScheduleId = ({
  scheduleId,
  photoTourItemId,
}: {
  scheduleId: string;
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourItemAndMovieOperator/scheduleId/${scheduleId}/photoTourItemId/${photoTourItemId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourItemAndMovieOperatorCreateWithScheduleId.method = "POST" as const;
photoTourItemAndMovieOperatorCreateWithScheduleId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 動画をphotoTourItemIdとlistingIdで検索
 *
 */
export const photoTourItemAndMovieManagerFindByPhotoTourItemIdAndListingId = ({
  scheduleType,
  photoTourItemId,
  listingId,
}: {
  scheduleType: string;
  photoTourItemId: string;
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourItemAndMovieManager/scheduleType/${scheduleType}/photoTourItemId/${photoTourItemId}/listingId/${listingId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourItemAndMovieManagerFindByPhotoTourItemIdAndListingId.method =
  "GET" as const;
photoTourItemAndMovieManagerFindByPhotoTourItemIdAndListingId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 動画をphotoTourItemIdとlistingIdで削除
 *
 */
export const photoTourItemAndMovieManagerDeleteByPhotoTourItemIdAndListingId =
  ({ movieId }: { movieId: string }) => {
    const __root = root();
    const __queries = Object.entries({})
      .filter(([_, value]) => {
        return value !== undefined;
      })
      .map(([key, value]) => {
        return `${key}=${value}`;
      })
      .join("&");
    const __path = `${__root}/${`photoTourItemAndMovieManager/movieId/${movieId}`}`;
    return __queries ? `${__path}?${__queries}` : __path;
  };

photoTourItemAndMovieManagerDeleteByPhotoTourItemIdAndListingId.method =
  "DELETE" as const;
photoTourItemAndMovieManagerDeleteByPhotoTourItemIdAndListingId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 動画をphotoTourItemIdとlistingIdで作成
 *
 */
export const photoTourItemAndMovieManagerUploadPhotoTourItemMovie = ({
  listingId,
  photoTourItemId,
}: {
  listingId: string;
  photoTourItemId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourItemAndMovieManager/listingId/${listingId}/photoTourItemId/${photoTourItemId}/uploadPhotoTourItemMovie`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourItemAndMovieManagerUploadPhotoTourItemMovie.method = "POST" as const;
photoTourItemAndMovieManagerUploadPhotoTourItemMovie.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 動画をlistingIdで検索
 *
 */
export const photoTourItemAndMovieManagerFindByListingId = ({
  scheduleType,
  listingId,
}: {
  scheduleType: string;
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`photoTourItemAndMovieManager/scheduleType/${scheduleType}/listingId/${listingId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

photoTourItemAndMovieManagerFindByListingId.method = "GET" as const;
photoTourItemAndMovieManagerFindByListingId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * equipmentRefImageOperatorをequipmentIdで検索
 *
 */
export const equipmentRefImageOperatorFindByEquipmentId = ({
  inspectionId,
  equipmentId,
}: {
  inspectionId: string;
  equipmentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`equipmentRefImageOperator/inspectionId/${inspectionId}/equipmentId/${equipmentId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

equipmentRefImageOperatorFindByEquipmentId.method = "GET" as const;
equipmentRefImageOperatorFindByEquipmentId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * equipmentRefMovieOperatorをequipmentIdで検索
 *
 */
export const equipmentRefMovieOperatorFindByEquipmentId = ({
  inspectionId,
  equipmentId,
}: {
  inspectionId: string;
  equipmentId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`equipmentRefMovieOperator/inspectionId/${inspectionId}/equipmentId/${equipmentId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

equipmentRefMovieOperatorFindByEquipmentId.method = "GET" as const;
equipmentRefMovieOperatorFindByEquipmentId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * commonAreaを作成
 *
 */
export const commonAreaCreate = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`commonArea`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

commonAreaCreate.method = "POST" as const;
commonAreaCreate.authSchema = { type: "", header: "" } as const;
/**
 * commonAreaを更新
 *
 */
export const commonAreaUpdate = ({
  commonAreaId,
}: {
  commonAreaId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`commonArea/commonAreas/${commonAreaId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

commonAreaUpdate.method = "PUT" as const;
commonAreaUpdate.authSchema = { type: "", header: "" } as const;
/**
 * commonAreaを削除
 *
 */
export const commonAreaDelete = ({
  commonAreaId,
}: {
  commonAreaId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`commonArea/commonAreas/${commonAreaId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

commonAreaDelete.method = "DELETE" as const;
commonAreaDelete.authSchema = { type: "", header: "" } as const;
/**
 * 共用部名で検索する
 *
 */
export const commonAreaSearchByName = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`commonArea/search_by_name`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

commonAreaSearchByName.method = "POST" as const;
commonAreaSearchByName.authSchema = { type: "", header: "" } as const;
/**
 * commonAreaをcommonAreaIdで検索
 *
 */
export const commonAreaFindById = ({
  commonAreaId,
}: {
  commonAreaId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`commonArea/commonAreas/${commonAreaId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

commonAreaFindById.method = "GET" as const;
commonAreaFindById.authSchema = { type: "", header: "" } as const;
/**
 * 共用部の清掃画像一覧を取得する
 *
 */
export const cleaningImageFindByCommonAreaId = ({
  commonAreaId,
}: {
  commonAreaId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`commonArea/${commonAreaId}/cleaningImages/`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

cleaningImageFindByCommonAreaId.method = "GET" as const;
cleaningImageFindByCommonAreaId.authSchema = { type: "", header: "" } as const;
/**
 * commonAreaをpropertyIdで検索
 *
 */
export const commonAreaFindByPropertyId = ({
  propertyId,
}: {
  propertyId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`commonArea/findByPropertyId/propertyId/${propertyId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

commonAreaFindByPropertyId.method = "GET" as const;
commonAreaFindByPropertyId.authSchema = { type: "", header: "" } as const;
/**
 * commonAreaをcompanyIdで検索
 *
 */
export const commonAreaFindByCompanyId = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`commonArea/findByCompanyId`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

commonAreaFindByCompanyId.method = "GET" as const;
commonAreaFindByCompanyId.authSchema = { type: "", header: "" } as const;
/**
 * commonAreaRefImageをcommonAreaIdで検索
 *
 */
export const commonAreaRefImageFindByCommonAreaId = ({
  commonAreaId,
}: {
  commonAreaId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`commonAreaRefImage/commonAreaId/${commonAreaId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

commonAreaRefImageFindByCommonAreaId.method = "GET" as const;
commonAreaRefImageFindByCommonAreaId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * commonAreaRefImageをcommonAreaRefImageIdで検索
 *
 */
export const commonAreaRefImageFindById = ({
  commonAreaRefImageId,
}: {
  commonAreaRefImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`commonAreaRefImage/findById/commonAreaRefImageId/${commonAreaRefImageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

commonAreaRefImageFindById.method = "GET" as const;
commonAreaRefImageFindById.authSchema = { type: "", header: "" } as const;
/**
 * commonAreaRefImageを作成
 *
 */
export const commonAreaRefImageCreate = ({
  commonAreaId,
}: {
  commonAreaId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`commonAreaRefImage/commonAreaId/${commonAreaId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

commonAreaRefImageCreate.method = "POST" as const;
commonAreaRefImageCreate.authSchema = { type: "", header: "" } as const;
/**
 * commonAreaRefImageを削除
 *
 */
export const commonAreaRefImageDelete = ({
  commonAreaRefImageId,
}: {
  commonAreaRefImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`commonAreaRefImage/commonAreaRefImageId/${commonAreaRefImageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

commonAreaRefImageDelete.method = "DELETE" as const;
commonAreaRefImageDelete.authSchema = { type: "", header: "" } as const;
/**
 * commonAreaRefImageを更新
 *
 */
export const commonAreaRefImageUpdate = ({
  commonAreaRefImageId,
}: {
  commonAreaRefImageId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`commonAreaRefImage/commonAreaRefImageId/${commonAreaRefImageId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

commonAreaRefImageUpdate.method = "PUT" as const;
commonAreaRefImageUpdate.authSchema = { type: "", header: "" } as const;
/**
 * 物件プレゼンテーションスキーマを作成
 *
 */
export const listingPresentationSchemaCreate = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listingPresentationSchema/`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingPresentationSchemaCreate.method = "POST" as const;
listingPresentationSchemaCreate.authSchema = { type: "", header: "" } as const;
/**
 * 物件プレゼンテーションスキーマを更新
 *
 */
export const listingPresentationSchemaUpdate = ({
  schemaId,
}: {
  schemaId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listingPresentationSchema/schemas/${schemaId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingPresentationSchemaUpdate.method = "PATCH" as const;
listingPresentationSchemaUpdate.authSchema = { type: "", header: "" } as const;
/**
 * カンパニーIDに紐づいた物件プレゼンテーションスキーマを取得
 *
 */
export const listingPresentationSchemaFindByCompanyId = ({
  companyId,
}: {
  companyId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listingPresentationSchema/companies/${companyId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingPresentationSchemaFindByCompanyId.method = "GET" as const;
listingPresentationSchemaFindByCompanyId.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 物件プレゼンテーションスキーマを削除
 *
 */
export const listingPresentationSchemaDelete = ({
  schemaId,
}: {
  schemaId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listingPresentationSchema/schemas/${schemaId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingPresentationSchemaDelete.method = "DELETE" as const;
listingPresentationSchemaDelete.authSchema = { type: "", header: "" } as const;
/**
 * 物件プレゼンテーションスキーマブロックを作成
 *
 */
export const listingPresentationSchemaBlockCreate = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listingPresentationSchemaBlock/`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingPresentationSchemaBlockCreate.method = "POST" as const;
listingPresentationSchemaBlockCreate.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 物件プレゼンテーションスキーマブロックを更新
 *
 */
export const listingPresentationSchemaBlockUpdate = ({
  blockId,
}: {
  blockId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listingPresentationSchemaBlock/blocks/${blockId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingPresentationSchemaBlockUpdate.method = "PATCH" as const;
listingPresentationSchemaBlockUpdate.authSchema = {
  type: "",
  header: "",
} as const;
/**
 * 物件プレゼンテーションスキーマIDに紐づいた物件プレゼンテーションスキーマブロックを取得
 *
 */
export const listingPresentationSchemaBlockFindBySchemaId = ({
  schemaId,
}: {
  schemaId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`listingPresentationSchemaBlock/schemas/${schemaId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

listingPresentationSchemaBlockFindBySchemaId.method = "GET" as const;
listingPresentationSchemaBlockFindBySchemaId.authSchema = {
  type: "",
  header: "",
} as const;
export const m2mCore_v1 = {
  healthCheck,
  listPrefectures,
  listCities,
  findMyListings,
  searchListings,
  listingSearchByName,
  transferListing,
  listingDetail,
  updateListing,
  deleteListing,
  listingGetAutoPricingSetting,
  listingUpdateAutoPricingSetting,
  listingAutoPricingPricing,
  listingAutoPricingPricingWithoutSave,
  listingRenotify,
  listingRenotifyReservations,
  listingSetMatsuriListingInfo,
  listingGetCleaningInfo,
  listingUpdateCleaningInfo,
  listingGetListingInfoForDepositManagement,
  listingGetInCheckListings,
  findListingEquipmentImagesById,
  uploadEquipmentImage,
  updateEquipmentImage,
  uploadListingImage,
  findListingImagesById,
  findListingCleaningImagesById,
  uploadListingMovie,
  findListingMoviesById,
  updateListingMovie,
  deleteListingMovie,
  findListingImageOrdersById,
  updateListingImageOrders,
  detachListingMainImage,
  detachListingLayoutImage,
  searchListingCommodityPlacements,
  findBigQueryAllTablesById,
  findlistingCalendarEventById,
  findBigQueryAllTablesByIdAsSystemUser,
  listingRenotifyReservationsAsSystemUser,
  findListingByAirbnbId,
  deleteListingImage,
  updateListingImage,
  propertyCreate,
  propertyEdit,
  propertyDelete,
  propertyList,
  propertyGet,
  propertyListRooms,
  getPropertyCalendarItemsOnlySingleRoomTypesById,
  notificationReservationOta,
  autoPricingPolicyCreate,
  autoPricingPolicyList,
  autoPricingPolicyGet,
  autoPricingPolicyUpdate,
  autoPricingPolicyDelete,
  ownerCreate,
  findAllMyOwners,
  loginForOwner,
  uploadFiles,
  getFileListsByOwner,
  deleteFile,
  createOwnerHistory,
  findOwnerHistoryById,
  findAllOwnerHistoryByIdByListingId,
  findAllOwnerHistoryByOwnerId,
  updateOwnerHistory,
  deleteOwnerHistory,
  findOwnerByListingId,
  findMyRoomTypes,
  createRoomTypeManually,
  transferRoomTypeReservation,
  createRoomTypeProprietary,
  createRoomTypeProprietaryForOwner,
  associateToOTA,
  syncOtaRoomTypeRelation,
  detachFromOTA,
  deleteRoomTypeProprietary,
  deleteRoomTypeProprietaryForOwner,
  editRoomTypeManually,
  editRoomTypeProprietary,
  editRoomTypeProprietaryForOwner,
  updateOTAReservation,
  getRoomType,
  updateRoomType,
  deleteRoomType,
  findKeysByRoomTypeId,
  getRoomCalendar,
  getListingCalendar,
  getMinpakuYearStockByListingId,
  getRoomTypeCalendar,
  searchCalendarOperationLogByRoomTypeId,
  getMinpakuYearStockByRoomTypeId,
  getRoomCalendarByTemporalToken,
  getCalendarAvailabilitiesByNameAndSpan,
  findMinpakuYearStocksByTargetYear,
  searchListingCalendarsByTemporalTokenForOwner,
  createReservationManuallyForOwner,
  updateReservationManuallyForOwner,
  createUserWithCompanyRelation,
  getExternalAvailabilitiyCalendarDelegationByNameAndSpan,
  searchRoomTypeCalendarStocks,
  importListing,
  importReservations,
  importOperationType,
  assignFailedReservations,
  reservationDetail,
  searchByOtaInfo,
  searchReservation,
  updateStayType,
  updateReservationByOtaInfo,
  registerMatsuriReservationInfo,
  getMatsuriReservationInfo,
  resetManualReservationOtaId,
  getRoomTypeNameByReservationId,
  updateReservationAccepted,
  findReservationAssignmentLogById,
  cancelReservation,
  forceUnassignedReservation,
  showAirbnbCalendar,
  showAirbnbCalendarByRoomType,
  applyAirbnbCalendar,
  getLatestOccupancyCalendar,
  exportAchievement,
  exportReservationDetails,
  saveMinpakuAndMonthlySales,
  findSalesByReservationId,
  registerAdditionalSales,
  registerInquiryReservationRelation,
  getInquiryReservationRelation,
  registerListingIdListingManualIdRelation,
  getListingIdListingManualIdRelation,
  searchAirbnbPayouts,
  searchAirbnbStatements,
  createAirbnbBankStatementRelation,
  deleteAirbnbBankStatementRelation,
  createAirbnbStatementCoreReservationRelation,
  deleteAirbnbStatementCoreReservationRelation,
  importAirbnbCsv,
  importAirbnbChargeFromSystems,
  searchBankStatements,
  importMizuhoBankCsv,
  generateTemporalToken,
  revokeTemporalToken,
  generateTemporalTokenForOwner,
  findCleaningInvoiceById,
  saveCleaningInvoice,
  cleaningInvoiceMonthlyReportForOwner,
  cleaningInvoiceMonthlyReportForCompany,
  searchProprietaries,
  searchTemporaryBlockProprietaries,
  getProprietary,
  getOtaManualTypes,
  putOtaManualType,
  deleteOtaManualType,
  exportTjaReservationCsv,
  findListingMonthBopByListingIdAndSpan,
  saveListingMonthBop,
  saveAllListingMonthBops,
  settleListingMonthBops,
  exportAllListingBops,
  exportLeaseDetails,
  createLease,
  findLeaseById,
  updateLease,
  deleteLease,
  readLeaseNotification,
  findNeedActionLeases,
  findEventHistoriesByResourceKey,
  findEventHistoriesByReservationId,
  findEventHistoriesByProprietaryId,
  findEventHistoriesByListingId,
  devonlyCreateOtaReservation,
  createCommodity,
  searchCommodity,
  getCommodityById,
  getCommodityStockById,
  updateCommodity,
  uploadCommodityImage,
  getCommodityImagesByCommodityId,
  searchCommodityOrderEventsByCommodityId,
  getCommodityPlacementEventsByCommodityId,
  deleteCommodityImage,
  createCommodityLocation,
  updateCommodityLocation,
  saveCommodityLocation,
  getCommodityLocationsByCommodityId,
  searchCommodityLocationsByLocation,
  createCommodityOrderEvent,
  updateCommodityOrderEvent,
  noSyncUpdateCommodityOrderEvent,
  findCommodityOrderEventById,
  searchCommodityOrderEvents,
  deleteCommodityOrderEvent,
  searchStoreKeeperCommodityOrderEvents,
  updateStoreKeeperCommodityOrderEvent,
  searchStoreKeeperCommodityPlacementEvents,
  updateStoreKeeperCommodityPlacementEvent,
  saveCarryInDelegation,
  getCarryInDelegationByListingId,
  getCarryInDelegatedListingById,
  getStoreKeeperDelegatedListings,
  getCarryInDelegatedListings,
  searchCarryInCommodityPlacementEvents,
  updateCarryInCommodityPlacementEvent,
  createCommodityPlacementEvent,
  searchCommodityPlacementEvents,
  instructShipmentGroup,
  deleteShipmentGroup,
  updateCommodityPlacementEvent,
  findCommodityPlacementEventById,
  deleteCommodityPlacementEvent,
  collectCommodityPlacementEvent,
  splitAndCollectCommodityPlacementEvent,
  splitAndTrashCommodityPlacementEvent,
  trashCommodityPlacementEvent,
  saveAllCommodityOrderPlacementRelations,
  deleteCommodityOrderPlacementRelation,
  findCommodityOrderPlacementRelationByOrderId,
  findCommodityOrderPlacementRelationByPlacementId,
  searchAvailableOrders,
  createStorage,
  findStoragesByCompanyId,
  updateStorage,
  findInventoryEventHistoryByResourceKey,
  findInventoryEventHistoryByCommodityOrderEvent,
  findInventoryEventHistoryByCommodityPlacementEvent,
  findInventoryEventHistoryByCommodityLocationRelation,
  createStockAdjustment,
  findStockAdjustmentById,
  findStockAdjustmentByCommodityId,
  updateStockAdjustment,
  findSupplierListByCompanyId,
  saveSupplierList,
  createOrderGroup,
  updateOrderGroup,
  findOrderGroupById,
  searchOrderGroup,
  findOrderGroupDocumentByOrderGroupId,
  uploadOrderGroupDocument,
  updateOrderGroupDocument,
  deleteOrderGroupDocument,
  createOrderGroupRelation,
  deleteOrderGroupRelation,
  findOrderGroupRelationByGroupId,
  findOrderGroupRelationByOrderId,
  createEquipment,
  findEquipmentsByCompanyId,
  findEquipmentById,
  updateEquipment,
  createEquipmentRefImage,
  updateEquipmentRefImage,
  deleteEquipmentRefImage,
  findEquipmentRefImage,
  deleteEquipmentImage,
  ocrDetectTextURI,
  ocrDetectDocumentText,
  ocrDetectDocumentTextURI,
  ocrDetectLabelsURI,
  ocrDetectWeb,
  ocrDetectWebURI,
  createEquipmentRefMovie,
  findEquipmentRefMovie,
  deleteEquipmentRefMovie,
  findCurrentImageIdsByListingId,
  findCurrentImagesByListingId,
  updateCurrentImagesByListingId,
  createInspection,
  createInspectionWithOperators,
  deleteInspection,
  updateInspection,
  getInspection,
  invalidateInspection,
  validateInspection,
  searchInspectionsByInspectionDateSpan,
  searchInspectionsByInspectionDateSpanWithOperators,
  createInspectionsWithOperators,
  findAssignedOperatorsByInspectionId,
  assignOperatorsToInspection,
  assignMultipleOperatorsToInspection,
  unassignOperatorsToInspection,
  unassignMultipleOperatorsToInspection,
  findTroublesByInspectionId,
  findTroubleById,
  resolveTrouble,
  resolveUntrouble,
  searchTroubles,
  findTroublesByListingId,
  createTroubleManager,
  importOperatorByEmail,
  createOperator,
  deleteOperator,
  listOperators,
  startInspection,
  reportInspection,
  listAssigned,
  getEquipmentsByInspectionId,
  uploadEquipmentImageByInspectionId,
  updateEquipmentImageByInspectionId,
  saveCurrentImagesByInspectionId,
  findCurrentImagesByInspectionId,
  findInspectionById,
  getInspectionById,
  createTroubleForOperator,
  resolveTroubleForOperator,
  getListingTroublesByInspectionIdForOperator,
  skipUploadEquipmentImageForOperator,
  inspectionOCRDetectText,
  inspectionOCRDetectTextURI,
  inspectionOCRDetectDocumentText,
  inspectionOCRDetectDocumentTextURI,
  inspectionOCRDetectLabels,
  inspectionOCRDetectLabelsURI,
  inspectionOCRDetectWeb,
  inspectionOCRDetectWebURI,
  findListingPDF,
  uploadListingPDF,
  updateListingPDF,
  deleteListingPDF,
  findListingPDFManager,
  uploadListingPDFManager,
  updateListingPDFManager,
  deleteListingPDFManager,
  createSchedule,
  createScheduleWithOperators,
  deleteSchedule,
  updateSchedule,
  getSchedule,
  invalidateSchedule,
  validateSchedule,
  searchSchedulesByScheduleDateSpan,
  searchSchedulesByScheduleDateSpanWithOperators,
  searchSchedulesByScheduleTypeDateSpan,
  searchSchedulesByScheduleTypeDateSpanWithOperators,
  photoTourManagerFindById,
  photoTourManagerFindByCompanyIdAndScheduleType,
  photoTourManagerCreate,
  photoTourImageManagerCreate,
  photoTourImageManagerCreateWithoutScheduleId,
  photoTourImageManagerFindById,
  photoTourImageManagerUpdate,
  photoTourImageManagerDelete,
  photoTourImageManagerFindByListingId,
  photoTourImageManagerFindByCompanyIdAndScheduleType,
  photoTourImageManagerFindByListingIdAndScheduleType,
  photoTourItemManagerCreate,
  photoTourItemManagerFindById,
  photoTourItemManagerFindByCompanyId,
  photoTourItemManagerFindByPhotoTourListId,
  photoTourItemManagerUpdate,
  photoTourItemManagerCreateRelation,
  photoTourItemManagerDelete,
  photoTourItemManagerDeleteRelation,
  photoTourItemManagerCreateRelationWithPhotoTourList,
  photoTourItemManagerEnable,
  photoTourItemManagerDisable,
  inspectionOperatorMovieFindById,
  inspectionOperatorMovieCreate,
  inspectionOperatorMovieStreamUpload,
  inspectionOperatorMovieDelete,
  inspectionOperatorMovieFindByListingId,
  uploadMovieWithEquipmentIdAndListingId,
  uploadMovieWithInspectionId,
  inspectionOperatorMovieFindByEquipmentId,
  inspectionOperatorMovieFindByListingIdAndEquipmentId,
  photoTourOperatorFindByPhotoTourListId,
  photoTourOperatorFindByCompanyId,
  photoTourOperatorFindByScheduleId,
  photoTourRefImageFindById,
  photoTourRefImageCreate,
  photoTourRefImageUpdate,
  photoTourRefImageDelete,
  photoTourRefImageFindByPhotoTourItemId,
  analyzeMovieLabelManager,
  photoTourImageOperatorFindById,
  photoTourImageOperatorCreate,
  photoTourImageOperatorFindByPhotoTourItemId,
  photoTourListOperatorFindByCompanyIdAndScheduleType,
  photoTourListOperatorFindByScheduleId,
  photoTourListOperatorFindById,
  photoTourItemAndListManagerFindByScheduleId,
  photoTourItemAndListManagerFindByScheduleType,
  photoTourImageOperatorOCRDetectText,
  photoTourImageOperatorOCRDetectDocumentText,
  photoTourImageManagerOcrDetectText,
  photoTourImageManagerOcrDetectDocumentText,
  photoTourItemAndMovieOperatorFindByPhotoTourItemIdAndScheduleId,
  photoTourItemAndMovieOperatorCreate,
  photoTourItemAndMovieOperatorCreateWithScheduleId,
  photoTourItemAndMovieManagerFindByPhotoTourItemIdAndListingId,
  photoTourItemAndMovieManagerDeleteByPhotoTourItemIdAndListingId,
  photoTourItemAndMovieManagerUploadPhotoTourItemMovie,
  photoTourItemAndMovieManagerFindByListingId,
  equipmentRefImageOperatorFindByEquipmentId,
  equipmentRefMovieOperatorFindByEquipmentId,
  commonAreaCreate,
  commonAreaUpdate,
  commonAreaDelete,
  commonAreaSearchByName,
  commonAreaFindById,
  cleaningImageFindByCommonAreaId,
  commonAreaFindByPropertyId,
  commonAreaFindByCompanyId,
  commonAreaRefImageFindByCommonAreaId,
  commonAreaRefImageFindById,
  commonAreaRefImageCreate,
  commonAreaRefImageDelete,
  commonAreaRefImageUpdate,
  listingPresentationSchemaCreate,
  listingPresentationSchemaUpdate,
  listingPresentationSchemaFindByCompanyId,
  listingPresentationSchemaDelete,
  listingPresentationSchemaBlockCreate,
  listingPresentationSchemaBlockUpdate,
  listingPresentationSchemaBlockFindBySchemaId,
};
