import {
    PhotoTour,
    PhotoTourImage,
    PhotoTourItem,
    PhotoTourItemSampleImage,
    PhotoTourMovie
} from "../domain/photoTour"
import { bearerFetcher, useAuthBearerFetch } from "matsuri-hooks"
import { m2mCleaning_managerV3 } from "../endpoints/m2m-cleaning.manager-v3"
import { m2mCleaning_managerV4 } from "@/endpoints/m2m-cleaning.manager-v4"
import { useMemo } from "react"

export const usePhotoTours = (
    token: string,
    params: {
        // 無効化されているツアーを除外するかどうか
        excludeDisabled: boolean
    } = { excludeDisabled: false }
) => {
    const fetched = useAuthBearerFetch<PhotoTour[]>(
        token,
        m2mCleaning_managerV3.findPhotoToursByCompanyId({ companyId: "1" }),
        {}
    )

    const data = useMemo(() => {
        if (!fetched.data) {
            return fetched.data
        }

        if (params.excludeDisabled) {
            return fetched.data.filter(tour => !tour.disabled)
        }

        return fetched.data
    }, [fetched.data, params.excludeDisabled])

    return { ...fetched, data }
}

export const usePhotoTourById = (
    token: string,
    photoTourId: string | undefined
) => {
    return useAuthBearerFetch<{
        tour: PhotoTour
        items: PhotoTourItem[]
    }>(
        token,
        photoTourId
            ? m2mCleaning_managerV3.findPhotoTourItemsByPhotoTourId({
                  photoTourId
              })
            : null,
        {}
    )
}

export const requestCreatePhotoTourItem = (
    token: string,
    photoTourId: string,
    input: {
        photoTourId: string
        title: string
        description: string
    }
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.createPhotoTourItem({
            photoTourId
        }),
        { method: "POST", body: JSON.stringify(input) }
    )
}

export const requestUpdatePhotoTourItem = (
    token: string,
    photoTourId: string,
    photoTourItemId: string,
    input: {
        photoTourId: string
        photoTourItemId: string
        title: string
        description: string
    }
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.updatePhotoTourItem({
            photoTourId,
            photoTourItemId
        }),
        { method: "PUT", body: JSON.stringify(input) }
    )
}

export const usePhotoTourImages = (
    token: string,
    cleaningId: string | undefined
) => {
    return useAuthBearerFetch<{ images: PhotoTourImage[] }>(
        token,
        cleaningId
            ? m2mCleaning_managerV3.findPhotoTourImagesByCleaningId({
                  cleaningId
              })
            : null,
        {}
    )
}

export const requestDeletePhotoTourImage = (
    token: string,
    photoTourImageId: string
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV4.deletePhotoTourImage({
            photoTourImageId
        }),
        { method: "DELETE" }
    )
}

export const usePhotoTourSampleImages = (
    token: string,
    photoTourItemId: string | undefined
) => {
    return useAuthBearerFetch<PhotoTourItemSampleImage[]>(
        token,
        photoTourItemId
            ? m2mCleaning_managerV3.findPhotoTourItemSampleImagesByPhotoTourItemId(
                  {
                      photoTourItemId
                  }
              )
            : null,
        {}
    )
}

export const requestUploadPhotoTourSampleImage = (
    token: string,
    photoTourItemId: string,
    input: {
        description: string
        image: string
    }
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.createPhotoTourItemSampleImage({
            photoTourItemId
        }),
        { method: "POST", body: JSON.stringify(input) }
    )
}

export const requestUpdatePhotoTourSampleImage = (
    token: string,
    imageId: string,
    input: {
        description: string
    }
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.updatePhotoTourItemSampleImage({
            photoTourItemSampleImageId: imageId
        }),
        { method: "PUT", body: JSON.stringify(input) }
    )
}

export const requestDeletePhotoTourSampleImage = (
    token: string,
    imageId: string
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.deletePhotoTourItemSampleImage({
            photoTourItemSampleImageId: imageId
        }),
        { method: "DELETE" }
    )
}

export const requestDeletePhotoTourItem = (
    token: string,
    photoTourId: string,
    photoTourItemId: string
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.deletePhotoTourItem({
            photoTourId,
            photoTourItemId
        }),
        { method: "DELETE" }
    )
}

export const requestCreatePhotoTour = (
    token: string,
    input: {
        title: string
        description: string
    }
) => {
    return bearerFetcher(token, m2mCleaning_managerV3.createPhotoTour(), {
        method: "POST",
        body: JSON.stringify(input)
    })
}

export const requestUpdatePhotoTour = (
    token: string,
    photoTourId: string,
    input: {
        title: string
        description: string
    }
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.updatePhotoTour({ photoTourId }),
        {
            method: "PUT",
            body: JSON.stringify(input)
        }
    )
}

export const requestEnablePhotoTour = (token: string, photoTourId: string) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV4.enablePhotoTour({ photoTourId }),
        { method: "PUT" }
    )
}

export const requestDisablePhotoTour = (token: string, photoTourId: string) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV4.diablePhotoTour({ photoTourId }),
        { method: "PUT" }
    )
}

export const requestEnablePhotoTourItem = (
    token: string,
    photoTourId: string,
    photoTourItemId: string
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV4.enablePhotoTourItem({
            photoTourId,
            photoTourItemId
        }),
        { method: "PUT" }
    )
}

export const requestDisablePhotoTourItem = (
    token: string,
    photoTourId: string,
    photoTourItemId: string
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV4.diablePhotoTourItem({
            photoTourId,
            photoTourItemId
        }),
        { method: "PUT" }
    )
}

export const usePhotoTourMovieByCleaningId = (
    token: string,
    cleaningId: string | undefined
) => {
    return useAuthBearerFetch<{ movies: PhotoTourMovie[] }>(
        token,
        cleaningId
            ? m2mCleaning_managerV4.findPhotoTourMovie({
                  cleaningId
              })
            : null,
        {}
    )
}

export const requestDeletePhotoTourMovie = (
    token: string,
    photoTourMovieId: string
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV4.deletePhotoTourMovie({
            photoTourMovieId
        }),
        { method: "DELETE" }
    )
}

export const requestReorderPhotoTourItems = (
    token: string,
    input: {
        photoTourItemIds: string[]
    }
) => {
    const endpoint = m2mCleaning_managerV4.reindexPhotoTourItems

    return bearerFetcher(token, endpoint(), {
        method: endpoint.method,
        body: JSON.stringify(input)
    })
}
