import {
    Button,
    Paper,
    Popper,
    TextField,
    Typography,
    useAlert
} from "matsuri-ui"
import { copyToClipboard } from "@/helpers/copyToClipboard"
import { css } from "@emotion/react"
import { useQRCode } from "next-qrcode"
import { useState } from "react"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import QrCodeIcon from "@mui/icons-material/QrCode"

export const ShowCleanerPageQrCode = () => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const open = Boolean(anchorEl)
    const handleClick = ({ currentTarget }: React.SyntheticEvent) => {
        setAnchorEl(prev => (prev ? null : currentTarget))
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const { Canvas: RenderQrCode } = useQRCode()

    const href =
        process.env.NODE_ENV === "production"
            ? "https://cleaner-cleaning.m2msystems.cloud"
            : "https://dev--m2m-cleaning-cleaner-front.netlify.app"

    const { addAlert } = useAlert()

    return (
        <div>
            <Button rounded icon={<QrCodeIcon />} onClick={handleClick} />
            <Popper
                anchorEl={anchorEl}
                open={open}
                onClickOutside={handleClose}
            >
                <Paper
                    css={css`
                        padding: 16px;
                    `}
                >
                    <div
                        css={css`
                            display: grid;
                            gap: 24px;
                        `}
                    >
                        <Typography>清掃員向け画面はこちら</Typography>

                        <div
                            css={css`
                                display: grid;
                                justify-content: center;
                            `}
                        >
                            <RenderQrCode
                                text={href}
                                options={{
                                    errorCorrectionLevel: "M",
                                    margin: 0
                                }}
                            />
                        </div>

                        <div
                            css={css`
                                display: grid;
                                grid-template-columns: 1fr auto;
                                gap: 8px;
                                align-items: center;
                            `}
                        >
                            <TextField value={href} readOnly />

                            <Button
                                icon={<ContentCopyIcon />}
                                onClick={() => {
                                    void copyToClipboard(href, {
                                        onSuccess: () => {
                                            addAlert(
                                                "クリップボードにコピーしました",
                                                {
                                                    severity: "success"
                                                }
                                            )
                                        }
                                    })
                                }}
                            />
                        </div>
                    </div>
                </Paper>
            </Popper>
        </div>
    )
}
