import { PlaceName } from "./type"

// placementTypeに関わらず、nameだけでソートする
export const sortPlaceName = (a: PlaceName, b: PlaceName) => {
    const aName =
        a.placementType === "listing" ? a.listingName : a.commonAreaName
    const bName =
        b.placementType === "listing" ? b.listingName : b.commonAreaName

    return aName.localeCompare(bName)
}
