import { PhotoTour } from "@/domain/photoTour"
import { TourChip } from "./TourChip"
import { Typography } from "matsuri-ui"
import { css } from "@emotion/react"
import { theme } from "@/Theme"

export interface CleaningListItem {
    cleaningId: string
    placementName: string
    photoTour?: PhotoTour
}

interface CleaningListProps {
    cleaningList: CleaningListItem[]
}

const styles = {
    placement: css`
        display: flex;
        gap: 12px;
        align-items: center;
        line-height: 24px;

        ::before {
            display: inline-block;
            width: 16px;
            height: 16px;
            content: "";
            background-color: ${theme.palette.intentions.primary.light};
            border-radius: 50%;
        }
    `
}

export const CleaningList = ({ cleaningList }: CleaningListProps) => {
    return (
        <>
            {cleaningList.map(({ cleaningId, placementName, photoTour }) => (
                <span
                    key={cleaningId}
                    css={css`
                        display: flex;
                        gap: 8px;
                        align-items: center;
                    `}
                >
                    <Typography variant="h4" css={styles.placement}>
                        {placementName}
                    </Typography>
                    <Typography variant="caption">
                        {photoTour ? <TourChip photoTour={photoTour} /> : null}
                    </Typography>
                </span>
            ))}
        </>
    )
}
