import { Button, Column, Modal, Typography } from "matsuri-ui"
import { useAccountInfoCtx } from "@/hooks/useAccountInfo"
import { useAuth } from "@/hooks/useAuth"
import React from "react"

export const AuthorityErrorDialog = () => {
    const { jwtPayload: accountInfo } = useAccountInfoCtx()
    const { logout: requestLogout } = useAuth()

    const isError = React.useMemo<boolean>(() => {
        return !(
            accountInfo?.authority === "Manager" ||
            accountInfo?.authority === "Admin"
        )
    }, [accountInfo?.authority])

    return (
        <Modal
            open={isError}
            backdrop
            body={
                <Column>
                    <Typography>
                        権限がありません。詳しくは管理者にお問い合わせください。
                    </Typography>
                </Column>
            }
            footer={<Button onClick={requestLogout}>ログアウト</Button>}
        />
    )
}
