import {
    Button,
    ErrorBoundaryHandleError,
    ErrorBoundary as OriginalErrorBoundary
} from "matsuri-ui"
import { css } from "@emotion/react"
import React, { ErrorInfo, useCallback, useState } from "react"

interface ErrorBoundaryProps {
    children: React.ReactNode
}

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
    const [error, setError] = useState<{
        error: Error
        errorInfo: ErrorInfo
    }>()

    const onError: ErrorBoundaryHandleError = useCallback(args => {
        setError(args)
        console.error(args.error)
    }, [])

    return (
        <OriginalErrorBoundary
            onError={onError}
            fallback={
                <div
                    css={css`
                        max-width: 1024px;
                        padding: 48px 5vw 0px;
                        margin: auto;
                        & > * {
                            margin-bottom: 16px;
                        }
                    `}
                >
                    <header>
                        <h2>エラーが発生しました</h2>
                    </header>
                    <div>
                        <p>
                            サポートが必要な場合は、以下のデータをコピーして開発者に発生状況などと共に送付してください。
                        </p>
                    </div>
                    <textarea
                        css={css`
                            width: 100%;
                            min-height: 200px;
                            border: 1px solid black;
                        `}
                        value={JSON.stringify({
                            // jsのerrorオブジェクトはシリアライズできないので、文字列化
                            error: `${error?.error}`,
                            errorInfo: error?.errorInfo
                        })}
                        readOnly
                    />
                    <div>
                        <Button
                            css={css`
                                float: right;
                            `}
                            variant="filled"
                            color="primary"
                            onClick={() => {
                                window.location.reload()
                            }}
                        >
                            元のページに戻る
                        </Button>
                    </div>
                </div>
            }
        >
            {children}
        </OriginalErrorBoundary>
    )
}
