import { SelectField, SelectFieldProps } from "matsuri-ui"
import { requestSearchListingByName } from "../hooks/useListings"
import { useAuth } from "../hooks/useAuth"

export const ListingSelectField = (
    props: Omit<SelectFieldProps, "loadOptions">
) => {
    const { token } = useAuth()

    return (
        <SelectField
            loadOptions={async ({ query, selectedOptions }) => {
                if (query === "") {
                    return selectedOptions
                }

                const { data } = await requestSearchListingByName(
                    token,
                    query,
                    { page: 1, pageSize: 20 }
                )
                return data?.data?.map(listing => ({
                    label: listing.listing.name,
                    value: listing.listing.id
                }))
            }}
            {...props}
        />
    )
}
