export const blobToString = (blob: Blob): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
            resolve(reader.result as string)
        }
        reader.onerror = ev => {
            reject(ev)
        }

        reader.readAsDataURL(blob)
    })
