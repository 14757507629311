export type StringTypes<T> = {
    [K in keyof T]: string
}

type FieldNames<T extends string> = {
    [K in T]: K
}

export const genFieldNames = <T extends string>(arr: T[]): FieldNames<T> => {
    return Object.fromEntries(arr.map(e => [e, e])) as FieldNames<T>
}

type FormDataObjectWithoutFile<T extends string> = {
    [K in T]: string
}

export const genFieldProps = <T extends string>(arr: T[]) => {
    return {
        fieldNames: Object.fromEntries(arr.map(e => [e, e])) as FieldNames<T>,
        getFormData: (element: HTMLFormElement) => {
            return Object.fromEntries(
                new FormData(element)
            ) as FormDataObjectWithoutFile<T>
        }
    }
}
