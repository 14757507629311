/* eslint-disable */
/**
 * A function that returns the URL part common to the endpoints.
 */
export const root = () => {
  let __root = "";

  if (process.env.RUNTIME_ENV === "local") {
    __root = "http://localhost:7999";
  }

  if (process.env.RUNTIME_ENV === "development") {
    __root = "https://api-cleaning.dev.m2msystems.cloud";
  }

  if (process.env.RUNTIME_ENV === "production") {
    __root = "https://api-cleaning.m2msystems.cloud";
  }

  return __root;
};
/**
 * 清掃員が単価承認リクエストを拒否
 *
 */
export const rejectPendingApprovalRequest = ({
  cleaningId,
}: {
  cleaningId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/approvalRequest/rejected/${cleaningId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

rejectPendingApprovalRequest.method = "POST" as const;
/**
 * TTLockのキー番号を取得する
 *
 */
export const getTTLockKeyNumberByListingId = ({
  listingId,
}: {
  listingId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/ttlock/keyNumber/${listingId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getTTLockKeyNumberByListingId.method = "GET" as const;
/**
 * 清掃員が単価承認リクエストを取得する
 *
 */
export const getReportedApprovalRequestsByDays = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/approval_requests/reported/days`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getReportedApprovalRequestsByDays.method = "POST" as const;
/**
 * 清掃員が日付で清掃を取得する
 *
 */
export const findCleaningsByDates = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/cleaner/cleanings/search/dates`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

findCleaningsByDates.method = "POST" as const;
/**
 * 清掃員用の通知を取得する
 *
 */
export const getCompanyNotificationForCleaner = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`v4/company_notification/cleaner`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

getCompanyNotificationForCleaner.method = "GET" as const;
export const m2mCleaning_cleanerV4 = {
  rejectPendingApprovalRequest,
  getTTLockKeyNumberByListingId,
  getReportedApprovalRequestsByDays,
  findCleaningsByDates,
  getCompanyNotificationForCleaner,
};
