import { Link } from "react-router-dom"
import { PlaceName } from "./type"
import { TableUIRenderCellProps, Typography } from "matsuri-ui"

export const PlacementNameCell = ({
    cell: { value }
}: TableUIRenderCellProps<{ placeName: PlaceName }, PlaceName>) => {
    return value.placementType === "commonArea" ? (
        <Typography>{value.commonAreaName}</Typography>
    ) : (
        <Link to={`/listings/${value.listingId}`}>{value.listingName}</Link>
    )
}
