import { SelectField, SelectFieldProps } from "matsuri-ui"
import { requestSearchCommonAreaByName } from "../hooks/useCommonAreas"
import { useAuth } from "m2m-components/storage/useM2mAuth"

export const CommonAreaSelectField = (
    props: Omit<SelectFieldProps, "loadOptions">
) => {
    const { token } = useAuth()
    return (
        <SelectField
            loadOptions={async ({ query, selectedOptions }) => {
                if (query === "") {
                    return selectedOptions
                }

                const { data } = await requestSearchCommonAreaByName(token, {
                    nameQuery: query,
                    pageSize: 20,
                    page: 0
                })
                if (data?.length === 0) {
                    return selectedOptions
                }

                return data?.map(d => ({
                    label: d.name,
                    value: d.id
                }))
            }}
            {...props}
        />
    )
}
