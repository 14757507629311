import { createContext, useCallback, useContext, useMemo } from "react"
import { fetcher, useAuthFetch } from "matsuri-hooks"
import {
    findMySubscriptions,
    m2mNotifications_v1,
    updateNotificationStatus
} from "@/endpoints/m2m-notifications.v1"
import { useAuth } from "../hooks/useAuth"

const serviceId = "m2m-cleaning"

export type NotificationType = "createTrouble"

interface Notification {
    createdAt: number
    id: string
    message: string
    notificationType: NotificationType
    resourceId: string
    serviceId: typeof serviceId
    status: "unread" | "read"
    userId: string
}

interface ResourceSubscription {
    resourceSelectType: "specific" | "any"
    resourceId: string
}

type SubscriptionStatus = "default" | "subscribed" | "denied"

interface Subscription {
    userId: string
    serviceId: typeof serviceId
    notificationType: NotificationType
    resourceSubscription: ResourceSubscription
    status: SubscriptionStatus
}

export const requestUpdateNotificationStatusToRead = async (
    token: string,
    id: string
) => {
    await fetcher(
        updateNotificationStatus({
            id
        }),
        {
            method: "PUT",
            token,
            body: JSON.stringify({ status: "read" })
        }
    )
}

const NotificationPermissionContext = createContext<
    | {
          checkPermission: (
              notificationType: NotificationType
          ) => SubscriptionStatus | undefined
          refetch: () => void
      }
    | undefined
>(undefined)

const NotificationsContext = createContext<
    | { notifications: Notification[]; isAllRead: boolean; refetch: () => void }
    | undefined
>(undefined)

interface NotificationsProviderProps {
    children: React.ReactNode
}

export const NotificationsProvider = (props: NotificationsProviderProps) => {
    const { token } = useAuth()

    const { data: subscriptions, refetch: refetchSubscriptions } = useAuthFetch<
        Subscription[]
    >(token, findMySubscriptions(), {})
    const checkPermission = useCallback(
        (notificationType: NotificationType) => {
            const subscription = subscriptions?.find(item => {
                return (
                    item.serviceId === serviceId &&
                    item.notificationType === notificationType &&
                    item.resourceSubscription.resourceSelectType === "any"
                )
            })

            return subscription?.status
        },
        [subscriptions]
    )

    const {
        data: notifications,
        error,
        refetch: refetchNotifications
    } = useAuthFetch<{ data: Notification[] }>(
        token,
        m2mNotifications_v1.findNotificationsByServiceId({ id: serviceId }),
        { swrConfig: { refreshInterval: 1000 * 60 * 5 } }
    )
    if (error) {
        // エラーが出てもユーザーは特に困らないので、ユーザーに伝える必要はないが、監視は必要。
        console.error(error)
    }

    const permissionContextState = useMemo(() => {
        return {
            checkPermission,
            refetch: refetchSubscriptions
        }
    }, [checkPermission, refetchSubscriptions])

    const notificationsContextState = useMemo(() => {
        return {
            notifications: notifications?.data ?? [],
            isAllRead:
                notifications?.data.every(n => n.status === "read") ?? true,
            refetch: refetchNotifications
        }
    }, [refetchNotifications, notifications?.data])

    return (
        <NotificationPermissionContext.Provider value={permissionContextState}>
            <NotificationsContext.Provider value={notificationsContextState}>
                {props.children}
            </NotificationsContext.Provider>
        </NotificationPermissionContext.Provider>
    )
}

export const useNotifications = () => {
    const ctx = useContext(NotificationsContext)
    if (!ctx) {
        throw new Error("NotificatoinProvider is not found")
    }

    return ctx
}

export const requestSubscribe = async (
    token: string,
    input: Pick<Subscription, "notificationType" | "resourceSubscription">
) => {
    const { error } = await fetcher(m2mNotifications_v1.saveSubscription(), {
        method: "POST",
        token,
        body: JSON.stringify({
            ...input,
            serviceId,
            status: "subscribed"
        })
    })
    if (error) {
        console.error(error)
    }
}

export const requestUnSubscribe = async (
    token: string,
    input: Pick<Subscription, "notificationType" | "resourceSubscription">
) => {
    const { error } = await fetcher(m2mNotifications_v1.saveSubscription(), {
        method: "POST",
        token,
        body: JSON.stringify({
            ...input,
            serviceId,
            status: "denied"
        })
    })
    if (error) {
        console.error(error)
    }
}

export const useNotificationPermission = () => {
    const ctx = useContext(NotificationPermissionContext)
    if (!ctx) {
        throw new Error("NotificatoinProvider is not found")
    }

    return ctx
}
