import {
    Button,
    Checkbox,
    Modal,
    ModalProps,
    MultipleTextField,
    Portal,
    Typography,
    useAlert,
    useModal
} from "matsuri-ui"
import { StringTypes, genFieldNames } from "../helpers/fieldTypes"
import { css } from "@emotion/react"
import { datadogLogs } from "@datadog/browser-logs"
import { fetcher, useFetch } from "matsuri-hooks"
import { guestFormGet, submissionCreate } from "../endpoints/matsuri-forms.v1"
import { useAuth } from "m2m-components/storage/useM2mAuth"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import FeedbackIcon from "@mui/icons-material/Feedback"

const FORM_ID =
    process.env.NODE_ENV === "production"
        ? "01GN97RRNRV5DY7FQJGN1WS9C1"
        : "01GN95ANF4S9V41KX04JTJXZ0Z"

const parseJWT = <T extends Record<string, unknown>>(
    jwt: string
): T | undefined => {
    const splitted = jwt.split(".")
    if (splitted.length !== 3) return

    const [, payload] = splitted
    const data = JSON.parse(atob(payload))
    if (!data) return

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data
}

const getRandomString = () => {
    return Math.random().toString(36).slice(-8)
}

const fieldNames = genFieldNames(["id", "userId", "pathname", "type", "body"])

type FieldTypes = StringTypes<typeof fieldNames>

interface QuestionLooseType {
    id: string
    /**
     * 扱いやすくするためにtitleをfieldNamesのkeyに一致させる
     */
    title: keyof FieldTypes
    description: string
    value: {
        text?: {
            inputType: string
            maxLength: number
        }
        multilineText?: {
            maxLength: number
        }
        select?: {
            options: [
                {
                    label: string
                    value: string
                },
                {
                    label: string
                    value: string
                }
            ]
            maxAnswer: number
        }
    }
    required: boolean
}
interface GuestFormGetResponse {
    content: {
        formId: string
        revision: string
        questions: QuestionLooseType[]
    }
}

const useFeedbackFormDetails = () => {
    return useFetch<GuestFormGetResponse>(guestFormGet({ formId: FORM_ID }), {
        method: guestFormGet.method
    })
}

const requestCreateSubmission = (
    formId: string,
    revision: string,
    values: {
        questionId: string
        value: string | string[]
    }[]
) => {
    const endpoint = submissionCreate
    return fetcher(endpoint(), {
        method: endpoint.method,
        body: JSON.stringify({
            formId,
            revision,
            values
        })
    })
}

type FeedbackProviderModalProps = Partial<ModalProps>

const FeedbackProviderModal = (props: FeedbackProviderModalProps) => {
    const location = useLocation()

    const id = getRandomString()

    const { token } = useAuth()

    const userId = parseJWT<{ userId: string }>(token)?.userId

    const [status, setStatus] = useState<"started" | "sending" | "completed">(
        "started"
    )

    useEffect(() => {
        if (props.open === false) {
            setStatus("started")
        }
    }, [props.open])

    const { data } = useFeedbackFormDetails()

    const { throwAlert } = useAlert()

    return (
        <Modal
            backdrop
            width={600}
            maxWidth={600}
            header={<Typography variant="h3">フィードバックを送信</Typography>}
            body={
                status === "completed" ? (
                    <div
                        css={css`
                            display: grid;
                            gap: 32px;
                        `}
                    >
                        <Typography>
                            レポートを送信しました。ありがとうございます。
                        </Typography>
                        <Typography
                            color="textSecondary"
                            variant="caption"
                            as="p"
                        >
                            お送りいただいたフィードバックは、問題の解決とサービスの改善に役立てさせていただきます。
                        </Typography>
                        <Button
                            color="primary"
                            variant="filled"
                            onClick={() => {
                                props.onClose?.()
                            }}
                        >
                            閉じる
                        </Button>
                    </div>
                ) : (
                    <form
                        css={css`
                            display: grid;
                            gap: 24px;
                        `}
                        onSubmit={async event => {
                            if (data === undefined) {
                                return
                            }
                            event.preventDefault()
                            setStatus("sending")

                            const formData = new FormData(event.currentTarget)
                            const input = Object.fromEntries(
                                formData
                            ) as FieldTypes

                            const { error } = await requestCreateSubmission(
                                data.content.formId,
                                data.content.revision,
                                data.content.questions.map(question => {
                                    const isSelectType =
                                        question.value.select !== null
                                    return {
                                        questionId: question.id,
                                        value: isSelectType
                                            ? [input[question.title]]
                                            : input[question.title]
                                    }
                                })
                            )

                            throwAlert(error, {
                                ignoreSuccess: true,
                                errorMessage: {
                                    reason: "サーバー側のエラーの可能性があります。",
                                    action: "しばらく時間を空けて試すか、開発チームへお問い合わせください。",
                                    happend:
                                        "フィードバックの送信に失敗しました。"
                                },
                                onSuccess: () => {
                                    datadogLogs.logger.info(
                                        `Feedback: ${input.id} by ${input.userId}`,
                                        { input }
                                    )
                                },
                                onError: () => {
                                    datadogLogs.logger.error(
                                        `Failed Feedback: ${input.id} by ${input.userId}`,
                                        { input, error }
                                    )
                                }
                            })

                            window.setTimeout(() => {
                                setStatus("completed")
                            }, 2000)
                        }}
                    >
                        <input
                            name={fieldNames.id}
                            type="hidden"
                            value={id}
                            readOnly
                        />

                        <input
                            name={fieldNames.userId}
                            type="hidden"
                            value={userId}
                            readOnly
                        />
                        <input
                            name={fieldNames.pathname}
                            type="hidden"
                            value={location.pathname}
                            readOnly
                        />
                        <div
                            css={css`
                                display: flex;
                                gap: 16px;
                            `}
                        >
                            {data?.content.questions
                                .find(question => question.title === "type")
                                ?.value.select?.options.map(option => {
                                    return (
                                        <Checkbox
                                            defaultChecked
                                            type="radio"
                                            name={fieldNames.type}
                                            value={option.value}
                                            key={option.value}
                                        >
                                            {option.label}
                                        </Checkbox>
                                    )
                                })}
                        </div>
                        <MultipleTextField
                            label="問題や提案について説明してください"
                            required
                            name={fieldNames.body}
                            rows={6}
                            helperText="現在開いているページのURLは自動的に送信されるため記載不要です"
                        />
                        <div>
                            <Button
                                type="submit"
                                color="primary"
                                variant="filled"
                                disabled={status === "sending"}
                                fullWidth
                            >
                                送信
                            </Button>
                            <Typography
                                variant="caption"
                                css={css`
                                    float: right;
                                `}
                            >
                                {status === "sending" ? "送信中..." : undefined}
                                &nbsp;
                            </Typography>
                        </div>
                    </form>
                )
            }
            {...props}
        />
    )
}

interface FeedbackProviderProps {
    children: React.ReactNode
}

export const FeedbackProvider = ({ children }: FeedbackProviderProps) => {
    const [open] = useModal(FeedbackProviderModal, {
        enableClickOutside: true,
        disableClose: false
    })
    return (
        <div>
            {children}
            <Portal>
                <Button
                    css={css`
                        position: fixed;
                        right: 16px;
                        bottom: 16px;
                    `}
                    onClick={() => {
                        open()
                    }}
                    type="button"
                    icon={<FeedbackIcon />}
                    color="primary"
                    variant="filled"
                    rounded
                />
            </Portal>
        </div>
    )
}
