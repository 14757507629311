import { Comment } from "@/domain/comment"
import { Company } from "@/domain/company"
import { Listing } from "@/domain/listing"
import { Trouble } from "@/domain/trouble"
import { User } from "../domain/user"
import { bearerFetcher, useAuthBearerFetch } from "matsuri-hooks"
import { m2mCleaning_managerV3 } from "@/endpoints/m2m-cleaning.manager-v3"
import { m2mCleaning_managerV4 } from "../endpoints/m2m-cleaning.manager-v4"
import { useAuth } from "./useAuth"

export const useTroubles = (cleaningId: string) => {
    const { token } = useAuth()
    return useAuthBearerFetch<Trouble[]>(
        token,
        m2mCleaning_managerV3.listTroubles({ cleaningId }),
        {}
    )
}

export const requestResolveTrouble = (token: string, troubleId: string) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.resolveTrouble({ troubleId }),
        {
            method: "POST"
        }
    )
}

export const requestUnresolveTrouble = (token: string, troubleId: string) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.unresolveTrouble({ troubleId }),
        {
            method: "POST"
        }
    )
}

type TroubleId = string

export const useSearchTroubles = (
    token: string,
    isDelegating: boolean,
    isResolved: boolean,
    pageSize: number,
    page: number
) => {
    return useAuthBearerFetch<{
        troubles: Trouble[]
        troubleIdListing: Record<TroubleId, Listing>
        total: number
    }>(token, m2mCleaning_managerV3.searchTroubles(), {
        method: "POST",
        body: JSON.stringify({
            isDelegating,
            isResolved,
            page,
            pageSize
        })
    })
}

export const useTrouble = (token: string, troubleId: string) => {
    return useAuthBearerFetch<Trouble>(
        token,
        m2mCleaning_managerV3.findTroubleById({ troubleId }),
        {}
    )
}

export const useTroubleComments = (
    token: string,
    troubleId: string,
    options?: { refreshInterval: number }
) => {
    return useAuthBearerFetch<{
        data: Comment[]
        userIdMap: Record<string, User>
        companyIdMap: Record<string, Company>
    }>(token, m2mCleaning_managerV3.listCommentsByTroubleId({ troubleId }), {
        swrConfig: {
            refreshInterval: options?.refreshInterval ?? 0
        }
    })
}

export const requestCreateTroubleComment = (
    token: string,
    troubleId: string,
    content: string
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.createTroubleComment({ troubleId }),
        {
            body: JSON.stringify({ content }),
            method: "POST"
        }
    )
}

export const requestUpdateTroubleComment = (
    token: string,
    troubleId: string,
    commentId: string,
    content: string
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.updateTroubleComment({ troubleId }),
        {
            body: JSON.stringify({ content, id: commentId }),
            method: "PUT"
        }
    )
}

export const requestDeleteTroubleComment = (
    token: string,
    troubleId: string,
    commentId: string
) => {
    return bearerFetcher(
        token,
        m2mCleaning_managerV3.deleteTroubleComment({ troubleId, commentId }),
        {
            method: "DELETE"
        }
    )
}

export const requestCreateTroubleWithPlacement = (
    token: string,
    input: {
        listingId?: string
        commonAreaId?: string
        description: string
        pictures: string[]
    }
) => {
    return bearerFetcher<{ id: string }>(
        token,
        m2mCleaning_managerV4.createOperationManagerTrouble(),
        {
            method: "POST",
            body: JSON.stringify({
                listingId: input.listingId ?? null,
                commonAreaId: input.commonAreaId ?? null,
                description: input.description,
                pictures: input.pictures
            })
        }
    )
}
