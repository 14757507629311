import { Cleaner } from "@/domain/user"
import { bearerFetcher } from "matsuri-hooks"
import { m2mCleaning } from "../endpoints/m2m-cleaning"
import { useAuthBearerFetch } from "matsuri-hooks"
import { useMemo } from "react"

interface CreateUserInput {
    name: string
    email: string
    authority: string
    password: string
}

interface UpdateCleanerInput {
    isActive: boolean
}

export const requestCreateCleaner = async (
    token: string,
    input: CreateUserInput
) => {
    return bearerFetcher<void>(token, m2mCleaning.managerV3.createCleaner(), {
        method: "POST",
        body: JSON.stringify(input)
    })
}

export const requestUpdateCleaner = async (
    token: string,
    cleanerId: string,
    input: UpdateCleanerInput
) => {
    return bearerFetcher<void>(
        token,
        m2mCleaning.managerV3.updateCleaners({ cleanerId }),
        {
            method: "PUT",
            body: JSON.stringify(input)
        }
    )
}

interface UseCleanersInput {
    cityGroupId?: string
}

export const useCleaners = (token: string, input?: UseCleanersInput) => {
    const endpoint = m2mCleaning.managerV4.listCleaners

    const { data, ...props } = useAuthBearerFetch<Cleaner[]>(
        token,
        input?.cityGroupId
            ? `${endpoint()}?cityGroupId=${input.cityGroupId}`
            : endpoint(),
        {
            method: endpoint.method
        }
    )

    const dataSorted = useMemo(
        () => data?.sort((a, b) => (a.name > b.name ? 1 : -1)),
        [data]
    )

    return { data: dataSorted, ...props }
}

export const requestUpdateUserByManager = async (
    token: string,
    userId: string,
    input: { name: string }
) => {
    return bearerFetcher<void>(
        token,
        m2mCleaning.managerV3.updateUserByManager({ userId }),
        {
            method: "POST",
            body: JSON.stringify(input)
        }
    )
}

export const requestImportUser = async (
    token: string,
    input: { email: string }
) => {
    return bearerFetcher<void>(
        token,
        m2mCleaning.managerV3.importCleanerByEmail(),
        {
            method: "POST",
            body: JSON.stringify(input)
        }
    )
}
