export const coreDomain = () =>
    process.env.NODE_ENV === "development"
        ? "https://dev--m2m-core-manager-front.netlify.app"
        : "https://core.m2msystems.cloud"

export const usersDomain = () =>
    process.env.NODE_ENV === "development"
        ? "https://dev--manage-users.netlify.app"
        : "https://manage-users.m2msystems.cloud"

export const isDev = () => process.env.NODE_ENV === "development"
