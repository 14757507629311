import { AssignStatus } from "@/domain/approvalRequest"
import { m2mCleaning_managerV4 } from "@/endpoints/m2m-cleaning.manager-v4"
import { useAuthBearerFetch } from "matsuri-hooks"

type CleanerId = string
type OperationId = string

export interface ApprovalRequest {
    id: string
    status: AssignStatus
    cleanerId: string
    unitPrice: number
    createdAt: number
    specialBonus?: number
}

type ApprovalRequestsByOperationIdsOutput = Record<
    OperationId,
    Record<CleanerId, ApprovalRequest>
>

export const useApprovalRequestsByOperationIds = (
    token: string,
    operationIds: string[]
) => {
    const endpoint =
        m2mCleaning_managerV4.listLatestApprovalRequestPerUserByCleaningIds

    return useAuthBearerFetch<ApprovalRequestsByOperationIdsOutput>(
        token,
        operationIds.length ? endpoint() : null,
        {
            body: JSON.stringify({ cleaningIds: operationIds }),
            method: endpoint.method
        }
    )
}
