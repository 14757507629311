import { Button, Column, Layout, Row, TextField, Typography } from "matsuri-ui"
import { OperationsTable, RowData } from "./OperationsTable"
import { PhotoTour } from "@/domain/photoTour"
import { TourFlag } from "@/parts/Table/columns/tourFlag"
import { composePlacementDetailMap } from "@/domain/placement"
import { css } from "@emotion/react"
import { useAuth } from "m2m-components/storage/useM2mAuth"
import { useMemo, useState } from "react"
import { useOperationsBySubmissionId } from "@/hooks/useOperationBySubmissionId"
import { usePhotoTours } from "@/hooks/usePhotoTour"
import { usePlacementDetails } from "@/hooks/usePlacement"
import dayjs from "dayjs"

const formName = {
    submissionId: "submissionId"
} as const

export const FormIDSearch = () => {
    const [searchingSubmissionId, setSearchingSubmissionId] = useState("")
    const { data: operations, isLoading } = useOperationsBySubmissionId({
        submissionId: searchingSubmissionId
    })

    const isNotFound =
        (!operations || operations?.length === 0) &&
        !isLoading &&
        Boolean(searchingSubmissionId)

    const { token } = useAuth()
    const { data: placementDetails } = usePlacementDetails(
        token,
        operations?.map(o => o.placementId) ?? []
    )

    const { data: tours } = usePhotoTours(token)

    const toursById: Record<string, PhotoTour> = useMemo(
        () =>
            tours?.reduce(
                (acc, tour) => {
                    acc[tour.id] = tour
                    return acc
                },
                {} as Record<string, PhotoTour>
            ) ?? {},
        [tours]
    )

    const tableData: RowData[] = useMemo(() => {
        if (!placementDetails) return []
        const placementDetailMap = composePlacementDetailMap(
            placementDetails.placements
        )

        if (!operations) return []

        return operations.map(o => {
            const placementDetail = placementDetailMap[o.placementId]

            const placementType = placementDetail.placement.placementType

            const tourFlag: TourFlag = {
                kind: "photoTour",
                title: toursById[o.photoTourId]?.title
            }

            const data: RowData = {
                id: o.cleaningId,
                cleaningDate: dayjs(o.cleaningDate),
                tourFlag,
                placeName:
                    placementType === "listing"
                        ? {
                              listingName: placementDetail.listing?.name ?? "",
                              placementType,
                              listingId: placementDetail.listing?.id ?? ""
                          }
                        : {
                              commonAreaName:
                                  placementDetail.commonArea?.name ?? "",
                              placementType
                          },
                placementType
            }

            return data
        })
    }, [operations, placementDetails, toursById])

    return (
        <Column>
            <Layout.Item>
                <form
                    onSubmit={e => {
                        e.preventDefault()
                        const formData = new FormData(e.currentTarget)
                        const submissionId = formData
                            .get(formName.submissionId)
                            ?.toString()

                        if (!submissionId) return
                        setSearchingSubmissionId(submissionId)
                    }}
                >
                    <Row gap={2} alignItems="flex-end">
                        <Layout.Item>
                            <TextField
                                name={formName.submissionId}
                                label="フォームIDで検索"
                            />
                        </Layout.Item>
                        <Layout.Item>
                            <Button variant="filled" color="primary">
                                検索する
                            </Button>
                        </Layout.Item>
                    </Row>
                </form>
            </Layout.Item>
            {isNotFound && (
                <Layout.Item>
                    <Typography color="error">
                        ツアー作業が見つかりませんでした
                    </Typography>
                </Layout.Item>
            )}

            {tableData.length > 0 && (
                <Layout.Item
                    css={css`
                        margin-top: 8px;
                    `}
                >
                    <OperationsTable tableData={tableData} />
                </Layout.Item>
            )}
        </Column>
    )
}
