import { useState } from "react"

interface UseMenuState {
    anchorEl: HTMLElement | null
    open: boolean
    onClickOutside: () => void
    handleOpenMenu: (event: React.SyntheticEvent<HTMLElement>) => void
}

export const useMenu = (): UseMenuState => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    return {
        anchorEl,
        open: Boolean(anchorEl),
        onClickOutside: () => setAnchorEl(null),
        handleOpenMenu: (event: React.SyntheticEvent<HTMLElement>) =>
            setAnchorEl(event.currentTarget)
    }
}
