import { Tag } from "@/domain/tag"
import { css } from "@emotion/react"
import { theme } from "@/Theme"

const tagDisplayAttributesMap = new Map<
    string,
    { displayName: string; color: string }
>([["unavailable", { displayName: "業務停止中", color: "#D9D9D9" }]])

export const CleanerTags = ({ tags }: { tags: Tag[] }) => {
    const defaultColor = theme.palette.gray[100]
    return (
        <>
            <div
                css={css`
                    display: flex;
                    gap: 4px;
                `}
            >
                {tags.map(tag => {
                    const attrs = tagDisplayAttributesMap.get(tag.name)
                    return (
                        <div
                            key={tag.id}
                            css={css`
                                width: max-content;
                                padding: 4px 8px;
                                text-align: center;
                                background-color: ${attrs?.color ??
                                defaultColor};
                                border-radius: 4px;
                            `}
                        >
                            {attrs?.displayName ?? tag.name}
                        </div>
                    )
                })}
            </div>
        </>
    )
}
