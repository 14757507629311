export type CleaningNewStatus =
    | "cleaningUnassigned"
    | "approvalUnapproved"
    | "approvalPending"
    | "cleaningPreparing"
    | "cleaningStarted"
    | "cleaningFinished"
    | "cleaningReported"
    | "cleaningDisabled"

export const displayCleaningNewStatus = (
    status: CleaningNewStatus | undefined
): string | undefined => {
    if (!status) return undefined

    return {
        cleaningUnassigned: "未割り当て",
        approvalUnapproved: "不承認",
        approvalPending: "承認待ち",
        cleaningPreparing: "清掃準備中",
        cleaningStarted: "開始済み",
        cleaningFinished: "清掃終了済み",
        cleaningReported: "清掃報告済み",
        cleaningDisabled: "無効化済み"
    }[status]
}

export const colorCleaningNewStatus = (
    status: CleaningNewStatus | undefined
) => {
    if (!status) return undefined

    // ref: https://www.figma.com/file/7VhJ2vCaYKMRra2foQj06X/m2m-Cleaning?type=design&node-id=6406%3A42803&mode=design&t=d1TDNdOnqs7iXMeO-1
    return {
        cleaningUnassigned: "#EF9A9A",
        approvalUnapproved: "#EF9A9A",
        approvalPending: "#FFB976",
        cleaningPreparing: "#FFE082",
        cleaningStarted: "#A5D6A7",
        cleaningFinished: "#A6C3FB",
        cleaningReported: "#E4C1F9",
        cleaningDisabled: "#AAAAAA"
    }[status]
}

export const sortCleaningNewStatus = (
    a?: CleaningNewStatus,
    b?: CleaningNewStatus
): number => {
    if (a === undefined && b === undefined) return 0
    if (a === undefined) return 1
    if (b === undefined) return -1

    const statusOrder = [
        "cleaningUnassigned",
        "approvalUnapproved",
        "approvalPending",
        "cleaningPreparing",
        "cleaningStarted",
        "cleaningFinished",
        "cleaningReported",
        "cleaningDisabled"
    ] as const
    const aIndex = statusOrder.indexOf(a)
    const bIndex = statusOrder.indexOf(b)
    if (aIndex === bIndex) return 0
    if (aIndex > bIndex) return -1
    return 1
}
