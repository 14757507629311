import {
    Button,
    ImageUploadArea,
    MultipleTextField,
    Typography,
    useAlert
} from "matsuri-ui"
import { Header } from "./Layout"
import { PlacementSelectField } from "../parts/PlacementSelectField"
import { StringTypes, genFieldNames } from "../helpers/fieldTypes"
import { css } from "@emotion/react"
import { imageToCompressedString } from "../helpers/compress"
import { requestCreateTroubleWithPlacement } from "../hooks/useTroubles"
import { useAuth } from "../hooks/useAuth"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"

const fieldNames = genFieldNames(["listingId", "description"])
type FieldTypes = StringTypes<typeof fieldNames>

export const NewTroublePage = () => {
    const { token } = useAuth()
    const navigate = useNavigate()
    const { addAlert, throwAlert } = useAlert()
    const [images, setImages] = useState<{ id: string; data: string }[]>([])

    const [placement, setPlacement] = useState<{
        listingId?: string
        commonAreaId?: string
    }>()

    return (
        <>
            <Header
                css={css`
                    display: flex;
                    gap: 8px;
                    align-items: center;
                `}
            >
                <Button
                    icon={<ArrowBackIosNewIcon />}
                    onClick={() => {
                        navigate("/troubles")
                    }}
                />
                <Typography variant="h2">トラブルを新規作成</Typography>
            </Header>

            <form
                css={css`
                    display: grid;
                    gap: 16px;
                    max-width: 600px;
                `}
                onSubmit={async event => {
                    event.preventDefault()

                    const input = Object.fromEntries(
                        new FormData(event.currentTarget)
                    ) as FieldTypes

                    if (!placement?.listingId && !placement?.commonAreaId) {
                        addAlert(`物件か共用部を選択してください`, {
                            severity: "error"
                        })
                        return
                    }

                    const { data, error } =
                        await requestCreateTroubleWithPlacement(token, {
                            listingId: placement.listingId,
                            commonAreaId: placement.commonAreaId,
                            description: input.description,
                            pictures: images.map(image => image.data)
                        })

                    throwAlert(error, {
                        errorMessage: "トラブル作成に失敗しました",
                        successMessage: "トラブルを作成しました",
                        onSuccess: () => {
                            if (!data?.id) {
                                return
                            }

                            navigate(`/trouble/${data.id}`)
                        }
                    })
                }}
            >
                <PlacementSelectField onChange={setPlacement} />

                <MultipleTextField
                    name={fieldNames.description}
                    label="トラブル内容"
                    rows={7}
                />

                <div>
                    <Typography variant="caption">
                        トラブルの写真を選択
                    </Typography>
                    <ImageUploadArea
                        images={images}
                        readBlob={async blob => {
                            return imageToCompressedString(blob)
                        }}
                        onUpload={(data: string[]) => {
                            if (data.length === 0) return
                            setImages(images => [
                                ...images,
                                ...data.map(d => ({
                                    id: `${Date.now()}-${Math.random()}`,
                                    data: d
                                }))
                            ])
                        }}
                        onDelete={id => {
                            setImages(images => images.filter(i => i.id !== id))
                        }}
                        onReorder={(ids: string[]) => {
                            setImages(images =>
                                ids
                                    .map(id => images.find(i => i.id === id))
                                    .filter(
                                        (
                                            image
                                        ): image is {
                                            id: string
                                            data: string
                                        } => Boolean(image)
                                    )
                            )
                        }}
                    />
                </div>

                <div
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                    `}
                >
                    <Button variant="filled" color="primary">
                        トラブルを作成
                    </Button>
                </div>
            </form>
        </>
    )
}
