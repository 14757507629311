import { m2mCleaning_managerV4 } from "@/endpoints/m2m-cleaning.manager-v4"
import { useAuth } from "m2m-components/storage/useM2mAuth"
import { useAuthBearerFetch } from "matsuri-hooks"

type OperationsBySubmissionIdOutput = {
    cleaningId: string
    cleaningDate: string
    photoTourId: string
    placementId: string
}[]

export const useOperationsBySubmissionId = ({
    submissionId
}: {
    submissionId: string
}) => {
    const { token } = useAuth()

    const endpoint = m2mCleaning_managerV4.searchOperationsBySubmissionId

    return useAuthBearerFetch<OperationsBySubmissionIdOutput>(
        token,
        submissionId ? endpoint() : null,
        {
            body: JSON.stringify({ submissionId }),
            method: endpoint.method
        }
    )
}
