import "m2m-components/m2m-apps-menu"
import "m2m-components/m2m-releases-link"
import "m2m-components/m2m-user-menu"
import { GlobalMenu } from "matsuri-ui"
import { Link } from "react-router-dom"
import { NavLink } from "react-router-dom"
import { NotificationMenu } from "./AppMenu/NotificationMenu"
import { PropsWithChildren } from "react"
import { SearchOperation } from "./AppMenu/SearchOperation/SearchOperation"
import { ShowCleanerPageQrCode } from "./AppMenu/ShowCleanerPageQrCode"
import { css } from "@emotion/react"
import { excludeFalsy } from "@/helpers/excludeFalsy"
import { isDev } from "@/helpers/env"
import { useAccountInfoCtx } from "@/hooks/useAccountInfo"
import { useAuth } from "m2m-components/storage/useM2mAuth"

const useItems = () => {
    const { isListingManager } = useAccountInfoCtx()

    return [
        {
            group: "メインメニュー",
            items: excludeFalsy([
                {
                    group: "清掃管理",
                    items: [
                        { text: "清掃カレンダー", href: "/calendar" },
                        { text: "本日の清掃", href: "/cleanings/day" },
                        {
                            text: "清掃員ごとの本日の清掃",
                            href: "/cleanings/user"
                        },
                        { text: "清掃一覧", href: "/cleanings" },
                        { text: "清掃箇所", href: "/reports/tasks" },
                        { text: "チェックリスト", href: "/reports/checkitems" },
                        isDev()
                            ? { text: "アサインマップ", href: "/assignmap" }
                            : undefined,
                        { text: "清掃状況", href: "/cleaningTimeline" }
                        // undefinedを除外
                    ].filter(
                        (item): item is { text: string; href: string } =>
                            item !== undefined
                    )
                },
                {
                    group: "一括インポート",
                    items: [
                        {
                            text: "オペレーション作成",
                            href: "/import/cleaning/create"
                        },
                        {
                            text: "オペレーション編集",
                            href: "/import/cleaning/update"
                        }
                    ]
                },
                {
                    group: "物件管理",
                    items: [
                        isListingManager
                            ? { text: "自社の物件", href: "/listings/owned" }
                            : undefined,
                        {
                            text: "委譲されている物件",
                            href: "/listings/delegated"
                        },
                        isListingManager
                            ? {
                                  text: "物件清掃費用インポート",
                                  href: "/listings/cleaningfee/import"
                              }
                            : undefined

                        // undefinedを除外
                    ].filter(
                        (item): item is { text: string; href: string } =>
                            item !== undefined
                    )
                },
                {
                    group: "清掃員管理",
                    items: [
                        { text: "清掃員一覧", href: "/cleaners" },
                        { text: "シフト管理", href: "/shift" },
                        {
                            text: "清掃員情報インポート",
                            href: "/cleanerDetailImport"
                        }
                    ]
                },
                {
                    group: "ツアー",
                    items: [
                        isDev()
                            ? { text: "ツアー設定", href: "/photoTours" }
                            : undefined
                    ].filter(
                        (item): item is { text: string; href: string } =>
                            item !== undefined
                    )
                },
                isDev()
                    ? {
                          group: "単価",
                          items: [
                              {
                                  text: "清掃員単価設定",
                                  href: "/cleaner/unitPrice"
                              },
                              {
                                  text: "ツアー単価設定",
                                  href: "/phototours/unitprice/list"
                              },
                              {
                                  text: "単価式",
                                  href: "/unitPriceFormula"
                              }
                          ]
                      }
                    : undefined,
                // ツアー設定はごく一部の人にだけ操作してほしいため、本番では導線を隠す
                {
                    group: "実績出力",
                    items: [
                        { text: "清掃実績", href: "/results" },
                        { text: "物件請求出力", href: "/listingInvoices" }
                    ]
                },
                {
                    group: "エリア",
                    items: [{ text: "エリア一覧", href: "/area/list" }]
                },
                {
                    group: "その他",
                    items: [
                        { text: "トラブル一覧", href: "/troubles" },
                        { text: "通知設定", href: "/settings/notification" },
                        {
                            text: "清掃員向け通知",
                            href: "/company/notification"
                        }
                    ]
                }
            ])
        }
    ]
}

const Logo = () => {
    return (
        <Link
            to="/"
            css={css`
                display: contents;
            `}
        >
            <img
                src="/images/m2m_cleaning.png"
                css={css`
                    width: 50px;
                    height: 50px;
                    margin: 4px;
                `}
                alt=""
            />
        </Link>
    )
}

const AuthenticatedMenu = ({ children }: PropsWithChildren) => {
    const items = useItems()

    return (
        <GlobalMenu
            logo={<Logo />}
            headerItems={
                <>
                    <>
                        <SearchOperation />
                        <NotificationMenu />
                        <ShowCleanerPageQrCode />
                    </>
                    <m2m-releases-link product="m2m-cleaning" />
                    <m2m-apps-menu />
                    <m2m-user-menu />
                </>
            }
            items={items}
            renderInternalLink={({ href, children }) => (
                <NavLink end to={href}>
                    {children}
                </NavLink>
            )}
        >
            {children}
        </GlobalMenu>
    )
}

const NotAuthenticatedMenu = ({ children }: PropsWithChildren) => {
    return (
        <GlobalMenu
            logo={<Logo />}
            headerItems={
                <>
                    <m2m-apps-menu />
                </>
            }
            items={[]}
            // renderInternalLinkは必須paramなので渡しておく
            renderInternalLink={({ href, children }) => (
                <NavLink end to={href}>
                    {children}
                </NavLink>
            )}
        >
            {children}
        </GlobalMenu>
    )
}

export const AppMenu = ({ children }: PropsWithChildren) => {
    const { authenticated } = useAuth()

    // NOTE: 本来は一つのGlobalMenuでｂ済ませるｂべきだが、簡単のため分けている
    return authenticated ? (
        <AuthenticatedMenu>{children}</AuthenticatedMenu>
    ) : (
        <NotAuthenticatedMenu>{children}</NotAuthenticatedMenu>
    )
}
