import "dayjs/locale/ja"
import { AccountInfoProvider } from "@/hooks/useAccountInfo"
import { AuthorityErrorDialog } from "@/components/AuthorityErrorDialog"
import { Button, ModalProvider } from "matsuri-ui"
import { FeedbackProvider } from "./parts/FeedbackProvider"
import {
    M2mAuthenticationProvider,
    useAuth
} from "m2m-components/storage/useM2mAuth"
import { NewTroublePage } from "./pages/NewTroublePage"
import { NotificationsProvider } from "./parts/NotificationsProvider"

import { AppMenu } from "./parts/AppMenu"
import { Navigate, Route, Routes } from "react-router-dom"
import { RecoilRoot } from "recoil"
import { SWRConfig } from "swr"
import { useCopyToClipboard } from "./helpers/useCopyToClipboard"
import AreaEditPage from "./pages/AreaEditPage"
import CleaningsForEachUserPage from "./pages/CleaningsForEachUser"
import React, { Suspense, useState } from "react"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import styled from "@emotion/styled"

// よく使うので全体で有効にしてしまう
dayjs.extend(relativeTime)
dayjs.locale("ja")

const Main = styled.main`
    margin: 0 auto;
    padding: 0 21px;
    margin-bottom: 64px;
`

const CleaningsPage = React.lazy(() => import("./pages/ListCleaningsPage"))
const WeeklyCalendarPage = React.lazy(
    () => import("./pages/WeeklyCalendarPage")
)
const CleaningDetailPage = React.lazy(
    () => import("./pages/CleaningDetailPage")
)
const DelegateCleaningDetailPage = React.lazy(
    () => import("./pages/DelegateCleaningDetail")
)
const ListingOwnedPage = React.lazy(
    () => import("./pages/Listings/ListingOwned")
)
const ListingDelegatedPage = React.lazy(
    () => import("./pages/Listings/ListingDelegated")
)
const ListingDetailPage = React.lazy(() => import("./pages/ListingDetailPage"))
const CleanersPage = React.lazy(() => import("./pages/Cleaners"))
const CheckItemsPage = React.lazy(() => import("./pages/CheckItems/CheckItems"))
const CleaningTasksPage = React.lazy(() => import("./pages/CleaningTasks"))
const CleaningResultsPage = React.lazy(() => import("./pages/AchievementPage"))
const NotificationSettings = React.lazy(
    () => import("@/pages/NotificationSettings/NotificationSettings")
)
const Login = React.lazy(() => import("./pages/Login"))
const TroublesPage = React.lazy(() => import("./pages/TroublesPage"))
const TroubleDetailPage = React.lazy(() => import("./pages/TroubleDetailPage"))
const ListingInvoicePage = React.lazy(
    () => import("./pages/ListingInvoicePage")
)
const CleaningsForDayPage = React.lazy(() => import("./pages/CleaningsForDay"))
const CleaningsForAllUserPage = React.lazy(
    () => import("./pages/CleaningsForAllUser")
)
const PhotoTourPage = React.lazy(() => import("./pages/PhotoTourPage"))
const PhotoTourDetailPage = React.lazy(
    () => import("./pages/PhotoTourDetailPage")
)
const PhotoTourItemNewPage = React.lazy(
    () => import("./pages/PhotoTourItemNewPage")
)
const PhotoTourItemDetailPage = React.lazy(
    () => import("./pages/PhotoTourItemDetailPage")
)
const OperationDetailPage = React.lazy(
    () => import("./pages/OperationDetailPage")
)

const PhotoTourItemReorderPage = React.lazy(
    () => import("./pages/PhotoTourItemReorderPage")
)
const ShiftPage = React.lazy(() => import("./pages/ShiftPage"))

const CleanerDetailImportPage = React.lazy(
    () => import("./pages/CleanerDetailImport")
)

const CleaningTimelinePage = React.lazy(
    () => import("./pages/CleaningTimelinePage")
)

const AreaListPage = React.lazy(() => import("./pages/AreaListPage"))

const NewAreaPage = React.lazy(() => import("./pages/NewAreaPage"))

const AssignMapPage = React.lazy(() => import("./pages/AssignMapPage"))

const ListingClusterPage = React.lazy(
    () => import("./pages/ListingClusterPage")
)

const ImportCreateOperationCSVPage = React.lazy(
    () => import("./pages/ImportCreateOperationCSVPage")
)
const ImportUpdateOperationCSVPage = React.lazy(
    () => import("./pages/ImportUpdateOperationCSVPage")
)

const PhotoTourPriceSettingsPage = React.lazy(
    () => import("./pages/PhotoTourPriceSettingsPage")
)

const PhotoTourPriceCSVImportPage = React.lazy(
    () => import("./pages/PhotoTourPriceCSVImportPage")
)
const CleanerUnitPriceSettingPage = React.lazy(
    () => import("./pages/CleanerUnitPriceSettingPage")
)

const CleanerUnitPriceCSVImportPage = React.lazy(
    () => import("./pages/CleanerUnitPriceCSVImportPage")
)

const ImportCleaningTypeFeePage = React.lazy(
    () => import("./pages/ImportCleaningTypeFeePage")
)

const InterpreterPage = React.lazy(() => import("./pages/InterpreterPage"))

const UnitPriceFormulaPage = React.lazy(
    () => import("./pages/UnitPriceFormulaPage")
)
const CompanyNotificationPreferencePage = React.lazy(
    () => import("./pages/CompanyNotificationPreferencePage")
)

const ErrorBombPage = () => {
    const [mode, setMode] = useState<"error-boundary" | undefined>(undefined)

    if (!mode) {
        return (
            <>
                <Button onClick={() => setMode("error-boundary")}>
                    エラーを発生させErrorBoundaryにキャッチさせる
                </Button>
                <Button
                    onClick={() => {
                        throw new Error("This error is threw in onClick")
                    }}
                >
                    イベントハンドラー内でエラーを発生させる
                </Button>
            </>
        )
    }

    throw new Error(
        "This error is threw in ErrorBombPage by error-boundary mode"
    )
}

const AppRoutes = () => {
    return (
        <Routes>
            <Route path={"/error"} element={<ErrorBombPage />} />
            <Route path={"/cleanings"} element={<CleaningsPage />} />
            <Route path="/cleanings/day/*" element={<CleaningsForDayPage />} />
            <Route
                path="/cleanings/user/*"
                element={<CleaningsForAllUserPage />}
            />
            <Route
                path="/cleanings/user/:cleanerId/cleanings"
                element={<CleaningsForEachUserPage />}
            />
            <Route path={"/calendar/*"} element={<WeeklyCalendarPage />} />
            <Route
                path={"/cleanings/:cleaningId/*"}
                element={<CleaningDetailPage />}
            />
            <Route
                path={"/delegateCleanings/:cleaningId/*"}
                element={<DelegateCleaningDetailPage />}
            />
            <Route path={"/listings/owned"} element={<ListingOwnedPage />} />
            <Route
                path={"/listings/delegated"}
                element={<ListingDelegatedPage />}
            />
            <Route
                path={"/listings/:listingId/*"}
                element={<ListingDetailPage />}
            />
            <Route path={"/cleaners/*"} element={<CleanersPage />} />
            <Route path={"/checkitems/*"} element={<CheckItemsPage />} />
            <Route
                path={"/reports/checkitems/*"}
                element={<CheckItemsPage />}
            />
            <Route path={"/reports/tasks/*"} element={<CleaningTasksPage />} />
            <Route path={"/results/*"} element={<CleaningResultsPage />} />
            <Route
                path={"/settings/notification"}
                element={<NotificationSettings />}
            />
            <Route path="/troubles/new/*" element={<NewTroublePage />} />
            <Route path="/troubles/*" element={<TroublesPage />} />
            <Route
                path="/trouble/:troubleId/*"
                element={<TroubleDetailPage />}
            />
            <Route path="/listingInvoices/*" element={<ListingInvoicePage />} />
            <Route path="/photoTours" element={<PhotoTourPage />} />
            <Route
                path="/photoTours/:photoTourId/reorder"
                element={<PhotoTourItemReorderPage />}
            />
            <Route
                path="/photoTours/:photoTourId"
                element={<PhotoTourDetailPage />}
            />
            <Route
                path="/photoTours/:photoTourId/items/new"
                element={<PhotoTourItemNewPage />}
            />
            <Route
                path="/photoTours/:photoTourId/items/:photoTourItemId/*"
                element={<PhotoTourItemDetailPage />}
            />

            <Route
                path={"/operations/:operationId/*"}
                element={<OperationDetailPage />}
            />

            <Route
                path={"/import/cleaning/create"}
                element={<ImportCreateOperationCSVPage />}
            />

            <Route
                path={"/import/cleaning/update"}
                element={<ImportUpdateOperationCSVPage />}
            />

            <Route path={"*"} element={<Navigate to="/calendar" />} />

            <Route path={"/shift/*"} element={<ShiftPage />} />
            <Route
                path={"/cleanerDetailImport/*"}
                element={<CleanerDetailImportPage />}
            />

            <Route
                path={"/cleaner/unitPrice"}
                element={<CleanerUnitPriceSettingPage />}
            />
            <Route
                path={"/import/cleaner/unitPrice"}
                element={<CleanerUnitPriceCSVImportPage />}
            />

            <Route path={"/area/list"} element={<AreaListPage />} />
            <Route path={"/area/new"} element={<NewAreaPage />} />
            <Route
                path={"/area/:cityGroupId/edit"}
                element={<AreaEditPage />}
            />
            <Route path={"/assignmap"} element={<AssignMapPage />} />
            <Route path={"/listingCluster"} element={<ListingClusterPage />} />
            <Route
                path={"/phototours/unitprice/list"}
                element={<PhotoTourPriceSettingsPage />}
            />
            <Route
                path={"/import/phototours/unitprice"}
                element={<PhotoTourPriceCSVImportPage />}
            />
            <Route path={"/interpreter"} element={<InterpreterPage />} />
            <Route
                path={"/unitPriceFormula"}
                element={<UnitPriceFormulaPage />}
            />
            <Route
                path={"/cleaningTimeline"}
                element={<CleaningTimelinePage />}
            />
            <Route
                path={"/listings/cleaningfee/import"}
                element={<ImportCleaningTypeFeePage />}
            />
            <Route
                path={"/company/notification"}
                element={<CompanyNotificationPreferencePage />}
            />
        </Routes>
    )
}

const SwrConfigProvider = ({ children }: { children: React.ReactNode }) => {
    return (
        <SWRConfig
            value={{
                onError: (err, _key, _config) => {
                    console.error(err)
                }
            }}
        >
            {children}
        </SWRConfig>
    )
}

const Providers = ({ children }: { children: React.ReactNode }) => {
    return (
        <RecoilRoot>
            <SwrConfigProvider>
                <NotificationsProvider>
                    <AccountInfoProvider>
                        <ModalProvider>
                            <FeedbackProvider>{children}</FeedbackProvider>
                        </ModalProvider>
                    </AccountInfoProvider>
                </NotificationsProvider>
            </SwrConfigProvider>
        </RecoilRoot>
    )
}

const AppContent = () => {
    const { token, authenticated } = useAuth()

    useCopyToClipboard({ text: token, label: "JWT", key: "j" })

    return (
        <Providers>
            <AppMenu>
                <Main>
                    <Suspense fallback={null}>
                        {authenticated ? (
                            <>
                                <AuthorityErrorDialog />
                                <AppRoutes />
                            </>
                        ) : (
                            <Routes>
                                <Route path={"/login"} element={<Login />} />
                                <Route
                                    path="*"
                                    element={
                                        <Navigate
                                            to={`/login?redirectUri=${encodeURIComponent(
                                                `${window.location.pathname}${window.location.search}`
                                            )}`}
                                        />
                                    }
                                />
                            </Routes>
                        )}
                    </Suspense>
                </Main>
            </AppMenu>
        </Providers>
    )
}

export const App = () => {
    return (
        <M2mAuthenticationProvider>
            <AppContent />
        </M2mAuthenticationProvider>
    )
}
