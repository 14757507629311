/* eslint-disable */
/**
 * A function that returns the URL part common to the endpoints.
 */
export const root = () => {
  let __root = "";

  if (process.env.RUNTIME_ENV === "local") {
    __root = "https://api-forms.dev.m2msystems.cloud";
  }

  if (process.env.RUNTIME_ENV === "localDev") {
    __root = "";
  }

  if (process.env.RUNTIME_ENV === "development") {
    __root = "https://api-forms.dev.m2msystems.cloud";
  }

  if (process.env.RUNTIME_ENV === "production") {
    __root = "https://api-forms.m2msystems.cloud";
  }

  return __root;
};
/**
 * health check
 *
 */
export const healthCheck = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`health_check`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

healthCheck.method = "GET" as const;
healthCheck.authSchema = { type: "", header: "" } as const;
/**
 * DraftRevisionのフォーム情報を取得する
 *
 */
export const formGet = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`forms/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

formGet.method = "GET" as const;
formGet.authSchema = { type: "", header: "" } as const;
/**
 * 特定のRevisionのフォーム情報を取得する
 *
 */
export const formGetByRevision = ({
  id,
  revision,
}: {
  id: string;
  revision: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`forms/${id}/${revision}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

formGetByRevision.method = "GET" as const;
formGetByRevision.authSchema = { type: "", header: "" } as const;
/**
 * Formを新規作成する
 *
 */
export const formCreate = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`forms`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

formCreate.method = "POST" as const;
formCreate.authSchema = { type: "", header: "" } as const;
/**
 * ユーザーに紐づいたフォームを全件取得する
 *
 */
export const formFindAll = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`forms`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

formFindAll.method = "GET" as const;
formFindAll.authSchema = { type: "", header: "" } as const;
/**
 * Formを更新する
 *
 */
export const formUpdate = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`forms/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

formUpdate.method = "PATCH" as const;
formUpdate.authSchema = { type: "", header: "" } as const;
/**
 * Formのシート機能を有効化する
 *
 */
export const formActivateSheetFeature = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`forms/${id}/sheets/activate`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

formActivateSheetFeature.method = "PATCH" as const;
formActivateSheetFeature.authSchema = { type: "", header: "" } as const;
/**
 * Formの公開ステータスをPrivateに変更する
 *
 */
export const formPublishStatusChangeToPrivate = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`forms/${id}/private`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

formPublishStatusChangeToPrivate.method = "PATCH" as const;
formPublishStatusChangeToPrivate.authSchema = { type: "", header: "" } as const;
/**
 * FormのContentを更新する
 *
 */
export const formsCreateNewRevision = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`forms/${id}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

formsCreateNewRevision.method = "POST" as const;
formsCreateNewRevision.authSchema = { type: "", header: "" } as const;
/**
 * Formの質問の順番を更新する
 *
 */
export const formUpdateQuestionsOrder = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`forms/${id}/questions/order`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

formUpdateQuestionsOrder.method = "POST" as const;
formUpdateQuestionsOrder.authSchema = { type: "", header: "" } as const;
/**
 * Form内容を確定し公開する
 *
 */
export const formPublish = ({ id }: { id: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`forms/${id}/publish`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

formPublish.method = "POST" as const;
formPublish.authSchema = { type: "", header: "" } as const;
/**
 * 質問を作成する
 *
 */
export const formQuestionCreate = ({ formId }: { formId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`forms/${formId}/questions/append`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

formQuestionCreate.method = "POST" as const;
formQuestionCreate.authSchema = { type: "", header: "" } as const;
/**
 * 質問を挿入する
 *
 */
export const formQuestionInsert = ({ formId }: { formId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`forms/${formId}/questions/insert`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

formQuestionInsert.method = "POST" as const;
formQuestionInsert.authSchema = { type: "", header: "" } as const;
/**
 * 質問を削除する
 *
 */
export const formQuestionDelete = ({ formId }: { formId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`forms/${formId}/questions/delete`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

formQuestionDelete.method = "POST" as const;
formQuestionDelete.authSchema = { type: "", header: "" } as const;
/**
 * 質問を更新する
 *
 */
export const formQuestionUpdate = ({
  formId,
  questionId,
}: {
  formId: string;
  questionId: string;
}) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`forms/${formId}/questions/${questionId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

formQuestionUpdate.method = "PATCH" as const;
formQuestionUpdate.authSchema = { type: "", header: "" } as const;
/**
 * 回答を取得
 *
 */
export const submissionGet = ({ formId }: { formId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`forms/${formId}/submissions`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

submissionGet.method = "GET" as const;
submissionGet.authSchema = { type: "", header: "" } as const;
/**
 * 回答を作成
 *
 */
export const submissionCreate = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`submissions`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

submissionCreate.method = "POST" as const;
submissionCreate.authSchema = { type: "", header: "" } as const;
/**
 * ゲストがフォームの情報を取得する
 *
 */
export const guestFormGet = ({ formId }: { formId: string }) => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`guest/forms/${formId}`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

guestFormGet.method = "GET" as const;
guestFormGet.authSchema = { type: "", header: "" } as const;
/**
 * Googleのトークンでサインインする
 *
 */
export const signInWithGoogle = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`auth/signInWithGoogle`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

signInWithGoogle.method = "POST" as const;
signInWithGoogle.authSchema = { type: "", header: "" } as const;
/**
 * Googleのトークンでサインアップする
 *
 */
export const signUpWithGoogle = () => {
  const __root = root();
  const __queries = Object.entries({})
    .filter(([_, value]) => {
      return value !== undefined;
    })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  const __path = `${__root}/${`auth/signUpWithGoogle`}`;
  return __queries ? `${__path}?${__queries}` : __path;
};

signUpWithGoogle.method = "POST" as const;
signUpWithGoogle.authSchema = { type: "", header: "" } as const;
export const matsuriForms_v1 = {
  healthCheck,
  formGet,
  formGetByRevision,
  formCreate,
  formFindAll,
  formUpdate,
  formActivateSheetFeature,
  formPublishStatusChangeToPrivate,
  formsCreateNewRevision,
  formUpdateQuestionsOrder,
  formPublish,
  formQuestionCreate,
  formQuestionInsert,
  formQuestionDelete,
  formQuestionUpdate,
  submissionGet,
  submissionCreate,
  guestFormGet,
  signInWithGoogle,
  signUpWithGoogle,
};
