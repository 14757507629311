import { Button } from "matsuri-ui"
import { SearchPopper } from "./SearchPopper"
import { usePopper } from "./usePopper"
import SearchIcon from "@mui/icons-material/Search"

export const SearchOperation = () => {
    const { anchorEl, open, openPopper, closePopper } = usePopper()

    return (
        <>
            <Button rounded icon={<SearchIcon />} onClick={openPopper} />
            <SearchPopper
                anchorEl={anchorEl}
                closePopper={closePopper}
                open={open}
            />
        </>
    )
}
