import { Errand } from "./errand"
import { Evidence } from "./evidence"
import { OtaInfo, Reservation } from "./reservation"
import { PlacementType } from "./placement"
import { TaskResult } from "@/domain/taskResult"

export type CleaningStatus = "prepared" | "started" | "finished" | "reported"

export const displayCleaningStatus = (s: CleaningStatus) => {
    return {
        prepared: "清掃準備中",
        started: "清掃中",
        finished: "清掃済み",
        reported: "報告済み"
    }[s]
}

export interface CleaningStatusInfo {
    status: CleaningStatus
    isDisabled: boolean
}

interface CleaningInfo {
    listingId: string
    listingName: string
    listingAddress: string
    otaInfo: OtaInfo[]
    nextReservation?: Reservation
    thisReservation?: Reservation
    cleaningDate: string
    startedAt?: number
    finishedAt?: number
    reportedAt?: number
    note: string
    photoTourId?: string
}

export type CleaningType = "standard" | "intensive" | "simple" | "deepCleaning"

// CleaningとCleaningWithCleanersは統合予定: https://github.com/matsuri-tech/m2m-cleaning/issues/1227
export interface Cleaning {
    id: string
    reservationId?: string
    listingId?: string
    createdAt: number
    status: CleaningStatus
    startedAt?: number
    finishedAt?: number
    reportedAt?: number
    cleaningDate: string
    companyId: string
    note: string
    keyboxRotationId?: string
    listingManagerMemo: string
    cleaningType: CleaningType
    isDisabled: boolean
    photoTourId?: string
    placement?: PlacementType
    placementId?: string
    scheduleStart?: number
    scheduleEnd?: number
}

export interface CleaningWithCleaners {
    id: string
    reservationId?: string
    listingId?: string
    commonAreaId?: string
    commonAreaName?: string
    createdAt: number
    status: CleaningStatus
    startedAt?: number
    finishedAt?: number
    reportedAt?: number
    cleanerIds: string[]
    cleaningDate: string
    companyId: string
    note: string
    keyboxRotationId?: string
    listingManagerMemo: string
    cleaningType: CleaningType
    isDisabled: boolean
    photoTourId?: string
    placement?: PlacementType
    placementId?: string
    address: string
}

export const cleaningDurationInSec = (cleaning: CleaningWithCleaners) => {
    return (cleaning.reportedAt !== undefined ||
        cleaning.finishedAt !== undefined) &&
        cleaning.startedAt !== undefined
        ? (cleaning.reportedAt ?? cleaning.finishedAt ?? 0) - cleaning.startedAt
        : 0
}

const CleaningTypeTranslation: { [K in CleaningType]: string } = {
    standard: "通常清掃",
    intensive: "重点清掃",
    simple: "簡易清掃",
    deepCleaning: "ディープクリーニング"
}

export const cleaningTypes = Object.keys(
    CleaningTypeTranslation
) as CleaningType[]

export const displayCleaningType = (s: CleaningType) => {
    return CleaningTypeTranslation[s]
}

export interface CleaningInfoDetail {
    id: string
    errands: Errand[]
    cleaningInfo: CleaningInfo
    status: CleaningStatus
    taskResults: TaskResult[]
    associatedReservationId?: string
    companyId: string
    companyName: string
    listingManagerMemo: string
    evidences?: Evidence[]
    cleaningType: CleaningType
    isDisabled: boolean
    scheduleStart?: number | null
    scheduleEnd?: number | null
}
