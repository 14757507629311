import { useCityGroups } from "./useCityGroup"
import { useMemo, useState } from "react"

export const useAreaViewTab = () => {
    const { data: cityGroups } = useCityGroups()
    const areaTabs = useMemo(
        () => [
            {
                title: "すべて",
                data: undefined
            },
            ...(cityGroups?.map(cityGroup => ({
                title: cityGroup.name,
                data: {
                    id: cityGroup.id
                }
            })) ?? [])
        ],
        [cityGroups]
    )

    const [selectedCityGroupId, setSelectedCityGroupId] = useState<
        string | undefined
    >(undefined)

    return {
        areaTabs,
        selectedCityGroupId,
        onCityGroupIdSelected: setSelectedCityGroupId
    }
}
