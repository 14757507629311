import { useCallback, useMemo, useState } from "react"

export const usePopper = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement>()
    const openPopper = useCallback(
        ({ currentTarget }: React.SyntheticEvent<HTMLElement>) => {
            setAnchorEl(prev => (prev ? undefined : currentTarget))
        },
        []
    )
    const closePopper = useCallback(() => setAnchorEl(undefined), [])
    const open = useMemo(() => Boolean(anchorEl), [anchorEl])

    return { anchorEl, openPopper, closePopper, open }
}
