import { Button, ListItem, Menu } from "matsuri-ui"
import { Link } from "react-router-dom"
import { MuiIconWrapper } from "../../components/MuiIconWrapper"
import { css } from "@emotion/react"
import {
    requestUpdateNotificationStatusToRead,
    useNotifications
} from "../NotificationsProvider"
import { theme } from "../../Theme"
import { useAuth } from "../../hooks/useAuth"
import { useMenu } from "@/components/useMenu"
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined"
import MailIcon from "@mui/icons-material/Mail"
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone"
import dayjs from "dayjs"

const Item = ({
    to,
    unread,
    createdAt,
    message,
    onClick
}: {
    to?: string
    unread: boolean
    createdAt: number
    message: string
    onClick: () => void
}) => {
    return (
        <ListItem
            as={Link}
            to={to ?? "#"}
            onClick={onClick}
            data-read={!unread}
            css={[
                css`
                    gap: 8px;
                    align-items: center;

                    [data-read="true"] {
                        color: ${theme.palette.text.secondary};
                        background-color: ${theme.palette.gray[200]};
                    }
                `
            ]}
        >
            <div
                css={css`
                    display: grid;
                    align-items: center;
                    margin-right: 8px;
                `}
            >
                {unread ? <DraftsOutlinedIcon /> : <MailIcon />}
            </div>
            <div
                css={css`
                    flex: 1;
                    line-height: 1.5;
                    white-space: normal;
                `}
            >
                {message}
            </div>
            <div
                css={css`
                    font-size: ${theme.typography.caption}px;
                    color: ${theme.palette.text.secondary};
                `}
            >
                {dayjs.unix(createdAt).fromNow()}
            </div>
        </ListItem>
    )
}

export const NotificationMenu = () => {
    const { token } = useAuth()
    const { handleOpenMenu, ...menuProps } = useMenu()
    const { notifications, isAllRead, refetch } = useNotifications()

    const handleClick = async (notificationId: string) => {
        menuProps.onClickOutside()
        await requestUpdateNotificationStatusToRead(token, notificationId)
        refetch()
    }

    return (
        <>
            <Button
                icon={
                    <MuiIconWrapper>
                        <NotificationsNoneIcon />
                        {!isAllRead && (
                            <div
                                css={css`
                                    position: absolute;
                                    top: 4px;
                                    right: 4px;
                                    width: 12px;
                                    height: 12px;
                                    background-color: ${theme.palette.intentions
                                        .primary.main};
                                    border-radius: 50%;
                                `}
                            />
                        )}
                    </MuiIconWrapper>
                }
                onClick={handleOpenMenu}
                rounded
            />

            <Menu
                paperProps={{ elevation: 5 }}
                {...menuProps}
                css={css`
                    width: 380px;
                    max-height: 400px;
                    overflow-y: scroll;
                `}
            >
                {notifications?.map(notification => {
                    const unread = notification.status === "unread"
                    const props = {
                        key: notification.id,
                        unread,
                        createdAt: notification.createdAt,
                        onClick: () => handleClick(notification.id)
                    }

                    if (notification.notificationType === "createTrouble") {
                        const payload = JSON.parse(notification.message) as {
                            cleaningId: string
                            listingId?: string
                            listingName?: string // 途中から追加されたもの
                        }

                        return (
                            <div key={notification.id}>
                                <Item
                                    {...props}
                                    to={`/trouble/${notification.resourceId}`}
                                    message={
                                        payload.listingName
                                            ? `${payload.listingName}にトラブルが作成されました`
                                            : "トラブルが作成されました"
                                    }
                                />
                            </div>
                        )
                    } else {
                        return (
                            <div key={notification.id}>
                                <Item
                                    {...props}
                                    message={notification.message}
                                />
                            </div>
                        )
                    }
                })}
            </Menu>
        </>
    )
}
